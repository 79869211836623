import { fromJS } from 'immutable';

import localStorageUser from 'utils/localStorageUser';

import { DEFAULT_LOCALE } from '../../i18n';
import { CHANGE_LOCALE } from './constants';

const locale = localStorageUser.getLanguageUserFromLocalStorage();
export const initialState = fromJS({
  locale: locale || DEFAULT_LOCALE,
});

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return state.set('locale', action.locale || 'en');
    default:
      return state;
  }
}

export default languageProviderReducer;
