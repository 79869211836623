import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

const Formula = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.formula)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3335 2.66665H2.66683V13.3333H13.3335V2.66665ZM2.66683 1.33331C1.93045 1.33331 1.3335 1.93027 1.3335 2.66665V13.3333C1.3335 14.0697 1.93045 14.6666 2.66683 14.6666H13.3335C14.0699 14.6666 14.6668 14.0697 14.6668 13.3333V2.66665C14.6668 1.93027 14.0699 1.33331 13.3335 1.33331H2.66683Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.06295 4.3839C4.17267 4.14964 4.408 4 4.66667 4H11.3333C11.7015 4 12 4.29848 12 4.66667V5.77778C12 6.14597 11.7015 6.44444 11.3333 6.44444C10.9652 6.44444 10.6667 6.14597 10.6667 5.77778V5.33333H6.09004L7.9566 7.57321C8.16263 7.82044 8.16263 8.17956 7.9566 8.42679L6.09004 10.6667H10.6667V10.2222C10.6667 9.85403 10.9652 9.55556 11.3333 9.55556C11.7015 9.55556 12 9.85403 12 10.2222V11.3333C12 11.7015 11.7015 12 11.3333 12H4.66667C4.408 12 4.17267 11.8504 4.06295 11.6161C3.95323 11.3818 3.98893 11.1053 4.15453 10.9065L6.57665 8L4.15453 5.09346C3.98893 4.89473 3.95323 4.61815 4.06295 4.3839Z"
    />
  </svg>
);

Formula.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Formula.defaultProps = {
  title: '',
};

export default injectIntl(Formula);
