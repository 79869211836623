import { fromJS } from 'immutable';

import {
  SELECT_GROUP,
  SET_DISPLAY_DETAILS,
  SET_GROUP_DETAILS,
  SET_GROUPS,
  SET_GROUPS_MEMBERS,
  SET_LOADING_FLAG,
  SET_LOADING_GROUP_DETAILS_FLAG,
  SET_PROJECT_MEMBERS,
  SET_SEARCH_TEXT,
  SET_SEARCHED,
} from './constants';

export const initialState = fromJS({
  loading: false,
  loadingGroupDetails: false,
  displayDetails: false,
  searched: false,
  membersGroups: {},
  groups: [],
  projectMembers: [],
  currentGroup: null,
  groupDetails: {},
  searchText: '',
});

const groupsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case SET_LOADING_GROUP_DETAILS_FLAG:
      return state.set('loadingGroupDetails', action.loading);
    case SET_DISPLAY_DETAILS:
      return state.set('displayDetails', action.displayDetails);
    case SET_SEARCHED:
      return state.set('searched', action.searched);
    case SET_SEARCH_TEXT:
      return state.set('searchText', action.searchText);
    case SET_GROUPS:
      return state.set('groups', fromJS(action.groups));
    case SET_PROJECT_MEMBERS:
      return state.set('projectMembers', fromJS(action.members));
    case SET_GROUPS_MEMBERS:
      return state.set('membersGroups', {
        ...state.get('membersGroups'),
        [action.id]: action.members,
      });
    case SET_GROUP_DETAILS:
      return state.set('groupDetails', action.groupDetails);
    case SELECT_GROUP:
      return state.set('currentGroup', action.group);
    default:
      return state;
  }
};

export default groupsPageReducer;
