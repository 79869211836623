import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Member from 'components/Member';
import MemberCellSkeleton from 'components/SkeletonScreen/components/MemberCellSkeleton';
import { getUserData } from 'containers/GlobalWrapper/actions';
import { selectUsers } from 'containers/GlobalWrapper/selectors';

function MemberCellWithUserId(props) {
  const { loading, userId, dispatchGetUserData, members } = props;
  const [objectMember, setObjectMember] = useState(null);

  useEffect(
    () => {
      const membersArray = Object.values(members);
      const foundMember = membersArray.find(member => member.user_id === userId);

      if (userId !== undefined && foundMember === undefined) {
        dispatchGetUserData(
          userId,
          userData => {
            setObjectMember(userData);
          },
          true,
        );
      }

      if (foundMember !== undefined) {
        setObjectMember(foundMember);
      }
    },
    [userId],
  );

  if (loading) {
    return <MemberCellSkeleton />;
  }

  return (
    <>
      {!objectMember && <div>-</div>}
      {objectMember && <Member member={objectMember} displayAvatar hideEmail />}
    </>
  );
}
MemberCellWithUserId.propTypes = {
  userId: PropTypes.string,
  loading: PropTypes.bool,
  dispatchGetUserData: PropTypes.func,
  members: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  members: selectUsers(),
});

const mapDispatchToProps = {
  dispatchGetUserData: getUserData,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(MemberCellWithUserId);
