import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function HelpCircle(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{props.title || props.intl.formatMessage(globalMessages.help_center)}</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2581 8.02434C11.7926 7.94449 11.3139 8.03197 10.9067 8.27129C10.4995 8.5106 10.1901 8.88631 10.0334 9.33187C9.85009 9.85286 9.27917 10.1266 8.75818 9.94336C8.23719 9.76008 7.96342 9.18917 8.14669 8.66818C8.46016 7.77707 9.0789 7.02565 9.8933 6.54702C10.7077 6.06839 11.6652 5.89343 12.5963 6.05313C13.5273 6.21283 14.3718 6.69688 14.9801 7.41955C15.5884 8.14207 15.9213 9.0565 15.92 10.0009C15.9196 11.5313 14.785 12.5419 13.9747 13.0821C13.5391 13.3725 13.1106 13.586 12.7949 13.7263C12.6357 13.7971 12.5016 13.8508 12.4049 13.8876C12.3565 13.9061 12.3173 13.9204 12.2887 13.9305L12.254 13.9427L12.2429 13.9465L12.239 13.9478L12.2375 13.9483C12.2372 13.9484 12.2363 13.9487 11.92 13L12.2363 13.9487C11.7123 14.1234 11.146 13.8402 10.9713 13.3162C10.7968 12.7927 11.0795 12.2267 11.6028 12.0517L11.6015 12.0521C11.6016 12.0521 11.6017 12.0521 11.6028 12.0517L11.6187 12.0461C11.6341 12.0406 11.6593 12.0315 11.6929 12.0187C11.7604 11.993 11.8606 11.9529 11.9826 11.8987C12.2295 11.789 12.551 11.6276 12.8653 11.418C13.5549 10.9582 13.92 10.4692 13.92 10L13.92 9.99853C13.9207 9.52621 13.7543 9.06889 13.4501 8.70755C13.1459 8.34622 12.7237 8.10419 12.2581 8.02434Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z"
        fill={props.fill}
      />
    </svg>
  );
}
HelpCircle.propTypes = {
  title: PropTypes.string,
  fill: PropTypes.string,
  intl: intlShape.isRequired,
};

HelpCircle.defaultProps = {
  title: '',
  fill: '#172134',
};

export default injectIntl(HelpCircle);
