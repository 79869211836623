import React from 'react';

import AlertOnly from 'components/SvgComponents/icons/AlertOnly';
import AlertTriangle from 'components/SvgComponents/icons/AlertTriangle';
import PrioritiesArrowUp from 'components/SvgComponents/icons/ArrowHigh';
import PrioritiesArrowLow from 'components/SvgComponents/icons/ArrowLow';
import ChevronDown from 'components/SvgComponents/icons/ChevronDown';
import ChevronsDown from 'components/SvgComponents/icons/ChevronsDown';
import ChevronsUp from 'components/SvgComponents/icons/ChevronsUp';
import ChevronUp from 'components/SvgComponents/icons/ChevronUp';
import PrioritiesCircle from 'components/SvgComponents/icons/Circle';
import PrioritiesLosange from 'components/SvgComponents/icons/Diamond';
import EmojiHappy from 'components/SvgComponents/icons/EmojiHappy';
import EmojiNeutral from 'components/SvgComponents/icons/EmojiNeutral';
import EmojiSad from 'components/SvgComponents/icons/EmojiSad';
import PrioritiesFive from 'components/SvgComponents/icons/Five';
import PrioritiesFour from 'components/SvgComponents/icons/Four';
import Minus from 'components/SvgComponents/icons/Minus';
import MinusCircle from 'components/SvgComponents/icons/MinusCircle';
import PrioritiesOne from 'components/SvgComponents/icons/One';
import PrioritiesThree from 'components/SvgComponents/icons/Three';
import PrioritiesTriangle from 'components/SvgComponents/icons/Triangle';
import PrioritiesTwo from 'components/SvgComponents/icons/Two';
import Xoctagon from 'components/SvgComponents/icons/Xoctagon';
import { typeIconSuperAdminLibrary, typeIconSuperAdminLibraryOne } from 'utils/constants';

export const getIcon = (name, color, props = {}) => {
  switch (name) {
    case typeIconSuperAdminLibraryOne.emojiSad:
      return <EmojiSad color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.emojiNeutral:
      return <EmojiNeutral color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.emojiHappy:
      return <EmojiHappy color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.chevronUp:
      return <ChevronUp color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.chevronsUp:
      return <ChevronsUp color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.chevronDown:
      return <ChevronDown color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.chevronsDown:
      return <ChevronsDown color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.minusCircle:
      return <MinusCircle color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.alertTriangle:
      return <AlertTriangle color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.minus:
      return <Minus color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.xoctagon:
      return <Xoctagon color={color} {...props} />;
    case typeIconSuperAdminLibraryOne.alertOnly:
      return <AlertOnly color={color} {...props} />;
    case typeIconSuperAdminLibrary.arrowDown:
      /* Old icons */
      return <PrioritiesArrowLow color={color} {...props} />;
    case typeIconSuperAdminLibrary.arrowUp:
      return <PrioritiesArrowUp color={color} {...props} />;
    case typeIconSuperAdminLibrary.circle:
      return <PrioritiesCircle color={color} {...props} />;
    case typeIconSuperAdminLibrary.losange:
      return <PrioritiesLosange color={color} {...props} />;
    case typeIconSuperAdminLibrary.triangle:
      return <PrioritiesTriangle color={color} {...props} />;
    case typeIconSuperAdminLibrary.one:
      return <PrioritiesOne color={color} {...props} />;
    case typeIconSuperAdminLibrary.two:
      return <PrioritiesTwo color={color} {...props} />;
    case typeIconSuperAdminLibrary.three:
      return <PrioritiesThree color={color} {...props} />;
    case typeIconSuperAdminLibrary.four:
      return <PrioritiesFour color={color} {...props} />;
    case typeIconSuperAdminLibrary.five:
      return <PrioritiesFive color={color} {...props} />;
    default:
      return '';
  }
};
