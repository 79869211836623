import React from 'react';

import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const styles = theme => ({
  defaultStyle: {
    background: theme.palette.background.primary,
    borderRadius: '30px',
    animation: `colorChange ease-in-out 4s infinite`,
  },
  circle: {
    borderRadius: '50%',
  },
  radius: {
    borderRadius: theme.spacing(),
  },
  '@keyframes colorChange': {
    '25%': {
      background: theme.palette.background.primary,
    },
    '75%': {
      background: theme.palette.background.secondary,
    },
    '100%': {
      background: theme.palette.background.primary,
    },
  },
});

const Placeholder = props => {
  const { shape, height, width, classes, className } = props;

  return (
    <div
      className={classNames(classes.defaultStyle, className, {
        [classes.circle]: shape === 'circle',
        [classes.radius]: shape === 'radius',
      })}
      style={{ minWidth: width, width, height }}
    />
  );
};

Placeholder.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  shape: PropTypes.string,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

Placeholder.defaultProps = {};

export default withStyles(styles)(Placeholder);
