import React from 'react';

import { ListItem, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter, NavLink } from 'react-router-dom';
import { compose } from 'redux';

import Undo from 'components/SvgComponents/icons/Undo';
import { styles } from 'theme/nav-link-theme';
import globalMessages from 'translations/messages/global-messages';

export function NavBack(props) {
  const { intl, classes, label, to } = props;

  return (
    <ListItem
      button
      component={NavLink}
      to={to}
      classes={{
        root: classes.backButton,
      }}
    >
      <Typography noWrap color="inherit" className={classes.backBtnLabel}>
        {label || intl.formatMessage(globalMessages.back)}
      </Typography>
      <Undo width="16" height="16" />
    </ListItem>
  );
}

NavBack.propTypes = {
  classes: PropTypes.object,
  intl: intlShape.isRequired,
  label: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  injectIntl,
  withStyles(styles),
)(NavBack);
