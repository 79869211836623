import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectAccountSettings = state => state.accountSettingsPage || initialState;

const selectLoadingFlag = () =>
  createSelector(selectAccountSettings, dataState => dataState.get('loading'));

export { selectLoadingFlag };
