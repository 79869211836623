export const cleanFiltersBeforeSave = filters => {
  if (!filters || filters.constructor.name !== 'Object') return {};
  const filterKeys = Object.keys(filters);
  if (!filterKeys.length) return {};

  const tempActiveFilter = {};
  filterKeys.forEach(key => {
    const activeFilterByKey = filters[key];
    if (Array.isArray(activeFilterByKey)) {
      tempActiveFilter[key] = [];
      for (let i = 0; i < activeFilterByKey.length; i++) {
        const item = activeFilterByKey[i];
        if (item?.ids) {
          for (let j = 0; j < item.ids.length; j++) {
            const id = item.ids[j];
            tempActiveFilter[key].push(id);
          }
        } else {
          tempActiveFilter[key].push(item?.id !== undefined ? item.id : item);
        }
      }
    } else {
      tempActiveFilter[key] = activeFilterByKey;
    }
  });

  return tempActiveFilter;
};
