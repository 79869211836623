import { fromJS } from 'immutable';

import { subscriptionPeriodicity } from 'utils/constants';

import {
  SET_AVAILABLE_OFFERS,
  SET_LOADING_FLAG,
  SET_CURRENT_PLAN,
  SET_ALL_MEMBERS,
  RESET_CONTRACT_PAGE_STATE,
  SET_NUMBER_USERS,
  SET_MONTHLY_PRICE,
} from './constants';

export const initialState = fromJS({
  loading: false,
  plan: {},
  offers: [],
  members: 0,
  monthly: true,
  users: 0,
});

function contractsPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case SET_CURRENT_PLAN:
      return state
        .set('plan', fromJS(action.plan))
        .set('monthly', action.plan?.price?.periodicity !== subscriptionPeriodicity.year);
    case SET_AVAILABLE_OFFERS:
      return state.set('offers', fromJS(action.offers));
    case SET_ALL_MEMBERS:
      return state.set('members', action.members);
    case SET_NUMBER_USERS:
      return state.set('users', action.users);
    case SET_MONTHLY_PRICE:
      return state.set('monthly', action.monthly);
    case RESET_CONTRACT_PAGE_STATE:
      return initialState;
    default:
      return state;
  }
}
export default contractsPageReducer;
