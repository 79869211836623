import React from 'react';

import { FormattedMessage } from 'react-intl';

import config from 'config/config';
import globalMessages from 'translations/messages/global-messages';

export const required = value => (value === null || value === '' ? 'Required' : undefined);

export const phoneNumber = value =>
  value &&
  !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value)
    ? 'Invalid phone'
    : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email' : '';

export const numericNormalizer = (val, prevVal) => {
  if (val) {
    return /^\d+$/.test(val) ? parseInt(val, 10) : prevVal;
  }
  if (val === '') {
    return '0';
  }
  return val;
};

export const numericNormalizerEmptyIncluded = (val, prevVal) => {
  if (val) {
    return /^\d+$/.test(val) ? parseInt(val, 10) : prevVal;
  }
  return val;
};

export const numericNormalizerExcludeZero = (val, prevVal) => {
  if (val) {
    return /^\d+$/.test(val) && val > 0 ? parseInt(val, 10) : prevVal;
  }
  return val;
};

export const normalizePhone = value => {
  if (!value) {
    return value;
  }

  return value.replace(/[^\d]/g, '');
};

export const validateEmail = emails => {
  const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,64}$/i;
  return re.test(String(emails).toLowerCase());
};

// PASSWORD VALIDATION
// This regex will enforce these rules:
// At least one upper case English letter, (?=.*?[A-Z])
// At least one lower case English letter, (?=.*?[a-z])
// At least one digit, (?=.*?[0-9])
// At least one special character, (?=.*?[#?!@$%^&*-])
// Minimum eight in length .{8,} (with the anchors)

export const isValidPassword = password => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return re.test(password);
};

export const isValidSecureURL = str =>
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    str,
  ) &&
  (str.includes(config.apiHostGateway) ||
    str.replace('.eu.', '.').includes(config.apiHostGateway) ||
    str.replace('.ap.', '.').includes(config.apiHostGateway));

export const normalize = value => value.replace(/^\s+/g, '');

export const requiredByKey = (values, key) => {
  const errors = {};

  const keyValue = values?.get(key);
  if (!keyValue || !keyValue?.trim() || required(keyValue)) {
    errors.name = <FormattedMessage {...globalMessages.required} />;
  }
  return errors;
};

export const validate = (values, key) => {
  const errors = {};

  const keyValue = values[key];
  if (!keyValue || !keyValue?.trim() || required(keyValue)) {
    errors.name = <FormattedMessage {...globalMessages.required} />;
  }
  return errors;
};
