import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import ArrowBack from 'components/SvgComponents/icons/ArrowBack';
import CloseSvg from 'components/SvgComponents/icons/Close';

export const styles = theme => ({
  dialogTitle: {
    display: 'flex',
    flexBasis: 'content',
    alignItems: 'center',
    height: '3.5em',
    backgroundColor: theme.palette.background.primary,
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.125rem',
    color: theme.palette.text.primary,
    lineHeight: '120%',
    justifyContent: 'center',
    paddingRight: 50,
  },
  singleLineTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  closeIcon: {
    position: 'absolute',
    right: '0.250em',
    color: theme.palette.text.dark,
  },
  backIcon: {
    position: 'absolute',
    left: '0.250em',
    color: theme.palette.text.dark,
  },
  contentRoot: {
    padding: 0,
    flexDirection: 'column',
    display: 'flex',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  dialogAction: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    height: `3.5em`,
    paddingRight: '1.5em',
  },
  paper: {
    backgroundColor: theme.palette.background.white,
    backgroundImage: 'none',
  },
  bottomSection: {
    display: 'flex',
    paddingLeft: 8,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
});

export const UIDialog = props => {
  const {
    classes,
    id,
    onCloseDialog,
    onBackDialog,
    onExitDialog,
    open,
    dialogTitleNode,
    dialogClasses,
    dialogTitleClasses,
    dialogContentClasses,
    dialogActionClasses,
    dialogActionNode,
    maxWidth = false,
    fullWidth,
    fullScreen,
    children,
    loading,
    showTitle,
    closeIconClassName,
    displayCloseIcon,
    bottomInformation,
    dialogActionVariant,
    maxHeightPx,
  } = props;
  const shouldDisplayCloseIcon = displayCloseIcon && (onCloseDialog || !loading);
  const dialogHeightStyle = maxHeightPx ? { maxHeight: `${maxHeightPx}px` } : {};

  return (
    <Dialog
      id={id}
      fullScreen={fullScreen}
      onClose={onCloseDialog}
      maxWidth={maxWidth}
      classes={{ paper: classes.paper, ...{ ...dialogClasses } }}
      sx={{
        '& .MuiDialog-paper': dialogHeightStyle,
      }}
      TransitionProps={{
        onExit: onExitDialog,
      }}
      fullWidth={fullWidth}
      open={open}
      aria-labelledby={`form-${id}`}
    >
      {showTitle ? (
        <DialogTitle className={classes.dialogTitle} classes={{ ...dialogTitleClasses }}>
          {onBackDialog && (
            <IconButton onClick={onBackDialog} className={classes.backIcon} color="primary">
              <ArrowBack />
            </IconButton>
          )}
          <div className={classes.singleLineTitle}>{dialogTitleNode}</div>
          {shouldDisplayCloseIcon && (
            <IconButton
              onClick={onCloseDialog}
              className={classnames(classes.closeIcon, {
                [closeIconClassName]: !!closeIconClassName,
              })}
              color="primary"
            >
              <CloseSvg />
            </IconButton>
          )}
        </DialogTitle>
      ) : null}
      <DialogContent
        classes={{
          root: classes.contentRoot,
          ...dialogContentClasses,
        }}
      >
        {children || 'No content'}
      </DialogContent>
      {dialogActionNode &&
        !loading && (
          <div className={classes.bottomSection}>
            {bottomInformation}
            <DialogActions
              style={{
                flexGrow: 1,
                justifyContent: dialogActionVariant === 'navigation' ? 'space-between' : 'end',
                border: 'none',
              }}
              classes={dialogActionClasses || { root: classes.dialogAction }}
            >
              {dialogActionNode}
            </DialogActions>
          </div>
        )}
    </Dialog>
  );
};

UIDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  open: PropTypes.bool,
  children: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  maxHeightPx: PropTypes.number,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  loading: PropTypes.bool,
  dialogTitleNode: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dialogClasses: PropTypes.object,
  dialogTitleClasses: PropTypes.object,
  dialogContentClasses: PropTypes.object,
  onCloseDialog: PropTypes.func,
  onExitDialog: PropTypes.func,
  onBackDialog: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([false])]),
  dialogActionClasses: PropTypes.object,
  dialogActionNode: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  dialogActionVariant: PropTypes.string,
  showTitle: PropTypes.bool,
  closeIconClassName: PropTypes.string,
  displayCloseIcon: PropTypes.bool,
  bottomInformation: PropTypes.node,
};

UIDialog.defaultProps = {
  id: uuid.v4(),
  loading: false,
  showTitle: true,
  displayCloseIcon: true,
  dialogActionVariant: 'default',
};

export default withStyles(styles)(UIDialog);
