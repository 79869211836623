import React from 'react';

import GroupTitle from 'components/LeftNavigation/GroupTitle';
import NavBack from 'components/LeftNavigation/NavBack';
import NavDivider from 'components/LeftNavigation/NavDivider';
import OrganizationPublicID from 'components/LeftNavigation/OrganizationPublicID';
import Activity from 'components/SvgComponents/icons/Activity';
import AlertCircle from 'components/SvgComponents/icons/AlertCircle';
import Circle from 'components/SvgComponents/icons/Circle';
import Clipboard from 'components/SvgComponents/icons/Clipboard';
import Diamond from 'components/SvgComponents/icons/Diamond';
import Folder from 'components/SvgComponents/icons/Folder';
import GridSvg from 'components/SvgComponents/icons/Grid';
import Home from 'components/SvgComponents/icons/Home';
import Information from 'components/SvgComponents/icons/Information';
import ListView from 'components/SvgComponents/icons/ListView';
import MessageMulti from 'components/SvgComponents/icons/MessageMulti';
import OpenBook from 'components/SvgComponents/icons/OpenBook';
import Organization from 'components/SvgComponents/icons/Organization';
import PeriodicExport from 'components/SvgComponents/icons/PeriodicExport';
import Phase from 'components/SvgComponents/icons/Phase';
import Plan from 'components/SvgComponents/icons/Plan';
import Plans from 'components/SvgComponents/icons/Plans';
import Settings from 'components/SvgComponents/icons/Settings';
import Signature from 'components/SvgComponents/icons/Signature';
import Tool from 'components/SvgComponents/icons/Tool';
import Users from 'components/SvgComponents/icons/Users';
import BetaTag from 'components/Tile/BetaTag';
import NewTag from 'components/Tile/NewTag';
import FormTree from 'containers/FormPage/components/FormTree';
import WorkspaceSwitcher from 'containers/WorkspaceSwitcher';
import { Routing } from 'routing/routing';
import globalMessages from 'translations/messages/global-messages';
import {
  authorizations,
  oneHomePages,
  oneProjectPages,
  oneProjectSubPages,
  organizationsPages,
} from 'utils/constants';
import localStorageUser from 'utils/localStorageUser';

const iconSize = 16;

export const oneProjectPage = ({ intl, match, projectRights, modules, featureFlags }) => {
  const { organizationId, idData } = match.params;

  return [
    {
      key: oneProjectPages.home,
      url: Routing.home.home(organizationId, idData),
      label: intl.formatMessage(globalMessages.home),
      icon: <Home />,
    },
    {
      key: oneProjectPages.documents,
      url: Routing.documents.list(organizationId, idData),
      label: intl.formatMessage(globalMessages.documents),
      icon: <Folder />,
    },
    {
      key: oneProjectPages.plans,
      url: Routing.plans.root(organizationId, idData),
      label: intl.formatMessage(globalMessages.plans),
      icon: <Plans />,
    },
    {
      key: oneProjectPages.groups,
      url: Routing.groups.list(organizationId, idData),
      label: intl.formatMessage(globalMessages.groups),
      icon: <MessageMulti />,
    },
    {
      key: oneProjectPages.export,
      url: Routing.exports.list(organizationId, idData),
      label: intl.formatMessage(globalMessages.reports_title),
      icon: <PeriodicExport />,
    },
    {
      component: <NavDivider size="extra-small" />,
      key: 'NavDivider_oneProjectPage_3',
    },
    {
      key: oneProjectSubPages.module,
      label: intl.formatMessage(globalMessages.modules),
      items: [...modules],
    },
    {
      component: <NavDivider size="extra-small" />,
      key: 'NavDivider_oneProjectPage_4',
    },
    {
      key: oneProjectSubPages.analytics,
      hide: !featureFlags?.web_project_cumulio,
      label: intl.formatMessage(globalMessages.analytics),
      rightsList: projectRights,
      permissions: [authorizations.project.readSettings],
      items: [
        {
          key: oneProjectPages.dashboard,
          url: Routing.projects.dashboard(organizationId, idData),
          label: intl.formatMessage(globalMessages.dashboard),
          icon: <Activity />,
        },
      ],
    },
    {
      component: <NavDivider size="extra-small" />,
      hide: !featureFlags?.web_project_cumulio,
      key: 'NavDivider_oneProjectPage_5',
    },
    {
      key: oneProjectSubPages.settings,
      label: intl.formatMessage(globalMessages.settings),
      items: [
        {
          key: oneProjectPages.members,
          url: Routing.members.list(organizationId, idData),
          label: intl.formatMessage(globalMessages.members),
          icon: <Users title={intl.formatMessage(globalMessages.members)} />,
        },
        {
          key: oneProjectPages.companies,
          url: Routing.companies.list(organizationId, idData),
          label: intl.formatMessage(globalMessages.companies),
          icon: <Tool />,
          rightsList: projectRights,
          permissions: [authorizations.project.writeLibrary],
        },
        {
          key: oneProjectPages.settings,
          url: Routing.projects.settings(organizationId, idData),
          label: intl.formatMessage(globalMessages.project_settings_details_title),
          icon: <Settings />,
          rightsList: projectRights,
          permissions: [authorizations.project.readSettings],
        },
        {
          key: oneProjectPages.forms,
          url: Routing.forms.settings(organizationId, idData),
          label: intl.formatMessage(globalMessages.forms),
          icon: <Clipboard />,
          rightsList: projectRights,
          permissions: [authorizations.project.writeFormTemplate],
        },
        {
          key: oneProjectPages.phases,
          url: Routing.phases.settings(organizationId, idData),
          label: intl.formatMessage(globalMessages.phases),
          icon: <Phase />,
          rightsList: projectRights,
          permissions: [authorizations.project.readSettings],
        },
        ...(featureFlags?.edit_project_statuses
          ? [
              {
                key: oneProjectPages.statuses,
                url: Routing.statuses.settings(organizationId, idData),
                label: intl.formatMessage(globalMessages.statuses),
                icon: <ListView />,
                rightsList: projectRights,
                permissions: [authorizations.project.handleModules],
              },
            ]
          : []),
      ],
    },
  ].filter(item => !item.hide);
};

export const oneSuperAdminPage = (intl, rightsList) => [
  {
    key: 'one-super-admin-back',
    component: (
      <NavBack
        to={Routing.organizations.projectList(
          localStorageUser.getBusinessOrganizationLocalStorage()?.id,
        )}
        label={intl.formatMessage(globalMessages.projects)}
      />
    ),
  },
  { component: <NavDivider />, key: 'NavDivider_oneSuperAdminPage_1' },
  {
    key: Routing.superAdmin.manageOrganizations(),
    url: Routing.superAdmin.manageOrganizations(),
    label: intl.formatMessage(globalMessages.manage_organizations),
    icon: (
      <Organization
        organization={localStorageUser.getBusinessOrganizationLocalStorage()}
        width={iconSize}
        height={iconSize}
        title={intl.formatMessage(globalMessages.manage_organizations)}
      />
    ),
    permissions: ['All'],
  },
  {
    key: Routing.superAdmin.library(),
    url: Routing.superAdmin.library(),
    label: intl.formatMessage(globalMessages.libraries),
    icon: (
      <Plan
        width={iconSize}
        height={iconSize}
        title={intl.formatMessage(globalMessages.libraries)}
      />
    ),
    permissions: [authorizations.specialUser.readFormFcLibrary],
    rightsList,
  },
];

export const oneSuperAdminLibraryPage = (intl, rightsList, featureFlags) => {
  const oneSuperAdminLibraryPageArray = [
    {
      key: 'one-super-admin-back',
      component: <NavBack to={Routing.superAdmin.manageOrganizations()} />,
    },
    { component: <NavDivider />, key: 'NavDivider' },
    {
      key: Routing.superAdmin.priority(),
      url: Routing.superAdmin.priority(),
      label: intl.formatMessage(globalMessages.priority),
      icon: <Diamond width={iconSize} height={iconSize} />,
      permissions: [authorizations.specialUser.readObservationFcLibrary],
      rightsList,
    },
    {
      key: Routing.superAdmin.status(),
      url: Routing.superAdmin.status(),
      label: intl.formatMessage(globalMessages.status),
      icon: <Circle width={iconSize} height={iconSize} />,
      permissions: [authorizations.specialUser.readObservationFcLibrary],
      rightsList,
    },

    {
      key: Routing.superAdmin.observations(),
      url: Routing.superAdmin.observations(),
      label: intl.formatMessage(globalMessages.observations),
      icon: <AlertCircle width={iconSize} height={iconSize} />,
      permissions: [authorizations.specialUser.readObservationFcLibrary],
      rightsList,
    },
  ];
  if (featureFlags?.superadmin_can_see_form_libraries) {
    oneSuperAdminLibraryPageArray.splice(4, 0, {
      key: Routing.superAdmin.forms(),
      url: Routing.superAdmin.forms(),
      label: intl.formatMessage(globalMessages.form_library),
      icon: <Clipboard width={iconSize} height={iconSize} />,
      permissions: [authorizations.specialUser.readFormFcLibrary],
      rightsList,
    });
  }

  return oneSuperAdminLibraryPageArray;
};

export const formPage = (intl, businessOrganizationId, workspaceId) => {
  const redirect = workspaceId
    ? Routing.workspaces.templateList(businessOrganizationId, workspaceId)
    : Routing.organizations.templateList(businessOrganizationId);
  return [
    {
      key: 'form-template-back',
      component: (
        <NavBack
          to={businessOrganizationId ? redirect : Routing.superAdmin.forms()}
          label={intl.formatMessage(globalMessages.templates)}
        />
      ),
    },
    { key: 'form-structure', component: <FormTree /> },
  ];
};

export const organizationPage = (intl, match, rightsList, featureFlags) => {
  const { organizationId } = match.params;
  const organizationPageArray = [
    {
      component: <WorkspaceSwitcher key="organizationSwitcher" placement="bottom-start" />,
      key: 'WorkspaceSwitcher_1',
    },
    {
      component: <OrganizationPublicID key="organizationPublicID" />,
      key: 'OrganizationPublicID_1',
    },
    {
      component: <NavDivider size="medium" />,
      key: 'NavDivider_organizationPage_2',
    },
    {
      component: <GroupTitle label={intl.formatMessage(globalMessages.organization_settings)} />,
      key: 'GroupTitle_1',
    },
    {
      key: Routing.organizations.info(organizationId),
      url: Routing.organizations.info(organizationId),
      label: intl.formatMessage(globalMessages.information),
      icon: (
        <Information
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.information)}
        />
      ),
      permissions: [authorizations.organization.handleOrganizationLibrary],
      rightsList,
    },
    {
      key: organizationsPages.templates,
      url: Routing.organizations.templateHome(organizationId),
      label: intl.formatMessage(globalMessages.templates),
      icon: (
        <OpenBook
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.templates)}
        />
      ),
      other: <NewTag />,
      permissions: [authorizations.organization.handleOrganizationLibrary],
      rightsList,
    },
    {
      key: oneHomePages.members,
      url: Routing.organizations.members(organizationId),
      label: intl.formatMessage(globalMessages.members),
      icon: (
        <Users
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.members)}
        />
      ),
      permissions: [authorizations.organization.handleMembers],
      rightsList,
    },
    {
      key: organizationsPages.workspaces,
      url: Routing.organizations.workspaceList(organizationId),
      label: intl.formatMessage(globalMessages.workspaces),
      icon: (
        <GridSvg
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.workspaces)}
        />
      ),
      permissions: [
        authorizations.organization.handleWorkspaces,
        authorizations.organization.handleWorkspacesLimited,
      ],
      rightsList,
    },
    {
      key: oneHomePages.contracts,
      url: Routing.organizations.contract(organizationId),
      label: intl.formatMessage(globalMessages.membership_plan),
      icon: (
        <Signature
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.membership_plan)}
        />
      ),
      permissions: [authorizations.organization.writeOffer],
      rightsList,
    },
  ];

  if (featureFlags?.web_cumulio) {
    organizationPageArray.splice(7, 0, {
      key: Routing.organizations.dashboard(organizationId),
      url: Routing.organizations.dashboard(organizationId),
      label: intl.formatMessage(globalMessages.dashboard),
      icon: (
        <Activity
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.dashboard)}
        />
      ),
      permissions: [authorizations.organization.handleWorkspaces],
      rightsList,
    });
  }

  return organizationPageArray;
};

export const workspacePage = (intl, match, rightsList, featureFlags) => {
  const { organizationId, workspaceId } = match.params;
  const workspacePageArray = [
    {
      component: <WorkspaceSwitcher key="workspaceSwitcher" placement="bottom-start" />,
      key: 'WorkspaceSwitcher',
    },
    {
      component: <OrganizationPublicID key="organizationPublicID" />,
      key: 'OrganizationPublicID_2',
    },
    {
      component: <NavDivider size="medium" />,
      key: 'NavDivider_workspacePage_2',
    },
    {
      component: <GroupTitle label={intl.formatMessage(globalMessages.workspace_settings)} />,
      key: 'GroupTitle_0',
    },
    {
      key: Routing.workspaces.info(organizationId, workspaceId),
      url: Routing.workspaces.info(organizationId, workspaceId),
      label: intl.formatMessage(globalMessages.information),
      icon: (
        <Information
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.information)}
        />
      ),
    },
    {
      key: organizationsPages.templates,
      url: Routing.workspaces.templateHome(organizationId, workspaceId),
      label: intl.formatMessage(globalMessages.templates),
      icon: (
        <OpenBook
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.templates)}
        />
      ),
      other: <NewTag />,
      permissions: [
        authorizations.organization.handleOrganizationLibrary,
        authorizations.organization.handleWorkspaceLibrary,
      ],
      rightsList,
    },
    {
      key: organizationsPages.members,
      url: Routing.workspaces.members(organizationId, workspaceId),
      label: intl.formatMessage(globalMessages.members),
      icon: (
        <Users
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.members)}
        />
      ),
    },
  ];

  if (featureFlags?.web_cumulio) {
    workspacePageArray.splice(7, 0, {
      key: Routing.workspaces.dashboard(organizationId, workspaceId),
      url: Routing.workspaces.dashboard(organizationId, workspaceId),
      label: intl.formatMessage(globalMessages.dashboard),
      icon: (
        <Activity
          width={iconSize}
          height={iconSize}
          title={intl.formatMessage(globalMessages.dashboard)}
        />
      ),
      other: <BetaTag />,
    });
  }

  return workspacePageArray;
};
