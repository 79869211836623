export const ORANGE_LIGHT = '#FFF3EA';
export const ORANGE = '#FF8029';

export const GREEN_LIGHT = '#49CB0C1A';
export const GREEN = '#49CB0C';

export const RED_LIGHT = '#FDEDEC';
export const RED = '#E94F3D';
export const RED_DARK = '#B33A2D';

export const YELLOW_LIGHT = '#FEFAEC';
export const YELLOW = '#F7CE46';
export const YELLOW_DARK = '#EBBB1C';

export const BLUE_LIGHT = '#EDF7FE';
export const BLUE = '#4CABF2';
export const BLUE_DARK = '#3489C9';

export const PURPLE_LIGHT = '#F4F3FE';
export const PURPLE = '#E6E3FE';
export const PURPLE_DARK = '#4C67F2';

export const GREY_LIGHT = '#F4F6F8';
export const GREY = '#CED2D8';
export const GREY_DARK = '#848F9D';
export const DARK_BLUE = '#172134';

export const VIOLET_LIGHT = '#9747FF';

// DARK THEME
export const BLACK_BLUE = '#101825';
export const GREY_BLUE = '#202A3C';
export const GREY_DARKER = '#414C5A';

// PRIORITY
export const PRIORITY_BROWN = '#898989';
export const PRIORITY_RED = '#E94F3D';
export const PRIORITY_ORANGE = '#FF8029';
export const PRIORITY_YELLOW = '#EBBB1C';
export const PRIORITY_DARK_GREEN = '#399223';
export const PRIORITY_LIGHT_GREEN = '#49CB0C';
export const PRIORITY_DARK_BLUE = '#264AA7';
export const PRIORITY_LIGHT_BLUE = '#4CABF2';
export const PRIORITY_PURPLE = '#7121F3';
export const PRIORITY_PINK = '#DC3B89';

// MODULE
export const OBSERVATION = '#E94F3D';
export const FORM = '#F7CE46';
export const DOCUMENT = '#4CABF2';
export const FILE = '#848F9D';

// Offer TAG
export const YELLOW_ALPHA = '#F7CE4646';
