import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import Pdf from 'components/SvgComponents/icons/Pdf';
import dropZoneStyle from 'theme/dropzone-theme';
import globalMessages from 'translations/messages/global-messages';

function FileErrorPreviewPdf(props) {
  const { classes, intl, width, height, classNameTitle, classNameSubtitle, classIcon } = props;

  return (
    <>
      <Pdf className={classIcon || classes.icon} width={width || '48'} height={height || '48'} />
      <Typography className={classNames(classes.instruction, classNameTitle)}>
        {intl.formatMessage(globalMessages.uploaded_pdf)}
      </Typography>
      <Typography className={classNames(classes.supported, classNameSubtitle)}>
        {intl.formatMessage(globalMessages.file_not_displayed_on_web)}
      </Typography>
    </>
  );
}

FileErrorPreviewPdf.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  classNameTitle: PropTypes.string,
  classNameSubtitle: PropTypes.string,
  classIcon: PropTypes.string,
};
export default compose(
  withStyles(dropZoneStyle),
  injectIntl,
)(FileErrorPreviewPdf);
