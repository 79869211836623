import React, { useContext, memo, useEffect } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';
import OktaLoginWrap from 'containers/OktaLogin/components/OktaLoginWrap';
import CreateAccount from 'containers/OktaLogin/CreateAccount';
import { selectLoadingFlag } from 'containers/OktaLogin/selectors';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import { Routing } from 'routing/routing';
import localStorageUser from 'utils/localStorageUser';
import sessionStorageUser from 'utils/sessionStorageUser';

import {
  addToProjectByInvitation,
  checkEmailRegistration,
  createAndAttachMedia,
  getAllOrganizations,
  getBusinessOrganization,
  getCurrentOffer,
  getModulesObservationsAndMeeting,
  getModulesForms,
} from './actions';
import {
  selectAllOrganizationsLoadingFlag,
  selectAllOrganizationsMap,
  selectEmailRegistrationMap,
  selectLoggedUserData,
} from './selectors';

function DataValidation({
  children,
  onValidationSuccess,
  dispatchAddToProjectByInvitation,
  dispatchCheckEmailRegistration,
  dispatchGetOrganizations,
  dispatchGetCurrentOffer,
  match,
  loggedUserData,
}) {
  const { authState, oktaAuth } = useOktaAuth();
  const { params } = match;
  const history = useHistory();
  const dispatch = useDispatch();
  const deepLink = sessionStorageUser.getDeepLink();
  const invitationsLink = sessionStorageUser.getInvitationsLink();
  const emailVerificationLinkData = localStorageUser.getEmailVerificationLink();
  const idp = sessionStorageUser.getIdp();
  const isIdpUser = !!idp?.id;
  const authStateFcUserId = authState?.accessToken?.claims?.fcUserId;

  // toJS() here instead of in the selector avoids an unnecessary re-render
  const organization = useContext(OrganizationContext);
  const organizations = useSelector(selectAllOrganizationsMap()).toJS();
  const userRegistrationInfo = useSelector(selectEmailRegistrationMap())?.toJS();
  const loading = useSelector(selectLoadingFlag());
  const loadingAllOrganizations = useSelector(selectAllOrganizationsLoadingFlag());

  const featureFlags = useFeatureFlags();

  const callback = () => {
    dispatch(getModulesForms(params.idData));
  };

  useEffect(
    () => {
      if (params.idData) {
        dispatch(getModulesObservationsAndMeeting(params.idData, featureFlags, callback));
      }
    },
    [dispatch, params.idData],
  );

  const forward = sessionStorageUser.getForwardRedirect();
  if (forward && loggedUserData) {
    const { projectId, organizationId, email } = forward;
    const currentUserEmail = loggedUserData.email;
    sessionStorageUser.setForwardRedirect(null);
    if (currentUserEmail === email && organizationId) {
      if (projectId) {
        return history.push(Routing.home.home(organizationId, projectId));
      }
      return history.push(Routing.home.projects(organizationId));
    }
  }

  const verifyEmailValidation = () => {
    const emailValidationData = localStorageUser.getEmailValidationLocation();
    history.push(emailValidationData !== null ? emailValidationData : Routing.general.welcome);
  };

  const handleCallBackOrganizations = data => {
    const firstOrganization = data?.organizations[0];
    if (deepLink?.organizationId && deepLink?.projectId) {
      return history.push(Routing.home.home(deepLink.organizationId, deepLink.projectId));
    }

    if (invitationsLink?.length > 0) {
      const firstInvitation = invitationsLink[0];
      if (firstInvitation?.organization_id && firstInvitation?.project_id) {
        sessionStorageUser.setInvitationsLink(invitationsLink.slice(1));
        return history.push(
          Routing.home.home(firstInvitation.organization_id, firstInvitation.project_id),
        );
      }
    }

    if (emailVerificationLinkData) {
      verifyEmailValidation();
    }

    if (!firstOrganization?.id) {
      return history.push(Routing.general.welcome);
    }

    return onValidationSuccess();
  };

  const checkFirstIdp = () => {
    // If user is IDP, has fcUserId but not temp Registration this means he has logged in with Standard IDP (Google, Microsoft, Apple)
    // and his email has never been checked in our system, so we check the email first\
    const userIdentifier = authState?.idToken?.claims?.email || authState?.accessToken?.claims?.sub;
    dispatchCheckEmailRegistration(userIdentifier, () =>
      dispatchGetOrganizations(handleCallBackOrganizations),
    );
  };

  const processAuthenticatedCase = () => {
    if (organization?.id) {
      dispatchGetCurrentOffer(organization.id);
    }
    if (isIdpUser) {
      checkFirstIdp();
    } else {
      dispatchGetOrganizations(handleCallBackOrganizations);
    }
  };

  useEffect(
    () => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated,redirect to login
        history.push('/login');
      } else if (authState && authState.isAuthenticated) {
        window.globalOktaAuth = oktaAuth;
        if (authStateFcUserId && !loading && deepLink) {
          dispatchAddToProjectByInvitation(deepLink.url, () => {
            sessionStorageUser.setDeepLink(null);
            dispatchGetOrganizations(handleCallBackOrganizations);
          });
        } else if (authStateFcUserId && !loading && !organizations.length) {
          processAuthenticatedCase();
        } else if (authState?.accessToken && !authStateFcUserId) {
          const userIdentifier =
            authState?.idToken?.claims?.email || authState?.accessToken?.claims?.sub;
          if (isIdpUser) {
            dispatchCheckEmailRegistration(userIdentifier);
          }
          console.error(
            '%c No fcUserId returned by the oktaAuth',
            'color: red;font-size: 18px, Creating account',
          );
        }
      }
    },
    [authState, params.idData],
  ); // Update if authState changes

  // get all organisations
  const renderCreateAccount = () => {
    if (!authState?.isAuthenticated) {
      return null;
    }

    if (!authStateFcUserId) {
      return (
        <CreateAccount
          form="create-account"
          userRegistrationInfo={userRegistrationInfo}
          completeIdp
        />
      );
    }
    return null;
  };

  if (!authState || !authState?.isAuthenticated || loadingAllOrganizations) {
    return null;
  }
  if (!authStateFcUserId && !loading) {
    return <OktaLoginWrap>{renderCreateAccount()}</OktaLoginWrap>;
  }
  return <>{children}</>;
}

DataValidation.propTypes = {
  children: PropTypes.node,
  match: PropTypes.object,
  dispatchGetOrganizations: PropTypes.func,
  onValidationSuccess: PropTypes.func,
  dispatchAddToProjectByInvitation: PropTypes.func,
  dispatchCheckEmailRegistration: PropTypes.func,
  dispatchGetCurrentOffer: PropTypes.func,
  loggedUserData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  loggedUserData: selectLoggedUserData(),
});

DataValidation.defaultProps = {
  onValidationSuccess: () => null,
};

const mapDispatchToProps = {
  dispatchGetOrganizations: getAllOrganizations,
  dispatchGetOrganization: getBusinessOrganization,
  dispatchAddToProjectByInvitation: addToProjectByInvitation,
  dispatchCheckEmailRegistration: checkEmailRegistration,
  dispatchCreateAndAttachMedia: createAndAttachMedia,
  dispatchGetCurrentOffer: getCurrentOffer,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default memo(
  compose(
    withConnect,
    withRouter,
  )(DataValidation),
);
