import React from 'react';

import PropTypes from 'prop-types';

function Five(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || '5'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm0 14.7c-2.398 0-3.2-1.802-3.102-2.7H8.7c0 .398.301 1.2 1.301 1.2 1.3 0 1.3-1.5 1.3-1.7 0-1.602-1.198-1.602-1.5-1.602-.8 0-1.198.403-1.198.5L7.102 10l.5-4.7H12.8v1.5H9.1l-.203 2c.102 0 .602-.3 1.403-.3 2.699 0 2.8 2.602 2.8 3.102 0 1.597-1 3.097-3.101 3.097z"
      />
    </svg>
  );
}
Five.propTypes = {
  title: PropTypes.string,
};

Five.defaultProps = {
  title: '',
};

export default Five;
