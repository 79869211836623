import React from 'react';

import { Card } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import pageStyle from 'theme/one/pages-content-theme';

const PageWrapper = ({ classes, children, paddings, smallPaddings }) => (
  <div className={classes.pageWrapper}>
    <div
      className={classNames({
        [classes.contentWrapper]: true,
        [classes.contentWrapperPadding]: paddings,
        [classes.contentWrapperSmallPadding]: smallPaddings,
      })}
    >
      <Card className={classes.cardWrapper} elevation={0}>
        {children}
      </Card>
    </div>
  </div>
);

PageWrapper.propTypes = {
  children: PropTypes.node,
  paddings: PropTypes.bool,
  classes: PropTypes.object,
  smallPaddings: PropTypes.bool,
};
PageWrapper.defaultProps = {
  paddings: true,
  smallPaddings: false,
};

export default withStyles(pageStyle)(PageWrapper);
