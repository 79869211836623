import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function HelpCircleFill(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{props.title || props.intl.formatMessage(globalMessages.help_center)}</title>

      <g id="help-circle">
        <path
          id="Vector"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M9.08984 9.00008C9.32495 8.33175 9.789 7.76819 10.3998 7.40921C11.0106 7.05024 11.7287 6.91902 12.427 7.03879C13.1253 7.15857 13.7587 7.52161 14.2149 8.06361C14.6712 8.60561 14.9209 9.2916 14.9198 10.0001C14.9198 12.0001 11.9198 13.0001 11.9198 13.0001"
          stroke={props.fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M12 17H12.01"
          stroke={props.fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
HelpCircleFill.propTypes = {
  title: PropTypes.string,
  fill: PropTypes.string,
  intl: intlShape.isRequired,
};

HelpCircleFill.defaultProps = {
  title: '',
  fill: '#172134',
};

export default injectIntl(HelpCircleFill);
