import { GET_IMAGE, SET_IMAGE } from './constants';

export function getImage(url, quality, callback, showFallback, forceRefresh) {
  return { type: GET_IMAGE, url, quality, callback, showFallback, forceRefresh };
}

export function setImage(url, image, quality) {
  return {
    type: SET_IMAGE,
    url,
    image,
    quality,
  };
}
