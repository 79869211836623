import { fromJS } from 'immutable';

import { SET_LOADING_FLAG } from './constants';

export const initialState = fromJS({
  loading: false,
});
function navigationBarReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    default:
      return state;
  }
}
export default navigationBarReducer;
