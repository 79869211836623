import React from 'react';

function FrSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      width="60px"
      height="36px"
      {...props}
    >
      <rect y="85.331" style={{ fill: '#F0F0F0' }} width="512" height="341.337" />
      <rect y="85.331" style={{ fill: '#0052B4' }} width="170.663" height="341.337" />
      <rect x="341.337" y="85.331" style={{ fill: '#D80027' }} width="170.663" height="341.337" />
    </svg>
  );
}

export default FrSvg;
