import { setPopoupBlockerObject } from 'containers/GlobalWrapper/actions';

export const downloadFile = (url, fileName) => {
  window.finalcadReduxStore.dispatch(setPopoupBlockerObject({ url, name: fileName }));
};

const mimeTypes = {
  '3gp': 'video/3gpp',
  '3gpp': 'video/3gpp',
  asf: 'video/x-ms-asf',
  asx: 'video/x-ms-asf',
  atom: 'application/atom+xml',
  avi: 'video/x-msvideo',
  avif: 'image/avif',
  bmp: 'image/x-ms-bmp',
  css: 'text/css',
  csv: 'text/csv',
  dmg: [
    'application/x-diskcopy',
    'dmg.dwg',
    'application/x-apple-diskimage',
    'application_x-apple-diskimage',
  ],
  doc: 'application/msword',
  docm: 'application/vnd.ms-word.document.macroEnabled.12',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dot: 'application/msword',
  dotm: 'application/vnd.ms-word.template.macroEnabled.12',
  dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  dwg: 'application/acad',
  flv: 'video/x-flv',
  gif: 'image/gif',
  htc: 'text/x-component',
  htm: 'text/html',
  html: 'text/html',
  ico: 'image/x-icon',
  ifc: ['application/x-ifc', 'model/x-ifc'],
  jad: 'text/vnd.sun.j2me.app-descriptor',
  jng: 'image/x-jng',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'application/javascript',
  json: 'application/json',
  kar: 'audio/midi',
  key: 'application/x-iwork-keynote-sffkey',
  log: 'text/plain',
  m4a: 'audio/x-m4a',
  m4v: 'video/x-m4v',
  md: 'text/markdown',
  mid: 'audio/midi',
  midi: 'audio/midi',
  mml: 'text/mathml',
  mng: 'video/x-mng',
  mov: 'video/quicktime',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  odt: 'application/vnd.oasis.opendocument.text',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ogg: 'audio/ogg',
  pdf: 'application/pdf',
  png: 'image/png',
  pot: 'application/vnd.ms-powerpoint',
  potm: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
  potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  ppa: 'application/vnd.ms-powerpoint',
  ppam: 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  pps: 'application/vnd.ms-pps',
  ppsm: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  ppt: 'application/vnd.ms-powerpoint',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ra: 'audio/x-realaudio',
  rar: 'application/vnd.rar',
  rss: 'application/rss+xml',
  rvt: 'application/vnd.autodesk.revit',
  shtml: 'text/html',
  svg: 'image/svg+xml',
  svgz: 'image/svg+xml',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  ts: 'video/mp2t',
  txt: 'text/plain',
  wbmp: 'image/vnd.wap.wbmp',
  webm: 'video/webm',
  webp: 'image/webp',
  wml: 'text/vnd.wap.wml',
  wmv: 'video/x-ms-wmv',
  xla: 'application/vnd.ms-excel',
  xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
  xls: 'application/vnd.ms-excel',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlt: 'application/vnd.ms-excel',
  xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
  xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  xml: 'text/xml',
  zip: 'application/zip',
};

export const mimeTypesArray = Object.entries(mimeTypes).flatMap(
  ([ext, type]) => (Array.isArray(type) ? type.map(t => ({ ext, type: t })) : [{ ext, type }]),
);

const hasFileExtension = (filename, mimeType) => {
  const fileNameArray = filename.split('.');

  if (fileNameArray.length > 1 && filename.split('.').pop() && !mimeType) {
    return true;
  }
  if (fileNameArray.length > 1 && filename.split('.').pop()) {
    const extension = fileNameArray[fileNameArray.length - 1];
    const expectedMimeType = mimeTypes[extension];
    if (Array.isArray(expectedMimeType)) {
      return expectedMimeType.includes(mimeType);
    }
    return mimeType === expectedMimeType;
  }
  return false;
};

export const getExtension = item => {
  const type =
    item.media_resource?.mime_type ||
    item.media_resource_mime_type ||
    item?.export_media_resource?.mime_type ||
    item?.mime_type;

  const mimeTypeEntry = mimeTypesArray.find(entry => entry.type === type);
  return mimeTypeEntry ? `.${mimeTypeEntry.ext}` : '';
};

const getMIMEType = item =>
  item?.media_resource?.mime_type ||
  item?.media_resource_mime_type ||
  item?.export_media_resource?.mime_type;

export const getFileName = item => {
  const filename =
    item?.name || item?.media_resource?.file_name || item?.export_media_resource.file_name;

  if (filename && hasFileExtension(filename, getMIMEType(item))) {
    return filename;
  }
  if (filename && !!getMIMEType(item)) {
    return `${filename}${getExtension(item)}`;
  }
  return item.name;
};

export const getFileUrl = item =>
  item.media_resource?.url ||
  item.media_resource_url ||
  item.thumbnail_url ||
  item?.export_media_resource?.url;

export const getPlanFileFormat = item => {
  const type = item.media_resource?.mime_type || item.media_resource_mime_type;
  switch (type) {
    case 'image/jpeg':
    case 'image/png':
      return 'image';
    case 'application/pdf':
      return 'pdf';
    case 'application/acad':
      return 'dwg';
    default:
      return 'ifc';
  }
};

export const downloadBlob = (result, fileName) => {
  const fakeUrl = window.URL.createObjectURL(new Blob(result));
  downloadFile(fakeUrl, fileName);
};

export const getBinaryDataFileWindow = uuidBlobFile =>
  window.blobStore[uuidBlobFile].replace(/^data:.+;base64,/, '');

const chunkSize = 8092; // 8KB

export function readFileFirstLine(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let offset = 0;
    let line = '';

    const processChunk = (chunkOffset, size) => {
      const blob = file.slice(chunkOffset, chunkOffset + size);
      reader.readAsText(blob);
    };

    reader.onload = e => {
      const text = e.target.result;
      // Find the first line break (\r, \n or \r\n)
      const newLineIndex = text.search(/(\r\n|\n|\r)/);
      if (newLineIndex !== -1) {
        line += text.slice(0, newLineIndex);
        resolve(line);
        return;
      }

      line += text;
      offset += chunkSize;

      if (offset >= file.size) {
        resolve(line);
      } else {
        // eslint-disable-next-line no-use-before-define
        processChunk(offset, chunkSize);
      }
    };

    reader.onerror = e => {
      reject(e.target.error);
    };

    processChunk(offset, chunkSize);
  });
}
