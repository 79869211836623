import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

const UnCheckBox = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.uncheck)}</title>

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9997 2.66671H3.99967C3.26329 2.66671 2.66634 3.26366 2.66634 4.00004V12C2.66634 12.7364 3.26329 13.3334 3.99967 13.3334H11.9997C12.7361 13.3334 13.333 12.7364 13.333 12V4.00004C13.333 3.26366 12.7361 2.66671 11.9997 2.66671ZM3.99967 1.33337C2.52692 1.33337 1.33301 2.52728 1.33301 4.00004V12C1.33301 13.4728 2.52691 14.6667 3.99967 14.6667H11.9997C13.4724 14.6667 14.6663 13.4728 14.6663 12V4.00004C14.6663 2.52728 13.4724 1.33337 11.9997 1.33337H3.99967Z"
    />
  </svg>
);

UnCheckBox.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(UnCheckBox);
