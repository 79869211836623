import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Placeholder from './Placeholder';

const styles = () => ({
  border: {
    borderRadius: '5px',
  },
});

const MediaCellSkeleton = props => {
  const { classes } = props;
  return <Placeholder className={classes.border} height="2rem" width="2rem" />;
};

MediaCellSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaCellSkeleton);
