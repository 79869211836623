/**
 * Determines the flex-shrink, flex-grow, and width values for a cell (header or column).
 */
import React from 'react';

export const getFlexStyleForColumn = (column, customStyle = {}) => {
  const flexValue = `${column.props.flexGrow} ${column.props.flexShrink} ${column.props.width}px`;

  const style = {
    ...customStyle,
    flex: flexValue,
    msFlex: flexValue,
    WebkitFlex: flexValue,
  };

  if (column.props.maxWidth) {
    style.maxWidth = column.props.maxWidth;
  }

  if (column.props.minWidth) {
    style.minWidth = column.props.minWidth;
  }

  return style;
};

export const defaultCellRenderer = ({ cellData }) => {
  if (cellData == null) {
    return '';
  }
  return String(cellData);
};

export const defaultCellDataGetter = ({ dataKey, rowData }) => {
  if (typeof rowData?.get === 'function') {
    return rowData?.get(dataKey);
  }
  return rowData[dataKey];
};

export const defaultHeaderRenderer = params => {
  const { label } = params;
  return (
    <span key="label" title={typeof label === 'string' ? label : null}>
      {label}
    </span>
  );
};

export const defaultHeaderRowRenderer = params => {
  const { className, columns, style, ref } = params;

  return (
    <div className={className} role="row" style={style} ref={ref}>
      {columns}
    </div>
  );
};
