import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

const Button = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.button)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9998 6.00002H1.99984L1.99984 10H13.9998V6.00002ZM1.99984 4.66669C1.26346 4.66669 0.666504 5.26364 0.666504 6.00002V10C0.666504 10.7364 1.26346 11.3334 1.99984 11.3334H13.9998C14.7362 11.3334 15.3332 10.7364 15.3332 10V6.00002C15.3332 5.26364 14.7362 4.66669 13.9998 4.66669H1.99984Z"
    />
  </svg>
);
Button.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(Button);
