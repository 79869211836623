import React from 'react';

import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ListView from 'components/SvgComponents/icons/ListView';
import ThumbView from 'components/SvgComponents/icons/ThumbView';

export const TABLE_VIEW = 'TABLE_VIEW';
export const LIST_VIEW = 'LIST_VIEW';

const styles = theme => ({
  actionsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    flexShrink: 0,
    marginTop: 0,
    minHeight: 40,
  },
  iconButton: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.secondary,
    padding: 8,
    marginRight: theme.spacing(),
  },
  activeIcon: {
    '&:disabled': {
      background: theme.palette.background.secondary,
      color: theme.palette.common.white,
    },
  },
});

export function ActionsBar(props) {
  const { classes, activeView, toggleView, disabled, className } = props;
  const handleToggleView = view => () => props.onViewChange(view);

  return (
    <div className={classNames(classes.actionsWrap, className)}>
      {props.children}
      {toggleView && (
        <div>
          {activeView === LIST_VIEW && (
            <IconButton
              onClick={handleToggleView(TABLE_VIEW)}
              disabled={disabled}
              className={classNames({
                [classes.iconButton]: true,
                [classes.activeIcon]: activeView === TABLE_VIEW,
              })}
            >
              <ListView />
            </IconButton>
          )}
          {activeView === TABLE_VIEW && (
            <IconButton
              onClick={handleToggleView(LIST_VIEW)}
              disabled={disabled}
              className={classNames({
                [classes.iconButton]: true,
                [classes.activeIcon]: activeView === LIST_VIEW,
              })}
            >
              <ThumbView />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
}

ActionsBar.propTypes = {
  classes: PropTypes.object,
  toggleView: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  activeView: PropTypes.string,
  onViewChange: PropTypes.func,
};
ActionsBar.defaultProps = {
  activeView: TABLE_VIEW,
  onViewChange: () => null,
};

export default withStyles(styles)(ActionsBar);
