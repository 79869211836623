import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function Undo(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{props.title || props.intl.formatMessage(globalMessages.cancel)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9L9.70711 13.2929C10.0976 13.6834 10.0976 14.3166 9.70711 14.7071C9.31658 15.0976 8.68342 15.0976 8.29289 14.7071L3.29289 9.70711C2.90237 9.31658 2.90237 8.68342 3.29289 8.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9C3 8.44772 3.44772 8 4 8H16C17.3261 8 18.5979 8.52678 19.5355 9.46447C20.4732 10.4021 21 11.6739 21 13V15C21 18.3137 18.3137 21 15 21H6C5.44772 21 5 20.5523 5 20C5 19.4477 5.44772 19 6 19H15C17.2091 19 19 17.2091 19 15V13C19 12.2044 18.6839 11.4413 18.1213 10.8787C17.5587 10.3161 16.7956 10 16 10H4C3.44772 10 3 9.55228 3 9Z"
      />
    </svg>
  );
}
Undo.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Undo.defaultProps = {
  title: '',
};

export default injectIntl(Undo);
