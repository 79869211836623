import React from 'react';

import { ClickAwayListener, Paper, Popper } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import Button from 'components/Button';
import ChevronDown from 'components/SvgComponents/icons/ChevronDown';
import ChevronUp from 'components/SvgComponents/icons/ChevronUp';
import combineStyles from 'theme/combineStyles';
import tableActionsStyles from 'theme/table-actions-theme';

import { TypographyEllipsis } from '../Templates/TypographyEllipsis';

export const styles = theme => ({
  filterPaper: {
    minWidth: 200,
    borderRadius: 12,
    boxShadow: '0px 6px 15px rgba(23, 33, 52, 0.15)',
  },
  filterWrap: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    minHeight: '40vh',
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  smallButton: {
    backgroundColor: 'transparent',
  },
});

function ActionDropdown(props) {
  const {
    classes,
    disabled,
    label,
    open,
    name,
    className,
    component,
    onToggleMenu,
    placement,
    size,
    labelEllipsisWidth,
    value,
  } = props;
  const buttonEl = React.useRef(null);

  const getIcon = () => {
    if (disabled) return null;
    return open ? <ChevronUp /> : <ChevronDown />;
  };

  const renderButton = () => {
    if (component) {
      const COMPONENT = component;
      return (
        <COMPONENT
          ref={buttonEl}
          aria-owns={`actionMenu-popper-${name}`}
          aria-haspopup="true"
          onClick={onToggleMenu}
        >
          {label}
        </COMPONENT>
      );
    }

    return (
      <Button
        disabled={disabled}
        ref={buttonEl}
        className={classNames({
          [className]: className,
          [classes.smallButton]: size === 'small',
        })}
        aria-owns={`actionMenu-popper-${name}`}
        aria-haspopup="true"
        onClick={onToggleMenu}
        variant="action"
        size={size}
        endIcon={getIcon()}
      >
        <TypographyEllipsis
          sx={{ maxWidth: labelEllipsisWidth }}
          noWrap
          variant={value ? 'body' : 'bodyLight'}
        >
          {label}
        </TypographyEllipsis>
      </Button>
    );
  };

  return (
    <>
      {renderButton()}
      <Popper
        autoFocus={false}
        id={`actionMenu-popper-${name}`}
        key={name}
        open={open}
        anchorEl={buttonEl.current}
        onClose={props.onToggleMenu}
        className={classes.popper}
        placement={placement}
      >
        <ClickAwayListener onClickAway={props.onToggleMenu}>
          <Paper
            classes={{
              root: classes.filterPaper,
            }}
          >
            {props.children}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

ActionDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  component: PropTypes.object,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onToggleMenu: PropTypes.func,
  placement: PropTypes.string,
  size: PropTypes.string,
  labelEllipsisWidth: PropTypes.string,
  value: PropTypes.string,
};

ActionDropdown.defaultProps = {
  open: false,
  placement: 'bottom-end',
  labelEllipsisWidth: '90%',
};

export default compose(
  injectIntl,
  withStyles(combineStyles(styles, tableActionsStyles)),
)(ActionDropdown);
