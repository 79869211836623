/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
const addLocaleData = require('react-intl').addLocaleData; //eslint-disable-line
const caLocaleData = require('react-intl/locale-data/ca');
const thLocaleData = require('react-intl/locale-data/th');
const viLocaleData = require('react-intl/locale-data/vi');
const zfLocaleData = require('react-intl/locale-data/zh');
const plLocaleData = require('react-intl/locale-data/pl');
const csLocaleData = require('react-intl/locale-data/cs');
const svLocaleData = require('react-intl/locale-data/sv');
const daLocaleData = require('react-intl/locale-data/da');
const deLocaleData = require('react-intl/locale-data/de');
const enLocaleData = require('react-intl/locale-data/en');
const esLocaleData = require('react-intl/locale-data/es');
const fiLocaleData = require('react-intl/locale-data/fi');
const frLocaleData = require('react-intl/locale-data/fr');
const heLocaleData = require('react-intl/locale-data/he');
const huLocaleData = require('react-intl/locale-data/hu');
const itLocaleData = require('react-intl/locale-data/it');
const jaLocaleData = require('react-intl/locale-data/ja');
const koLocaleData = require('react-intl/locale-data/ko');
const nlLocaleData = require('react-intl/locale-data/nl');
const noLocaleData = require('react-intl/locale-data/no');
const ptLocaleData = require('react-intl/locale-data/pt');
const ruLocaleData = require('react-intl/locale-data/ru');
const slLocaleData = require('react-intl/locale-data/sl');
const zhLocaleData = require('react-intl/locale-data/zh');

// en
const enGlobalTranslationMessages = require('./translations/en-global.json');

const enTranslationMessages = {
  ...enGlobalTranslationMessages,
};

// ca
const caGlobalTranslationMessages = require('./translations/ca-global.json');

const caTranslationMessages = {
  ...caGlobalTranslationMessages,
};

// zh
const zhGlobalTranslationMessages = require('./translations/zh-global.json');

const zhTranslationMessages = {
  ...zhGlobalTranslationMessages,
};

// fr
const frGlobalTranslationMessages = require('./translations/fr-global.json');

const frTranslationMessages = {
  ...frGlobalTranslationMessages,
};

// de
const deGlobalTranslationMessages = require('./translations/de-global.json');

const deTranslationMessages = {
  ...deGlobalTranslationMessages,
};

// it
const itGlobalTranslationMessages = require('./translations/it-global.json');

const itTranslationMessages = {
  ...itGlobalTranslationMessages,
};

// ja
const jaGlobalTranslationMessages = require('./translations/ja-global.json');

const jaTranslationMessages = {
  ...jaGlobalTranslationMessages,
};

// ko
const koGlobalTranslationMessages = require('./translations/ko-global.json');

const koTranslationMessages = {
  ...koGlobalTranslationMessages,
};

// nl
const nlGlobalTranslationMessages = require('./translations/nl-global.json');

const nlTranslationMessages = {
  ...nlGlobalTranslationMessages,
};

// pt
const ptGlobalTranslationMessages = require('./translations/pt-global.json');

const ptTranslationMessages = {
  ...ptGlobalTranslationMessages,
};

// es
const esGlobalTranslationMessages = require('./translations/es-global.json');

const esTranslationMessages = {
  ...esGlobalTranslationMessages,
};

// th
const thGlobalTranslationMessages = require('./translations/th-global.json');

const thTranslationMessages = {
  ...thGlobalTranslationMessages,
};

// vi
const viGlobalTranslationMessages = require('./translations/vi-global.json');

const viTranslationMessages = {
  ...viGlobalTranslationMessages,
};

// zf
const zfGlobalTranslationMessages = require('./translations/zf-global.json');

const zfTranslationMessages = {
  ...zfGlobalTranslationMessages,
};

// pl
const plGlobalTranslationMessages = require('./translations/pl-global.json');

const plTranslationMessages = {
  ...plGlobalTranslationMessages,
};

// cs
const csGlobalTranslationMessages = require('./translations/cs-global.json');

const csTranslationMessages = {
  ...csGlobalTranslationMessages,
};

// sv
const svGlobalTranslationMessages = require('./translations/sv-global.json');

const svTranslationMessages = {
  ...svGlobalTranslationMessages,
};

// da
const daGlobalTranslationMessages = require('./translations/da-global.json');

const daTranslationMessages = {
  ...daGlobalTranslationMessages,
};

// no
const noGlobalTranslationMessages = require('./translations/no-global.json');

const noTranslationMessages = {
  ...noGlobalTranslationMessages,
};

// ru
const ruGlobalTranslationMessages = require('./translations/ru-global.json');

const ruTranslationMessages = {
  ...ruGlobalTranslationMessages,
};

// fi
const fiGlobalTranslationMessages = require('./translations/fi-global.json');

const fiTranslationMessages = {
  ...fiGlobalTranslationMessages,
};

// he
const heGlobalTranslationMessages = require('./translations/he-global.json');

const heTranslationMessages = {
  ...heGlobalTranslationMessages,
};

// hu
const huGlobalTranslationMessages = require('./translations/hu-global.json');

const huTranslationMessages = {
  ...huGlobalTranslationMessages,
};

// sl
const slGlobalTranslationMessages = require('./translations/sl-global.json');

const slTranslationMessages = {
  ...slGlobalTranslationMessages,
};

addLocaleData([
  ...enLocaleData,
  ...caLocaleData,
  ...zhLocaleData,
  ...frLocaleData,
  ...deLocaleData,
  ...itLocaleData,
  ...jaLocaleData,
  ...koLocaleData,
  ...nlLocaleData,
  ...ptLocaleData,
  ...esLocaleData,
  ...thLocaleData,
  ...viLocaleData,
  ...zfLocaleData,
  ...plLocaleData,
  ...csLocaleData,
  ...svLocaleData,
  ...daLocaleData,
  ...noLocaleData,
  ...ruLocaleData,
  ...fiLocaleData,
  ...heLocaleData,
  ...huLocaleData,
  ...slLocaleData,
]);

const DEFAULT_LOCALE = 'en';

// prettier-ignore
const appLocales = [
  'en',
  'ca',
  'zh',
  'fr',
  'de',
  'it',
  'ja',
  'ko',
  'nl',
  'pt',
  'es',
  'th',
  'vi',
  'zf',
  'pl',
  'cs',
  'sv',
  'da',
  'no',
  'ru',
  'fi',
  'he',
  'hu',
  'sl',
];

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  ca: formatTranslationMessages('ca', caTranslationMessages),
  zh: formatTranslationMessages('zh', zhTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
  it: formatTranslationMessages('it', itTranslationMessages),
  ja: formatTranslationMessages('ja', jaTranslationMessages),
  ko: formatTranslationMessages('ko', koTranslationMessages),
  nl: formatTranslationMessages('nl', nlTranslationMessages),
  pt: formatTranslationMessages('pt', ptTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
  th: formatTranslationMessages('th', thTranslationMessages),
  vi: formatTranslationMessages('vi', viTranslationMessages),
  zf: formatTranslationMessages('zf', zfTranslationMessages),
  pl: formatTranslationMessages('pl', plTranslationMessages),
  cs: formatTranslationMessages('cs', csTranslationMessages),
  sv: formatTranslationMessages('sv', svTranslationMessages),
  da: formatTranslationMessages('da', daTranslationMessages),
  no: formatTranslationMessages('no', noTranslationMessages),
  ru: formatTranslationMessages('ru', ruTranslationMessages),
  fi: formatTranslationMessages('fi', fiTranslationMessages),
  he: formatTranslationMessages('he', heTranslationMessages),
  hu: formatTranslationMessages('hu', huTranslationMessages),
  sl: formatTranslationMessages('sl', slTranslationMessages),
};

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
