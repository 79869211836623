import React from 'react';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import InvitationExpired from 'components/InvitationExpired';
import config from 'config/config';
import AllSetPage from 'containers/AllSetPage/Loadable';
import CallBackPaymentPage from 'containers/ContractPage/CallBackPaymentPage';
import PaymentPage from 'containers/ContractPage/PaymentPage';
import FormPage from 'containers/FormPage/Loadable';
import OktaLoginPage from 'containers/OktaLogin/Loadable';
import AuthRedirect from 'containers/OktaLogin/LoadableAfterLogin';
import OneProjectPage from 'containers/OneProjectPage/Loadable';
import OneProjectPlanPage from 'containers/OneProjectPlanPage/Loadable';
import OrganizationPage from 'containers/OrganizationPage/Loadable';
import Page403 from 'containers/Page403/Loadable';
import Page404 from 'containers/Page404/Loadable';
import Page404Project from 'containers/Page404Project/Loadable';
import Projects from 'containers/Projects/Loadable';
import SuperAdminPage from 'containers/SuperAdmin/Loadable';
import WorkspacePage from 'containers/WorkspacePage';
import { appPaths, pathFallback } from 'utils/constants';
import localStorageUser from 'utils/localStorageUser';
import sessionStorageUser from 'utils/sessionStorageUser';

import { ProtectedRoute } from './protected-route';
import RedirectProjectLegacyRoute from './RedirectProjectLegacyRoute';

const createRedirectIfNeeded = (fromPath, toPath, exact) => {
  if (fromPath !== toPath) {
    return <Redirect key={`${fromPath}-redirect`} exact={exact} from={fromPath} to={toPath} />;
  }
  return null;
};

const createRoutesWithFallback = (path, component, exact = false) => {
  const routes = [<ProtectedRoute key={path} exact={exact} path={path} component={component} />];

  let replacedPath = path;
  pathFallback.forEach(([search, replace]) => {
    replacedPath = replacedPath.replace(new RegExp(replace, 'g'), search);
  });
  const redirect = createRedirectIfNeeded(replacedPath, path, exact);
  if (redirect) routes.push(redirect);

  pathFallback.forEach(([search, replace]) => {
    const newPath = path.replace(new RegExp(replace, 'g'), search);
    const redirectRoute = createRedirectIfNeeded(newPath, path, exact);
    if (redirectRoute) routes.push(redirectRoute);
  });

  return routes;
};

const AppWithRouterAccess = () => {
  const oktaAuth = new OktaAuth({
    issuer: config.okta.oidc.issuer,
    clientId: config.okta.oidc.clientId,
    redirectUri: `${window.location.origin}/login/callback`,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    tokenManager: { autoRenew: true },
    pkce: true,
  });
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  const customAuthHandler = () => {
    // Redirect to the /login page
    // history.push('/login');
    sessionStorageUser.setFinalcadTempRegistrationInfo(null);
    sessionStorageUser.setIsSignUp(null);
    localStorageUser.removeOktaTokenStorage();
    // this is a hack when token expiress
    oktaAuth.signOut();
  };
  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        <Route exact path="/" component={AuthRedirect} />
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/login" component={OktaLoginPage} />
        <Route exact path="/invitation/expired" component={InvitationExpired} />
        <ProtectedRoute exact path="/welcome" component={AllSetPage} />
        {createRoutesWithFallback('/organization/:organizationId/projects', Projects, true).map(
          route => route,
        )}
        <Route path="/projects/:idData" component={RedirectProjectLegacyRoute} />
        <Route path="/projects" component={RedirectProjectLegacyRoute} />
        {createRoutesWithFallback(
          '/organization/:organizationId/workspace/:workspaceId/form/:formId',
          FormPage,
          true,
        ).map(route => route)}
        {createRoutesWithFallback('/organization/:organizationId/form/:formId', FormPage).map(
          route => route,
        )}
        {createRoutesWithFallback(
          '/organization/:organizationId/workspace/:workspaceId/:pageName?/:itemId?/:subItemId?',
          WorkspacePage,
        ).map(route => route)}
        {createRoutesWithFallback(appPaths.projectPage, OneProjectPage, true).map(route => route)}
        {createRoutesWithFallback(
          '/organization/:organizationId/project/:idData/plans/folder/:itemId/plan/:planId/:planType/:associatedPlanId?',
          OneProjectPlanPage,
        ).map(route => route)}
        {createRoutesWithFallback('/organization/:organizationId/form/:formId', FormPage).map(
          route => route,
        )}
        {createRoutesWithFallback(
          '/organization/:organizationId/:pageName?/:itemId?/:subItemId?',
          OrganizationPage,
          true,
        ).map(route => route)}
        <ProtectedRoute
          exact
          path="/super-admin/:pageName/:libraryName?/:itemId?"
          component={SuperAdminPage}
        />
        <Route path="/403" component={Page403} />
        <Route path="/organizations/:organizationId/404" component={Page404Project} />
        <Route path="/404" component={Page404} />
        <Route path="/checkout" component={PaymentPage} />
        <Route path="/return" component={CallBackPaymentPage} />
        <Route component={Page404} />
      </Switch>
    </Security>
  );
};

export default AppWithRouterAccess;
