import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function RefreshCW(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <title>{props.title || props.intl.formatMessage(globalMessages.refresh)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.47822 2.87819C8.61278 2.62786 7.69802 2.60085 6.81931 2.79966C5.94061 2.99848 5.12658 3.41666 4.4532 4.01517C3.77982 4.61368 3.26902 5.37301 2.96848 6.22233C2.84565 6.56942 2.4647 6.75123 2.1176 6.62841C1.7705 6.50558 1.5887 6.12463 1.71152 5.77753C2.0872 4.71589 2.7257 3.76672 3.56742 3.01858C4.40915 2.27044 5.42668 1.74773 6.52507 1.4992C7.62345 1.25068 8.7669 1.28445 9.8487 1.59736C10.9271 1.90928 11.9092 2.48844 12.7039 3.28107L14.6667 5.12535V2.66659C14.6667 2.2984 14.9651 1.99992 15.3333 1.99992C15.7015 1.99992 16 2.2984 16 2.66659V6.66659C16 6.84341 15.9298 7.01298 15.8047 7.138C15.6797 7.26302 15.5101 7.33326 15.3333 7.33326L11.3333 7.33325C10.9651 7.33325 10.6667 7.03477 10.6667 6.66658C10.6667 6.29839 10.9651 5.99992 11.3333 5.99992L13.6503 5.99992L11.7835 4.24576C11.7784 4.24099 11.7734 4.23614 11.7685 4.23121C11.1316 3.59401 10.3437 3.12852 9.47822 2.87819ZM1.33333 10.8745V13.3333C1.33333 13.7014 1.03486 13.9999 0.666667 13.9999C0.298477 13.9999 0 13.7014 0 13.3333V9.33326C0 8.96507 0.298476 8.6666 0.666667 8.66659L4.66667 8.66659C5.03485 8.66658 5.33333 8.96506 5.33333 9.33325C5.33333 9.70144 5.03486 9.99992 4.66667 9.99992L2.3497 9.99992L4.21652 11.7541C4.2216 11.7589 4.2266 11.7637 4.23152 11.7686C4.86841 12.4058 5.65634 12.8713 6.52178 13.1217C7.38722 13.372 8.30198 13.399 9.18069 13.2002C10.0594 13.0014 10.8734 12.5832 11.5468 11.9847C12.2202 11.3862 12.731 10.6268 13.0315 9.77753C13.1544 9.43043 13.5353 9.24862 13.8824 9.37145C14.2295 9.49428 14.4113 9.87523 14.2885 10.2223C13.9128 11.284 13.2743 12.2331 12.4326 12.9813C11.5908 13.7294 10.5733 14.2521 9.47493 14.5007C8.37655 14.7492 7.2331 14.7154 6.1513 14.4025C5.07292 14.0906 4.09083 13.5114 3.29606 12.7188L1.33333 10.8745Z"
      />
    </svg>
  );
}
RefreshCW.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

RefreshCW.defaultProps = {
  title: '',
};

export default injectIntl(RefreshCW);
