import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

const ChevronRight = props => (
  <svg
    id="ChevronDownSvg"
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox="0 0 12 12"
    {...props}
  >
    <title>{props.title || props.intl.formatMessage(globalMessages.right)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.14645 2.64645C4.34171 2.45118 4.65829 2.45118 4.85355 2.64645L7.85355 5.64645C8.04881 5.84171 8.04881 6.15829 7.85355 6.35355L4.85355 9.35355C4.65829 9.54882 4.34171 9.54882 4.14645 9.35355C3.95118 9.15829 3.95118 8.84171 4.14645 8.64645L6.79289 6L4.14645 3.35355C3.95118 3.15829 3.95118 2.84171 4.14645 2.64645Z"
    />
  </svg>
);

ChevronRight.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
  size: PropTypes.number,
};

ChevronRight.defaultProps = {
  title: '',
  size: 16,
};

export default injectIntl(ChevronRight);
