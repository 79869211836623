import { SET_LOADING_FLAG, VALIDATE_CODE, SEND_VALIDATION_EMAIL } from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function validateCode(email, code, callback) {
  return {
    type: VALIDATE_CODE,
    email,
    code,
    callback,
  };
}
export function sendValidationEmail(email, callback) {
  return {
    type: SEND_VALIDATION_EMAIL,
    email,
    callback,
  };
}
