function baseProjectPath(organizationId, projectId) {
  return `/organization/${organizationId}/project/${projectId}`;
}

const organizations = {
  dashboard: organizationId => `/organization/${organizationId}/dashboard`,
  projectList: organizationId => `/organization/${organizationId}/projects`,
  info: organizationId => `/organization/${organizationId}/info`,
  contract: organizationId => `/organization/${organizationId}/contracts`,
  members: organizationId => `/organization/${organizationId}/members`,
  formTemplate: (organizationId, formTemplateId) =>
    `/organization/${organizationId}/form/${formTemplateId}`,
  workspaceList: organizationId => `/organization/${organizationId}/workspaces`,

  templateHome: organizationId => `/organization/${organizationId}/templates`,
  templateList: organizationId => `/organization/${organizationId}/templates/forms`,
  templateModules: organizationId => `/organization/${organizationId}/templates/modules`,
  templateStatus: organizationId => `/organization/${organizationId}/templates/status`,
  templatePriority: organizationId => `/organization/${organizationId}/templates/priority`,
  templateObservations: organizationId =>
    `/organization/${organizationId}/templates/common-observations`,
  referentials: organizationId => `/organization/${organizationId}/templates/referentials`,
};

const workspaces = {
  dashboard: (organizationId, workspaceId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/dashboard`,
  members: (organizationId, workspaceId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/members`,
  formTemplate: (organizationId, workspaceId, formTemplateId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/form/${formTemplateId}`,
  info: (organizationId, workspaceId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/info`,
  templateHome: (organizationId, workspaceId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/templates`,
  templateList: (organizationId, workspaceId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/templates/forms`,
  templateModules: (organizationId, workspaceId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/templates/modules`,
  templateStatus: (organizationId, workspaceId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/templates/status`,
  templatePriority: (organizationId, workspaceId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/templates/priority`,
  templateObservations: (organizationId, workspaceId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/templates/common-observations`,
  templateObservationDetail: (organizationId, workspaceId, tradeId) =>
    `/organization/${organizationId}/workspace/${workspaceId}/templates/common-observations/${tradeId}`,
};

const deeplink = {
  expired: () => `/invitation/expired`,
};

const general = {
  welcome: '/welcome',
  formTemplate: formTemplateId => `/form/${formTemplateId}`,
};

const home = {
  home: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/home`,
};

const documents = {
  list: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/documents`,
  folderContent: (organizationId, projectId, folderId) =>
    `${baseProjectPath(organizationId, projectId)}/documents/folder/${folderId}`,
  fileContent: (organizationId, projectId, folderId) =>
    `${baseProjectPath(organizationId, projectId)}/documents/file/${folderId}`,
};

const exports = {
  list: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/export`,
};

const groups = {
  list: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/groups`,
};

const plans = {
  root: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/plans`,
  folder: (organizationId, projectId, folderId) =>
    `${baseProjectPath(organizationId, projectId)}/plans/folder/${folderId}`,
  plan: (organizationId, projectId, folderId, planId, planType) =>
    `${baseProjectPath(
      organizationId,
      projectId,
    )}/plans/folder/${folderId}/plan/${planId}/${planType}`,
  bimSplit: (organizationId, projectId, folderId, bimFileId, id) =>
    `${baseProjectPath(
      organizationId,
      projectId,
    )}/plans/folder/${folderId}/plan/${bimFileId}/bim/${id}`,
  bim: (organizationId, projectId, folderId, bimFileId, id) =>
    `${baseProjectPath(organizationId, projectId)}/plans/folder/${folderId}/plan/${bimFileId}/bim`,
  zone: (organizationId, projectId, folderId, planId) =>
    `${baseProjectPath(organizationId, projectId)}/plans/folder/${folderId}/plan/${planId}/plan`,
  zoning: (organizationId, projectId) =>
    `${baseProjectPath(organizationId, projectId)}/plans/zoning`,
};

const observations = {
  list: (organizationId, projectId, moduleId) =>
    `${baseProjectPath(organizationId, projectId)}/observations/module/${moduleId}`,
  detail: (organizationId, projectId, moduleId, observationId, index) =>
    `${baseProjectPath(
      organizationId,
      projectId,
    )}/observations/module/${moduleId}/observation/${observationId}?index=${index || 0}`,
  categoryList: (organizationId, projectId, moduleId) =>
    `${baseProjectPath(organizationId, projectId)}/observations/module/${moduleId}/categories`,
};

const forms = {
  list: (organizationId, projectId, moduleId) =>
    `${baseProjectPath(organizationId, projectId)}/forms/module/${moduleId}`,
  settings: (organizationId, projectId) =>
    `${baseProjectPath(organizationId, projectId)}/forms-settings`,
  detail: (organizationId, projectId, moduleId, formId, index) =>
    `${baseProjectPath(
      organizationId,
      projectId,
    )}/forms/module/${moduleId}/form/${formId}?index=${index || 0}`,
};

const meetings = {
  list: (organizationId, projectId, moduleId) =>
    `${baseProjectPath(organizationId, projectId)}/meetings/module/${moduleId}`,
  detail: (organizationId, projectId, moduleId, itemId) =>
    `${baseProjectPath(organizationId, projectId)}/meetings/module/${moduleId}/meeting/${itemId}`,
};

const members = {
  list: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/members`,
};

const statuses = {
  settings: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/statuses`,
};

const companies = {
  list: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/companies`,
};

const projects = {
  settings: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/settings`,
  dashboard: (organizationId, projectId) =>
    `${baseProjectPath(organizationId, projectId)}/dashboard`,
};

const phases = {
  settings: (organizationId, projectId) => `${baseProjectPath(organizationId, projectId)}/phases`,
};

const superAdmin = {
  manageOrganizations: () => `/super-admin/manage-organizations`,
  library: () => '/super-admin/library/priority',
  priority: () => '/super-admin/library/priority',
  status: () => '/super-admin/library/status',
  forms: () => '/super-admin/library/forms',
  tools: () => '/super-admin/tools',
  users: () => '/super-admin/users',
  organizations: () => '/super-admin/organisations',
  projects: () => '/super-admin/projects',
  formsTemplateLibrary: () => '/super-admin/library/forms',
  observations: () => '/super-admin/library/common-observations',
  module: itemId => `/super-admin/library/common-observations/${itemId}`,
};

const httpRedirect = {
  status404: () => '/404',
  status404Project: organizationId => `/organizations/${organizationId}/404`,
};

const payment = {
  checkout: () => `/checkout`,
};

const replaceWorkspaceId = (pathname, workspaceId) => {
  const pathSegments = pathname.split('/');
  const workspaceIndex = pathSegments.indexOf('workspace');

  if (workspaceIndex === -1) {
    pathSegments.splice(3, 0, 'workspace', workspaceId);
    const pageName = pathSegments[pathSegments.length - 1];
    if (pageName === 'contracts' || pageName === 'workspaces') {
      pathSegments[pathSegments.length - 1] = 'info';
    }
  } else {
    pathSegments[workspaceIndex + 1] = workspaceId;
  }
  return pathSegments.join('/');
};

export const Routing = {
  workspaces,
  organizations,
  deeplink,
  general,
  home,
  documents,
  exports,
  groups,
  plans,
  observations,
  forms,
  meetings,
  members,
  companies,
  projects,
  phases,
  superAdmin,
  httpRedirect,
  statuses,
  payment,
  replaceWorkspaceId,
};
