/*
 * This is the container that selects the error that is set on the global error state and shows it
 */

import React, { Component } from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import _, { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import CustomizedSnackbar from 'components/Snackbar';
import globalMessages from 'translations/messages/global-messages';
import injectReducer from 'utils/injectReducer';

import { setGlobalError, setGlobalInfo } from './actions';
import reducer from './reducer';
import { selectGlobalError, selectGlobalInfo } from './selectors';

const styles = theme => ({
  globalErrorWrap: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
  },
  errorColor: {
    color: theme.palette.common.white,
  },
});

class ErrorHandler extends Component {
  state = {
    open: false,
    error: null,
    info: null,
  };

  static getDerivedStateFromProps(props, state) {
    let retState = null;
    if (props.error !== state.error || !_.isEqual(props.error, state.error)) {
      retState = {
        error: props.error,
        open: true,
      };
    }
    if (props.info !== state.info || !_.isEqual(props.info, state.info)) {
      retState = {
        ...retState,
        info: props.info,
        open: true,
      };
    }
    return retState;
  }

  handleClose = () => {
    this.setState(
      {
        open: false,
      },
      debounce(() => {
        this.props.dispatchSetGlobalError(null);
        this.props.dispatchSetGlobalInfo(null);
      }, 200),
    );
  };

  getGlobalErrorMessage = error => {
    const { intl } = this.props;
    const { response, customErrorMessage, customErrorMessageIntl } = error;
    let message = intl.formatMessage(globalMessages.server_resource_not_found);
    if (error) {
      if (customErrorMessage) {
        return customErrorMessage;
      }
      if (customErrorMessageIntl) {
        return intl.formatMessage(customErrorMessageIntl);
      }
      if (!response) {
        console.error('error without response', error);
        return message;
      }
      if (
        (response.Errors && response.Errors.length) ||
        (response.errors && response.errors.length)
      ) {
        return this.processResponseErrors(error, response);
      }
      if (response.Message || response.message) {
        message = response.Message || response.message;
      }
      console.error('error with custom message', error, response);
      return intl.formatMessage(globalMessages.custom_error_occurred, {
        message,
      });
    }
    return '';
  };

  processResponseErrors = (error, response) => {
    const { intl, classes } = this.props;
    console.error('error with response', error, response);
    return (
      <div className={classes.globalErrorWrap}>
        <Typography
          component="p"
          variant="subtitleLight"
          gutterBottom
          className={classes.errorColor}
        >
          {response.message ||
            response.Message ||
            intl.formatMessage(globalMessages.custom_error_occurred, {
              message: '',
            })}
        </Typography>
        <div className={classes.globalErrorWrap}>
          {(response.Errors || response.errors).map(item => (
            <Typography key={item} component="div" className={classes.errorColor} gutterBottom>
              {item}
            </Typography>
          ))}
        </div>
      </div>
    );
  };

  getGlobalInfoMessage = info => {
    const { intl } = this.props;
    if (info) {
      if (info.response) {
        return intl.formatMessage(globalMessages.custom_request_not_processed, {
          message: info.response.Message || info.response.message,
        });
      }
      return info;
    }
    return '';
  };

  render() {
    const { error, open, info } = this.state;
    return (
      <CustomizedSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        variant={error ? 'error' : 'info'}
        verticalPosition="top"
        onClose={this.handleClose}
        open={
          (!!error && (error && error.status !== 401 && open)) ||
          (!!info && (info && info.status !== 401 && open))
        }
        autoHideDuration={8000}
        message={error ? this.getGlobalErrorMessage(error) : this.getGlobalInfoMessage(info)}
      />
    );
  }
}

ErrorHandler.propTypes = {
  classes: PropTypes.object,
  intl: intlShape.isRequired,
  dispatchSetGlobalError: PropTypes.func,
  dispatchSetGlobalInfo: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  error: selectGlobalError(),
  info: selectGlobalInfo(),
});

const mapDispatchToProps = dispatch => ({
  dispatchSetGlobalError: error => {
    dispatch(setGlobalError(error));
  },
  dispatchSetGlobalInfo: info => {
    dispatch(setGlobalInfo(info));
  },
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withReducer = injectReducer({ key: 'errorState', reducer });

export default compose(
  withReducer,
  withConnect,
  injectIntl,
  withStyles(styles),
)(ErrorHandler);
