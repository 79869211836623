import React from 'react';

import { IconButton, InputAdornment, TextField, Typography, alpha } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import Clear from 'components/SvgComponents/icons/Clear';
import { BLUE } from 'theme/colors';
import globalMessages from 'translations/messages/global-messages';

export const styles = theme => ({
  closeFocus: {
    position: 'absolute',
    display: 'none',
    padding: 0,
    backgroundColor: theme.palette.text.primary,
    right: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.dark,
    },
  },
  showClear: {
    display: 'flex',
  },
  disabled: {
    '&:hover': {
      border: `1px solid transparent !important`,
      '& $showOnlyOnFocus': {
        display: 'flex',
      },
    },
  },
  showOnlyOnFocus: {},
  inputCleanable: {
    color: theme.palette.text.primary,
    minWidth: 90,
    background: theme.palette.background.primary,
    borderRadius: 8,
    border: '1px transparent solid',
    '&:focus': {
      border: `1px ${theme.palette.text.dark} solid`,
      borderRadius: 8,
      display: 'flex',
    },
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      padding: '10px 16px 10px 16px',
      height: '18px',
    },
    '& textarea': {
      padding: '10px 16px 10px 16px',
      // 34px = 16px + 16px + 1px + 1px (border)
      width: 'calc(100% - 34px)',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.info.main}`,
      '& $showOnlyOnFocus': {
        display: 'flex',
      },
    },
  },

  textFieldFocus: {
    border: `1px solid ${theme.palette.info.main}`,
    '& $showOnlyOnFocus': {
      display: 'flex',
    },
    boxShadow: `0px 0px 0px 3px ${alpha(BLUE, 0.2)}`,
  },
  multiline: {
    padding: 0,
    paddingRight: 50,
    '& textarea': {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  icon: {
    color: theme.palette.text.primary,
    marginInlineStart: `${theme.spacing(3)}`,
  },
  iconDisabled: {
    color: `${theme.palette.text.primary}33`,
  },
  maxChar: {
    position: 'absolute',
    right: theme.spacing(8),
    bottom: 13,
  },
  error: {
    color: theme.palette.error.main,
  },
});

const CleanableTextField = ({
  classes,
  input,
  customClassName,
  intl,
  meta,
  multiline,
  maxChar,
  disabled,
  clearable,
  icon,
  clearableOnlyOnFocus,
  customValueOutput,
  fieldName,
  ...custom
}) => {
  const { touched, error } = meta;
  const handleChange = event => {
    input.onChange(event);
  };
  const displayMaxChar = multiline && maxChar;

  const handleClear = event => {
    event.stopPropagation();
    input.onChange('');
  };
  const value = customValueOutput ? customValueOutput(input.value) : input.value;
  const showClear = clearable && input.value;

  const InputProps = {};

  if (showClear) {
    InputProps.endAdornment = (
      <InputAdornment position="start">
        {displayMaxChar && (
          <Typography
            color="textSecondary"
            variant="caption"
            className={classNames(classes.maxChar, {
              [classes.error]: maxChar - input?.value?.length < 0,
            })}
          >
            {maxChar - input?.value?.length}
          </Typography>
        )}
        {!disabled && (
          <IconButton
            className={classNames({
              [classes.showClear]: !clearableOnlyOnFocus,
              [classes.showOnlyOnFocus]: clearableOnlyOnFocus,
              [classes.closeFocus]: true,
            })}
            onClick={handleClear}
          >
            <Clear
              aria-label={intl.formatMessage(globalMessages.delete)}
              role="img"
              title={intl.formatMessage(globalMessages.delete)}
              color="inherit"
              width="16"
              height="16"
            />
          </IconButton>
        )}
      </InputAdornment>
    );
  }

  return (
    <TextField
      className={classNames(classes.textField, customClassName)}
      variant="outlined"
      fullWidth
      disabled={disabled}
      multiline={multiline}
      onChange={handleChange}
      error={touched && !!error}
      helperText={touched && error}
      value={value}
      name={fieldName}
      {...custom}
      InputProps={{
        classes: {
          root: classNames(classes.inputCleanable, {
            [classes.disabled]: disabled,
          }),
          input: classes.textFieldInput,
          multiline: classes.multiline,
          focused: classes.textFieldFocus,
        },
        ...InputProps,
        ...custom?.InputProps,
      }}
    />
  );
};

CleanableTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  customClassName: PropTypes.string,
  multiline: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  maxChar: PropTypes.number,
  intl: intlShape.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  clearableOnlyOnFocus: PropTypes.bool,
  customValueOutput: PropTypes.func,
  fieldName: PropTypes.string,
};

CleanableTextField.defaultProps = {
  customClassName: '',
  clearable: false,
  clearableOnlyOnFocus: false,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(CleanableTextField);
