import React from 'react';

import { IconButton, Snackbar } from '@mui/material';
import amber from '@mui/material/colors/amber';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Close from 'components/SvgComponents/icons/Close';

const CustomizedSnackbarStyles = theme => ({
  '.MuiSnackbarContent-message': {
    width: '100%',
  },
  margin: {
    margin: theme.spacing(),
  },
  top: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: `0 ${theme.spacing(6)}`,
    borderRadius: `${theme.spacing(2)}`,
  },
  success: {
    '& .MuiPaper-root.MuiSnackbarContent-root': {
      padding: `0 ${theme.spacing(2)}`,
      backgroundColor: theme.palette.background.dark,
      color: theme.palette.text.tertiary,
    },
    '& .MuiSnackbarContent-message': {
      padding: 0,
      width: '100%',
    },
  },
  error: {
    '& .MuiPaper-root.MuiSnackbarContent-root': {
      padding: `0 ${theme.spacing(2)}`,
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.text.dark,
    },
  },
  info: {
    '& .MuiPaper-root.MuiSnackbarContent-root': {
      padding: `0 ${theme.spacing(2)}`,
      backgroundColor: theme.palette.info.main,
    },
  },
  warning: {
    '& .MuiPaper-root.MuiSnackbarContent-root': {
      padding: `0 ${theme.spacing(2)}`,
      backgroundColor: amber[700],
    },
  },
  icon: {
    fontSize: 16,
    padding: 0,
  },
  action: {
    paddingLeft: 0,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    wordWrap: 'break-word',
    padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
    fontWeight: 'bold',
    fontSize: '1rem',

    '&.left': {
      flexDirection: 'row-reverse',
    },
  },
});

function CustomizedSnackbar(props) {
  const {
    open,
    autoHideDuration,
    onClose,
    variant,
    message,
    verticalPosition,
    horizontalPosition,
    action,
    classes,
  } = props;
  return (
    <Snackbar
      className={classNames(classes[variant])}
      anchorOrigin={{
        vertical: verticalPosition || 'bottom',
        horizontal: horizontalPosition || 'center',
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={
        <div
          id="client-snackbar"
          className={classNames(classes.message, {
            left: horizontalPosition === 'left',
          })}
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            className={classes.icon}
          >
            <Close height="18" width="18" />
          </IconButton>
          {message}
        </div>
      }
      action={action}
    />
  );
}

CustomizedSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  autoHideDuration: PropTypes.number,
  verticalPosition: PropTypes.oneOf(['bottom', 'top']),
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  action: PropTypes.array,
};

export default withStyles(CustomizedSnackbarStyles)(CustomizedSnackbar);
