import React from 'react';

import { compose } from 'redux';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './saga';

function GlobalExport() {
  return <div />;
}

const withReducer = injectReducer({ key: 'globalExport', reducer });
const withSaga = injectSaga({ key: 'globalExport', saga });

export default compose(
  withReducer,
  withSaga,
)(GlobalExport);
