const Config = {
  apiRegistrationToken:
    '$2y$10$u1zVgUEarYkXfAVaILbg/O1xy.7oJJg5MnkMjpta3youaMO2LMvGq',
  apiHostGateway:
    'https://api.staging.finalcad.cloud',
  apiHostGatewayGlobal:
    'https://api.global.staging.finalcad.cloud',
  grpcGateway: 'https://realtime-service-pusher.staging.finalcad.cloud',
  pdfTronLicenseKey: 'DEMO',
  firebaseConfig: {
    apiKey: 'AIzaSyDQQs8qA71W7cD6jTkkr8xuMoBjwacpm6U',
    authDomain: 'finalcad-one-beta.firebaseapp.com',
    databaseURL: 'https://finalcad-one-beta.firebaseio.com',
    projectId: 'finalcad-one-beta',
    storageBucket: 'finalcad-one-beta.appspot.com',
    messagingSenderId: '896568218983',
    appId: '1:896568218983:web:c9e45707b38dfcdfe18bc2',
    measurementId: 'G-9NLYBJNC16',
  },
  stripeCheckoutKey: 'pk_test_51ILQ3mLfL2ThMUp8pTzJVg40sM7RsD7uiDDNlc0N5KVrP9VGD6PH4IYUFSpUue6OMlH6sS1TvXgtkOQVXVZJs8fw00zmqqylhD',
  okta: {
    oidc: {
      clientId: '0oasklaniP9MeU9fs0x6',
      issuer: 'https://preview.finalcad.com/oauth2/ausv056t9AKHmSUvk0x6',
      redirectUri: '$\{window.location.origin\}/login/callback',
      scopes: ['openid', 'profile', 'email'],
    },
    domain: 'https://preview.finalcad.com',
    idps: [
      { type: 'GOOGLE', id: '0oauz0nrt59Fr4uDU0x6' },
      { type: 'MICROSOFT', id: '0oauz0gl9OiziRx2C0x6' },
      { type: 'APPLE', id: '0oauk3dptMonqrNiT0x6' },
    ],
  },
  sentry: {
    environment: 'staging',
    dsn: 'https://cbde69b234184256ad295c3879334a5c@o1003935.ingest.sentry.io/5973713',
  },
  customerIo: {
    siteId: '5aa567bbd35b145b3166',
    apiKey: '4a0c7d3ec55e1047d6db',
  },
  gleap: {
    appToken: 'JLKq5vEGs1w9TKZPRl15SYQGJpYtTi1N',
  },
  googleMapsApiKey: 'AIzaSyAEmhLO21A_KSfPNbh6QMQMpfco7TOkluQ',
};
module.exports = Config;

