import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function Circle(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || props.intl.formatMessage(globalMessages.warning)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.5816 0 0 3.5816 0 8C0 12.4184 3.5816 16 8 16C12.4184 16 16 12.4184 16 8C16 3.5816 12.4184 0 8 0ZM8.4 12H7.6C7.3792 12 7.2 11.8208 7.2 11.6V10.8C7.2 10.5792 7.3792 10.4 7.6 10.4H8.4C8.6208 10.4 8.8 10.5792 8.8 10.8V11.6C8.8 11.8208 8.6208 12 8.4 12ZM8 8.8C7.5584 8.8 7.2 8.4416 7.2 8V4.8C7.2 4.3584 7.5584 4 8 4C8.4416 4 8.8 4.3584 8.8 4.8V8C8.8 8.4416 8.4416 8.8 8 8.8Z"
      />
    </svg>
  );
}
Circle.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Circle.defaultProps = {
  title: '',
};

export default injectIntl(Circle);
