import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import FileErrorPreviewPdf from '../Dropzone/FileErrorPreviewPdf';
import SecuredImage, { quality } from '../SecuredImage';
import PdfDocument from './PdfDocument';

/**
 * PdfPreview
 */
function PdfPreview({
  changeLoader,
  elementDimension,
  entityType,
  file,
  fullViewer,
  moduleId,
  onError = () => null,
  onPageLoadSuccess,
  overflow,
  pins = [],
  onPlacePin,
  renderPageNumber,
  statuses = [],
  tableItems,
  zonesData,
  addZoomButton,
  mouseWheelZoom,
  seeAnnotations,
  isFirstPageOnly,
  updatePin,
  fileType,
  plan,
  hasOfferLimitation,
  tools,
  document,
}) {
  const renderError = useCallback(
    () => {
      onError();
      return (
        <Box sx={{ textAlign: 'center' }}>
          <FileErrorPreviewPdf />
        </Box>
      );
    },
    [onError],
  );

  return (
    <SecuredImage
      forceRefresh
      componentSrc="file"
      withPlaceholder={false}
      imgQuality={quality.original}
    >
      <PdfDocument
        addZoomButton={addZoomButton}
        mouseWheelZoom={mouseWheelZoom}
        overflow={overflow}
        fullViewer={fullViewer}
        tableItems={tableItems}
        changeLoader={changeLoader}
        zonesData={zonesData}
        file={file}
        onError={renderError}
        scale={elementDimension?.scale}
        renderPageNumber={renderPageNumber}
        originalWidth={elementDimension?.originalWidth}
        originalHeight={elementDimension?.originalHeight}
        onPageLoadSuccess={onPageLoadSuccess}
        pins={pins}
        onPlacePin={onPlacePin}
        moduleId={moduleId}
        entityType={entityType}
        statuses={statuses}
        seeAnnotations={seeAnnotations}
        isFirstPageOnly={isFirstPageOnly}
        updatePin={updatePin}
        fileType={fileType}
        plan={plan}
        hasOfferLimitation={hasOfferLimitation}
        tools={tools}
        document={document}
      />
    </SecuredImage>
  );
}

PdfPreview.propTypes = {
  tableItems: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  file: PropTypes.string,
  addZoomButton: PropTypes.bool,
  mouseWheelZoom: PropTypes.bool,
  zonesData: PropTypes.object,
  elementDimension: PropTypes.object,
  renderPageNumber: PropTypes.number,
  onPageLoadSuccess: PropTypes.func,
  changeLoader: PropTypes.func,
  onError: PropTypes.func,
  overflow: PropTypes.bool,
  fullViewer: PropTypes.bool,
  pins: PropTypes.array,
  onPlacePin: PropTypes.func,
  moduleId: PropTypes.string,
  entityType: PropTypes.string,
  statuses: PropTypes.array,
  seeAnnotations: PropTypes.bool,
  isFirstPageOnly: PropTypes.bool,
  updatePin: PropTypes.func,
  fileType: PropTypes.string,
  plan: PropTypes.object,
  hasOfferLimitation: PropTypes.bool,
  document: PropTypes.object,
  tools: PropTypes.shape({
    annotationVisibilityOptionEnabled: PropTypes.bool,
    itemsVisibilityOptionEnabled: PropTypes.bool,
    annotationAreVisible: PropTypes.bool,
    itemsAreVisible: PropTypes.bool,
    toggleAnnotationVisibility: PropTypes.func,
    toggleItemsVisibility: PropTypes.func,
  }),
};

PdfPreview.defaultProps = {
  hasOfferLimitation: false,
  tools: {
    annotationVisibilityOptionEnabled: false,
    itemsVisibilityOptionEnabled: false,
    annotationAreVisible: false,
    itemsAreVisible: false,
    toggleAnnotationVisibility: () => null,
    toggleItemsVisibility: () => null,
  },
};

export default PdfPreview;
