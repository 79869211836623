import React from 'react';

import { Box, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import offerIllustration from 'assets/images/offerIllustration.svg';
import { Routing } from 'routing/routing';
import globalMessages from 'translations/messages/global-messages';

import Banner from './index';

const Banner_OfferContainer = styled(Banner)(() => ({
  width: '100%',
  maxWidth: '1000px',
  height: '84px',
  '& .Banner-root': {
    padding: 'none !important',
  },
}));

const Box_BannerOfferLayout = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: 'auto',
  alignItems: 'center',
}));

const Box_BannerOfferMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(4),
}));

const Img_BannerOfferIllustration = styled('img')(() => ({
  height: '100%',
}));

function BannerOffer({ title, description, history, intl }) {
  const { organizationId } = useParams();
  const onBannerOfferClicked = () => {
    history.push(Routing.organizations.contract(organizationId));
  };

  const defaultDescription =
    !title && !description ? intl.formatMessage(globalMessages.offers_banner_title) : null;

  return (
    <Box onClick={onBannerOfferClicked}>
      <Banner_OfferContainer noPadding variant="info">
        <Box_BannerOfferLayout>
          <Box_BannerOfferMessage>
            {title && (
              <Typography variant="subtitleLight" color="white">
                {title}
              </Typography>
            )}
            {description && (
              <Typography variant="subtitleBold" color="white">
                {description}
              </Typography>
            )}
            {defaultDescription && (
              <Typography variant="body" color="white">
                {defaultDescription}
              </Typography>
            )}
          </Box_BannerOfferMessage>
          <Img_BannerOfferIllustration src={offerIllustration} alt="Test" />
        </Box_BannerOfferLayout>
      </Banner_OfferContainer>
    </Box>
  );
}

BannerOffer.propTypes = {
  intl: intlShape.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  history: PropTypes.object,
};

export default compose(
  withRouter,
  injectIntl,
)(BannerOffer);
