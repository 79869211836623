import React, { useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import initAddMessageSaga from 'components/AddMessage/saga';
import HelpPlacePin from 'components/HelpPlacePin';
import { ProjectContext } from 'containers/GlobalWrapper/ProjectDataValidation';
import { selectModules } from 'containers/GlobalWrapper/selectors';
import {
  selectLimitsOfferForms,
  selectLimitsOfferObservation,
} from 'containers/LimitationMessageOffer/selectors';
import { getLimitOfferObservation } from 'containers/LimitationMessageOffer/utils/getLimitOfferObservation';
import { getLimitOfferForms } from 'containers/LimitationMessageOffer/utils/getLimitsOfferForms';
import AddFormButton from 'containers/OneProjectPage/FormsPage/components/AddFormButton';
import { getGroups } from 'containers/OneProjectPage/GroupsPage/actions';
import groupsPageReducer from 'containers/OneProjectPage/GroupsPage/reducer';
import initGroupsPageSaga from 'containers/OneProjectPage/GroupsPage/saga';
import { selectGroups } from 'containers/OneProjectPage/GroupsPage/selectors';
import AddObservation from 'containers/OneProjectPage/ObservationsPage/components/AddObservation';
import observationsPageReducer from 'containers/OneProjectPage/ObservationsPage/reducer';
import initObservationsPageSaga from 'containers/OneProjectPage/ObservationsPage/saga';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import { MODULE_TYPES, authorizations } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import pin from '../../PlanViewer/pin.svg';

export const AddItemButton = ({
  match,
  entityType,
  dispatchGetGroups,
  groups,
  moduleId,
  updatePin,
  isPinsVisible,
  limitsForms,
  showCustomPin,
  hideCustomPin,
  triggerClickToPlacePin,
  isViewerFull,
  onClickFromItem,
  isAnnotating,
}) => {
  const [isClickToPlaceMode, setIsClickToPlaceMode] = useState(!!onClickFromItem);
  const componentRef = useRef(null);
  const [isCreateItemDialogOpen, setIsCreateItemDialogOpen] = useState(false);
  const [position, setPosition] = useState();
  const limitsObservation = useSelector(selectLimitsOfferObservation());
  const featureFlags = useFeatureFlags();
  const modules = useSelector(selectModules());
  const limitsOfferForm = limitsForms ? getLimitOfferForms(limitsForms) : {};
  const [KeyDownListener, setKeyDownListener] = useState();
  const limitsOfferObservation = limitsObservation
    ? getLimitOfferObservation(limitsObservation)
    : {};

  const project = useContext(ProjectContext);

  const [canCreateObservation, setCanCreateObservation] = useState(
    project?.authorizations?.indexOf(authorizations.project.createObservation) !== -1 &&
      !limitsOfferObservation?.reachPlan,
  );
  const [canWriteLibrary, setCanWriteLibrary] = useState(
    project?.authorizations?.indexOf(authorizations.project.writeLibrary) !== -1,
  );

  const [canCreateForm, setCanCreateForm] = useState(
    project?.authorizations?.indexOf(authorizations.project.createForm) > -1 &&
      !limitsOfferForm?.reachPlan,
  );

  const canActivateLibrary =
    project?.authorizations?.indexOf(authorizations.project.writeSettings) !== -1;

  useEffect(
    () => {
      if (isClickToPlaceMode && triggerClickToPlacePin?.x && triggerClickToPlacePin?.y) {
        setPosition({
          x: triggerClickToPlacePin?.x,
          y: triggerClickToPlacePin?.y,
          planId: match.params.planId,
        });
        setIsCreateItemDialogOpen(true);
        setIsClickToPlaceMode(false);
        isPinsVisible(false);
      }
    },
    [triggerClickToPlacePin],
  );

  useEffect(
    () => {
      if (!project?.authorizations?.includes(authorizations.project.createObservation)) {
        dispatchGetGroups(match.params);
      }
    },
    [dispatchGetGroups, match.params, project],
  );

  useEffect(
    () => {
      if (
        !project?.authorizations?.includes(authorizations.project.createObservation) &&
        featureFlags?.item_creation_flow_for_guest
      ) {
        setCanCreateObservation(
          groups.some(group =>
            group?.authorizations?.includes(authorizations.project.createObservation),
          ),
        );
      }
    },
    [groups, project],
  );

  const handleHideCustomPin = () => {
    setIsCreateItemDialogOpen(false);
    setIsClickToPlaceMode(false);
    isPinsVisible(false);
    if (hideCustomPin) {
      hideCustomPin();
    }
  };

  useEffect(
    () => {
      if (isClickToPlaceMode) {
        if (!KeyDownListener) {
          window.addEventListener('keydown', e => {
            if (e.key === 'Escape') {
              handleHideCustomPin();
            }
          });
        }
        setKeyDownListener(true);
      }
    },
    [isClickToPlaceMode, isCreateItemDialogOpen],
  );

  const handleCreateItem = () => {
    if (!isClickToPlaceMode) {
      setIsClickToPlaceMode(true);
      if (showCustomPin) {
        showCustomPin();
      } else {
        isPinsVisible(true);
      }

      return true;
    }
    setIsClickToPlaceMode(false);
    isPinsVisible(false);
    return false;
  };

  const getPosition = e => ({
    x: e.nativeEvent.layerX / componentRef.current?.getBoundingClientRect().width,
    y: e.nativeEvent.layerY / componentRef.current?.getBoundingClientRect().height,
    planId: match.params.planId,
  });

  const handleOnclickToAddItem = e => {
    if (isClickToPlaceMode) {
      if (onClickFromItem) {
        onClickFromItem(getPosition(e));
      } else {
        setPosition(getPosition(e));
        setIsCreateItemDialogOpen(true);
        setIsClickToPlaceMode(false);
        isPinsVisible(false);
      }
    } else {
      setIsCreateItemDialogOpen(false);
    }
  };

  const handleItemCreated = () => {
    updatePin();
    setIsCreateItemDialogOpen(false);
    setIsClickToPlaceMode(false);
  };

  return (
    <>
      {!isViewerFull && (
        <div
          onClick={handleOnclickToAddItem}
          ref={componentRef}
          aria-hidden="true"
          style={{
            width: '100%',
            height: '100%',
            zIndex: 999,
            top: '0px',
            ...(isClickToPlaceMode ? { position: 'absolute' } : {}),
            ...(isClickToPlaceMode && !showCustomPin
              ? { cursor: `url("${pin}") 42 110, crosshair` }
              : {}),
          }}
        />
      )}
      {isClickToPlaceMode && !onClickFromItem && <HelpPlacePin />}
      <div
        style={{
          position: 'fixed',
          bottom: '0px',
          transform: 'translate(-50%, 0%)',
          zIndex: 999,
        }}
      >
        {entityType === MODULE_TYPES.observations && (
          <AddObservation
            canWriteLibrary={canWriteLibrary}
            canActivateLibrary={canActivateLibrary}
            canCreateObservation={canCreateObservation}
            disableAddButton={isClickToPlaceMode || isAnnotating}
            moduleId={moduleId}
            beforeOpenDialog={handleCreateItem}
            openDialog={isCreateItemDialogOpen}
            position={position}
            addButtonLabel={modules?.find(module => module?.id === moduleId)?.name}
            onObservationCreated={() => handleItemCreated()}
            disableRedirect
            onCloseDialog={handleHideCustomPin}
          />
        )}
        {entityType === MODULE_TYPES.forms && (
          <AddFormButton
            canCreateForm={canCreateForm}
            disableAddButton={isClickToPlaceMode || isAnnotating}
            moduleId={moduleId}
            beforeOpenDialog={handleCreateItem}
            openDialog={isCreateItemDialogOpen}
            position={position}
            addButtonLabel={modules?.find(module => module?.id === moduleId)?.name}
            disableRedirect
            onCloseDialog={handleHideCustomPin}
            onFormCreated={() => handleItemCreated()}
          />
        )}
      </div>
    </>
  );
};

AddItemButton.propTypes = {
  match: PropTypes.object,
  entityType: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.object),
  dispatchGetGroups: PropTypes.func.isRequired,
  moduleId: PropTypes.string,
  updatePin: PropTypes.func,
  isPinsVisible: PropTypes.func,
  limitsForms: PropTypes.object,
  showCustomPin: PropTypes.func,
  hideCustomPin: PropTypes.func,
  triggerClickToPlacePin: PropTypes.object,
  isViewerFull: PropTypes.bool,
  onClickFromItem: PropTypes.func,
  isAnnotating: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  groups: selectGroups(),
  limitsForms: selectLimitsOfferForms(),
});

const mapDispatchToProps = {
  dispatchGetGroups: getGroups,
};

const withSaga = injectSaga({ key: 'observationsPage', saga: initObservationsPageSaga });
const withReducer = injectReducer({ key: 'observationsPage', reducer: observationsPageReducer });
const withSagaGroups = injectSaga({ key: 'groupsSaga', saga: initGroupsPageSaga });
const withGroupsReducer = injectReducer({ key: 'groupsPage', reducer: groupsPageReducer });
const withMessageSaga = injectSaga({ key: 'addMessageSaga', saga: initAddMessageSaga });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withRouter,
  withConnect,
  withSaga,
  withReducer,
  withSagaGroups,
  withGroupsReducer,
  withMessageSaga,
)(AddItemButton);
