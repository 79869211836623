import React, { useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import finalcadLogoText from 'assets/images/logos/finalcad-logo-text.svg';
import globalMessages from 'translations/messages/global-messages';
import initialLoader from 'utils/initialLoader';

import { LoginCarousel } from './login-carousel';

const styles = theme => ({
  oktaLoginWrap: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    height: '100%',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '0 1fr',
    },
  },
  oktaLoginContent: {
    zIndex: 1,
    backgroundColor: theme.palette.background.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    position: 'relative',
    padding: theme.spacing(5),
    overflow: 'auto',
  },
  carousel: {
    backgroundColor: theme.palette.custom.login.background,
  },
  img: {
    height: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
  },
});

function OktaLoginWrap(props) {
  const { classes, children, intl } = props;

  useEffect(() => {
    initialLoader.hideLoader();
  });

  return (
    <div className={classes.oktaLoginWrap}>
      <div className={classes.carousel}>
        <LoginCarousel />
        <div className={classes.img}>
          <img
            src={finalcadLogoText}
            className={classes.img}
            alt={intl.formatMessage(globalMessages.finalcad_one_web_app)}
          />
        </div>
      </div>
      <div className={classes.oktaLoginContent}>{children}</div>
    </div>
  );
}

OktaLoginWrap.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  intl: intlShape.isRequired,
};

export default compose(
  withStyles(styles),
  withOktaAuth,
  injectIntl,
)(OktaLoginWrap);
