import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectInfiniteTableSelfStandingState = (namespace = 'table') => state =>
  state[namespace] || initialState;

const selectLoadingFlag = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), dataState =>
    dataState.get('loading'),
  );

const selectSearchText = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), dataState =>
    dataState.get('searchText'),
  );

const selectActiveFilters = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), infiniteTableState =>
    infiniteTableState.get('activeFilters').toJS(),
  );

const selectItems = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), infiniteTableState =>
    infiniteTableState.get('list'),
  );

const selectItem = (id, namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), infiniteTableState =>
    infiniteTableState.get('list').find(item => item.get('id') === id),
  );

const selectSearchCount = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), infiniteTableState =>
    infiniteTableState.get('count'),
  );
const selectUniqueKey = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), infiniteTableState =>
    infiniteTableState.get('key'),
  );

const selectItemsSortData = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), infiniteTableState =>
    infiniteTableState.get('sortData').toJS(),
  );

const selectCheckedItems = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), infiniteTableState =>
    infiniteTableState.get('checkedItems').toJS(),
  );

const selectIsEmptyTable = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), infiniteTableState => {
    const list = infiniteTableState.get('list');
    const loading = infiniteTableState.get('loading');
    const searchText = infiniteTableState.get('searchText');
    const filterActive = infiniteTableState.get('activeFilters').toJS();
    return list?.size === 0 && !searchText && !Object.keys(filterActive).length && !loading;
  });

const selectIsEmptySearchFilterTable = (namespace = 'table') =>
  createSelector(selectInfiniteTableSelfStandingState(namespace), infiniteTableState => {
    const list = infiniteTableState.get('list');
    const loading = infiniteTableState.get('loading');
    return list?.size === 0 && !loading;
  });

export {
  selectInfiniteTableSelfStandingState,
  selectLoadingFlag,
  selectItems,
  selectActiveFilters,
  selectSearchText,
  selectItemsSortData,
  selectCheckedItems,
  selectIsEmptyTable,
  selectIsEmptySearchFilterTable,
  selectSearchCount,
  selectUniqueKey,
  selectItem,
};
