import React from 'react';

import { caES, enUS, esES, frFR, itIT, jaJP, koKR, nlNL, ptBR, zhCN } from '@mui/x-date-pickers';
import moment from 'moment';

import DeSvg from 'components/SvgComponents/flags/DeSvg';
import EnSvg from 'components/SvgComponents/flags/EnSvg';
import EsSvg from 'components/SvgComponents/flags/EsSvg';
import FrSvg from 'components/SvgComponents/flags/FrSvg';
import JaSvg from 'components/SvgComponents/flags/JaSvg';
import ZhSvg from 'components/SvgComponents/flags/ZhSvg';
import {
  PRIORITY_BROWN,
  PRIORITY_DARK_BLUE,
  PRIORITY_DARK_GREEN,
  PRIORITY_LIGHT_BLUE,
  PRIORITY_LIGHT_GREEN,
  PRIORITY_ORANGE,
  PRIORITY_PINK,
  PRIORITY_PURPLE,
  PRIORITY_RED,
  PRIORITY_YELLOW,
} from 'theme/colors';
import globalMessages from 'translations/messages/global-messages';

// eslint-disable-next-line import/no-cycle
import localStorageUser from './localStorageUser';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const fileChunkSize = 5242880;
export const finalcadStorageEventKey = 'finalcadStorageEvent';
export const finalcadBusinessOrganisation = 'finalcadBusinessOrganisation';
export const finalcadStorageLanguageKey = 'finalcadStorageLanguageKey';
export const finalcadTradeLanguageKey = 'finalcadTradeLanguageKey';
export const idpUser = 'idpUser';
export const isSignUp = 'isSignUp';
export const finalcadTempRegistrationInfoTypeKey = 'finalcadTempRegistrationInfoTypeKey';
export const finalcadStorageRecentlyViewedPlans = 'finalcadStorageRecentlyViewedPlans';
export const finalcadStorageRecentlyViewedProjects = 'finalcadStorageRecentlyViewedProjects';
export const finalcadStorageRecentlyViewedOrganizations =
  'finalcadStorageRecentlyViewedOrganizations';
export const finalcadStorageDeepLink = 'finalcadStorageDeepLink';
export const finalcadStorageInviteLink = 'finalcadStorageInviteLink';
export const finalcadStorageEmailVerificationLink = 'finalcadStorageEmailVerificationLink';
export const finalcadStorageEmailValidationLocation = 'finalcadStorageEmailValidationLocation';
export const finalcadUserStore = 'finalcadUserStore';
export const oktaTokenStorage = 'okta-token-storage';
export const oktaCacheStorage = 'okta-cache-storage';
export const firebaseFeatureStorage = 'firebaseFeatureStorage';
export const finalcadLastVisitedPage = 'finalcadLastVisitedPage';
export const finalcadCurrentOffer = 'finalcadCurrentOffer';
export const finalcadGroupsStorage = 'finalcadGroupsStorage';
export const finalcadThemePreference = 'finalcadThemePreference';
export const finalcadSavedProjectFilters = 'finalcadSavedProjectFilters';
export const finalcadProjectsListFilters = 'finalcadProjectsListFilters';
export const LIBRARY_UPDATE_AKNOWLEDGEMENT = 'libraryUpdateAknowledgement';
export const finalcadReportCustomization = 'finalcadReportCustomization';
export const finalcadForwardRedirect = 'finalcadForwardRedirect';

export const pathFallback = [
  ['/organizations/:', '/organization/:'],
  ['/workspaces/:', '/workspace/:'],
  ['/templates/:', '/template/:'],
  ['/projects/:', '/project/:'],
];

export const appPaths = {
  projectPage:
    '/organization/:organizationId/project/:idData/:pageName/:subPageName?/:itemId?/:subSubPageName?/:subItemId?',
  organizationProjectList: '/organization/:organizationId/projects',
};

export const formAnswerfieldsName = [
  'value_boolean',
  'value_string',
  'lock_sections',
  'value_date1',
  'value_time1',
  'value_numeric',
  'array_field_choices',
  'value_media_resources',
];

export const typeFieldDataConst = {
  shortAnswer: 'ShortAnswer',
  paragraph: 'Paragraph',
  singleChoice: 'SingleChoice',
  multipleChoice: 'MultipleChoice',
  signature: 'Signature',
  control: 'Control',
  formLink: 'FormLink',
  email: 'Email',
  phoneNumber: 'PhoneNumber',
  formLinkGrid: 'Grid',
  disclaimer: 'Disclaimer',
  attachment: 'Attachments',
  button: 'Button',
  formula: 'Formula',
};
export const typeFieldDataShortAnswer = {
  number: 'Number',
  calendar: 'Calendar',
  text: 'Text',
};
export const typeConst = {
  section: 'Section',
  part: 'Part',
  text: 'Text',
  field: 'Field',
  question: 'Question',
  number: 'Number',
  calendar: 'Calendar',
  library: 'Library',
  resources: 'Resources',
  group: 'Group',
};

export const colorTheme = {
  light: 'light',
  dark: 'dark',
};

export const formVisibility = {
  Default: 'default',
  Available: 'available',
  NotAvailable: 'not_available',
};

export const superAdminTabs = {
  active: 'Active',
  deleted: 'Deleted',
};

export const formsModuleActions = {
  link: 'Link',
  unlink: 'Unlink',
  trash: 'Trash',
  renameModule: 'RenameModule',
  renameCategory: 'RenameCategory',
  createFolder: 'CreateFolder',
};

export const moduleType = {
  module: 'Module',
  category: 'Category',
};

export const controlPresentationControlConst = ['WithValue', 'WithCheck', 'WithEmoji', 'WithScale'];

export const controlPresentationSingleChoiceConst = ['RadioButton', 'DropDown'];

export const inputTextCaseSensTypes = [
  'ConvertInUppercase',
  'ConvertInLowercase',
  'InitCapWholeText',
  'InitCapEachWord',
];

export const getInputTextCaseSensTypesTranslation = (intl, code) => {
  switch (code) {
    case inputTextCaseSensTypes[0]:
      return intl.formatMessage(globalMessages.to_uppercase);
    case inputTextCaseSensTypes[1]:
      return intl.formatMessage(globalMessages.to_lowercase);
    case inputTextCaseSensTypes[2]:
      return intl.formatMessage(globalMessages.capitalize_sentence);
    case inputTextCaseSensTypes[3]:
      return intl.formatMessage(globalMessages.capitalize_each_word);
    default:
      return code;
  }
};

export const calendarFormat = ['DateOnly', 'DateAndTime', 'TimeOnly'];

export const getCalendarFormatTranslation = (intl, code) => {
  switch (code) {
    case calendarFormat[0]:
      return intl.formatMessage(globalMessages.forms_calendar_date_only);
    case calendarFormat[1]:
      return intl.formatMessage(globalMessages.forms_calendar_date_and_time);
    case calendarFormat[2]:
      return intl.formatMessage(globalMessages.forms_calendar_hour_only);
    default:
      return code;
  }
};

export const calendarDefaultValue = [
  'Today',
  'FirstDayOfWeek',
  'FirstDayOfMonth',
  'FirstDayOfYear',
  'LastDayOfWeek',
  'LastDayOfMonth',
  'LastDayOfYear',
  // 'StartDateOfContruction',
];

export const getCalendarDefaultValueTranslation = (intl, code) => {
  switch (code) {
    case calendarDefaultValue[0]:
      return intl.formatMessage(globalMessages.today);
    case calendarDefaultValue[1]:
      return intl.formatMessage(globalMessages.forms_calendar_first_day_of_week);
    case calendarDefaultValue[2]:
      return intl.formatMessage(globalMessages.forms_calendar_first_day_of_month);
    case calendarDefaultValue[3]:
      return intl.formatMessage(globalMessages.forms_calendar_first_day_of_year);
    case calendarDefaultValue[4]:
      return intl.formatMessage(globalMessages.forms_calendar_last_day_of_week);
    case calendarDefaultValue[5]:
      return intl.formatMessage(globalMessages.forms_calendar_last_day_of_month);
    case calendarDefaultValue[6]:
      return intl.formatMessage(globalMessages.forms_calendar_last_day_of_year);
    default:
      return code;
  }
};

export const controlFormatArrayConst = ['KoOk', 'KoNeutralOk' /* , 'ControlScale' */];

export const apiFetchItemsCount = 24; // do not change, this is calculated by table height
export const apiFetchPreloadItemsCount = 10; // do not change, this is calculated by table height
export const apiFetchPaginatedTable = 1000;
export const nrOfConcurentMediaRequests = 2;

export const getMembersDefaultParams = {
  limit: 100,
  largeLimitForSearch: 1000,
  offset: 0,
};

export const cronConfigurationSelectType = {
  DAYS: 3,
  WEEK: 4,
  MONTH: 5,
};

export const filterEntityType = {
  form: 'Form',
  observation: 'Observation',
};

export const possibleLanguages = [
  'ca',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'nl',
  'pt',
  'th',
  'vi',
  'zh',
  'zf',
  'pl',
  'cs',
  'sv',
  'da',
  'no',
  'ru',
  'fi',
  'he',
  'hu',
  'sl',
];

export const possibleProjectStatus = ['InProgress', 'Done'];

export const projectStatus = {
  inProgress: 'InProgress',
  done: 'Done',
};

export const AttendanceOptions = [
  { id: 'Present', translation: 'meetings_attendees_state_attending' },
  { id: 'Excused', translation: 'meetings_attendees_state_excused' },
  { id: 'Late', translation: 'meetings_attendees_state_late' },
  { id: 'Absent', translation: 'meetings_attendees_state_missing' },
  { id: 'NotConcerned', translation: 'meetings_attendees_state_not_concerned' },
];

export const tempTemplateLanguages = [
  {
    code: 'ca',
    label: '',
    value: 'Catalan',
  },
  {
    code: 'de',
    label: <DeSvg />,
    value: 'German',
  },
  {
    code: 'en',
    label: <EnSvg />,
    value: 'English',
  },
  {
    code: 'es',
    label: <EsSvg />,
    value: 'Spanish',
  },
  {
    code: 'fr',
    label: <FrSvg />,
    value: 'French',
  },
  {
    code: 'it',
    label: '',
    value: 'Italian',
  },
  {
    code: 'ja',
    label: <JaSvg />,
    value: 'Japanese',
  },
  {
    code: 'ko',
    label: '',
    value: 'Korean',
  },
  {
    code: 'nl',
    label: '',
    value: 'Netherland',
  },
  {
    code: 'pt',
    label: '',
    value: 'Portuguese',
  },
  {
    code: 'th',
    label: '',
    value: 'Thai',
  },
  {
    code: 'vi',
    label: '',
    value: 'Vietnamese',
  },
  {
    code: 'zh',
    label: <ZhSvg />,
    value: 'Chinese',
  },
  {
    code: 'zf',
    label: '',
    value: 'Chinese Traditional',
  },
  {
    code: 'pl',
    label: '',
    value: 'Polish',
  },
  {
    code: 'cs',
    label: '',
    value: 'Czech',
  },
  {
    code: 'sv',
    label: '',
    value: 'Swedish',
  },
  {
    code: 'da',
    label: '',
    value: 'Danish',
  },
  {
    code: 'no',
    label: '',
    value: 'Norwegian',
  },
  {
    code: 'ru',
    label: '',
    value: 'Russian',
  },
  {
    code: 'fi',
    label: '',
    value: 'Finnish',
  },
  {
    code: 'he',
    label: '',
    value: 'Hebrew',
  },
  {
    code: 'hu',
    label: '',
    value: 'Hungarian',
  },
  {
    code: 'sl',
    label: '',
    value: 'Slovenian',
  },
];

export const getTempTemplateLanguagesDisplay = code => {
  switch (code) {
    case 'ca':
      return 'Català';
    case 'de':
      return 'Deutsch';
    case 'en':
      return 'English';
    case 'es':
      return 'Español';
    case 'fr':
      return 'Français';
    case 'it':
      return 'Italiano';
    case 'ja':
      return '日本語';
    case 'ko':
      return '코렌';
    case 'nl':
      return 'Nederlands';
    case 'pt':
      return 'Português';
    case 'th':
      return 'ไทย';
    case 'vi':
      return 'Tiếng Việt';
    case 'zh':
      return '中文';
    case 'zf':
      return '中文繁體';
    case 'pl':
      return 'Polski';
    case 'cs':
      return 'Čeština';
    case 'sv':
      return 'Svenska';
    case 'da':
      return 'Dansk';
    case 'no':
      return 'Norsk';
    case 'ru':
      return 'Русский';
    case 'fi':
      return 'Suomi';
    case 'he':
      return 'עברית';
    case 'hu':
      return 'Magyar';
    case 'sl':
      return 'Slovenščina';
    default:
      return code;
  }
};

export const getLocalizationFromLanguage = lang => {
  switch (lang) {
    case 'ca': // Catalan
      return caES.components.MuiLocalizationProvider.defaultProps.localeText;
    case 'de': // Deutsch
      return nlNL.components.MuiLocalizationProvider.defaultProps.localeText;
    case 'es': // Spanish
      return esES.components.MuiLocalizationProvider.defaultProps.localeText;
    case 'fr': // Français
      return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    case 'it': // Italian
      return itIT.components.MuiLocalizationProvider.defaultProps.localeText;
    case 'ja': // Japanese
      return jaJP.components.MuiLocalizationProvider.defaultProps.localeText;
    case 'ko': // Korean
      return koKR.components.MuiLocalizationProvider.defaultProps.localeText;
    case 'pt': // Portuguese
      return ptBR.components.MuiLocalizationProvider.defaultProps.localeText;
    case 'zh': // Chinese
      return zhCN.components.MuiLocalizationProvider.defaultProps.localeText;
    default:
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  }
};

export const adminDefaultSettingsPages = {
  rights: '/admin/defaults/rights',
  category: '/admin/defaults/category',
  processes: '/admin/defaults/processes',
  groups: '/admin/defaults/groups',
  status: '/admin/defaults/status',
  priority: '/admin/defaults/priority',
};

export const oneProjectSubPages = {
  companies: 'companies',
  observation: 'observation',
  form: 'form',
  trade: 'trade',
  folder: 'folder',
  file: 'file',
  project: 'project',
  observations: 'observations',
  zoning: 'zoning',
  module: 'module',
  categories: 'categories',
  phases: 'phases',
  meeting: 'meeting',
  settings: 'settings',
  analytics: 'analytics',
  dashboard: 'dashboard',
};

export const oneProjectPages = {
  home: 'home',
  groups: 'groups',
  plans: 'plans',
  documents: 'documents',
  observations: 'observations',
  forms: 'forms',
  meetings: 'meetings',
  formsSettings: 'forms-settings',
  organizations: 'organizations',
  members: 'members',
  export: 'export',
  settings: 'settings',
  companies: 'companies',
  phases: 'phases',
  statuses: 'statuses',
  dashboard: 'dashboard',
};

export const oneHomePages = {
  welcome: 'welcome',
  projects: 'projects',
  templates: 'templates',
  members: 'members',
  contracts: 'contracts',
  workspaces: 'workspaces',
};

export const templatesPages = {
  home: '/templates',
  forms: '/templates/forms',
  status: '/templates/status',
  priority: '/templates/priority',
  observations: '/templates/common-observations',
};

export const organizationsPages = {
  info: 'info',
  dashboard: 'dashboard',
  templates: 'templates',
  members: 'members',
  contracts: 'contracts',
  workspaces: 'workspaces',
};

export const organizationsTemplatesPages = {
  forms: 'forms',
  status: 'status',
  priority: 'priority',
  observations: 'common-observations',
  modules: 'modules',
  referentials: 'referentials',
};

export const MODULE_TYPES = {
  forms: 'Forms',
  observations: 'Observations',
  meetings: 'Meetings',
};

export const locationType = {
  plan: 'plan',
  folder: 'folder',
  bim: 'bim',
};

export const GED = 'Ged';

export const LOCATION_TYPES = locationType;

export const qrCodeFormats = {
  position: 'Position',
  key: 'KeyValue',
};

export const sortDirectionConst = {
  ASC: 'Ascending',
  DESC: 'Descending',
};

export const superAdminPages = {
  manageOrganizations: '/super-admin/manage-organizations',
  library: '/super-admin/library/priority',
  priority: '/super-admin/library/priority',
  status: '/super-admin/library/status',
  forms: '/super-admin/library/forms',
  tools: '/super-admin/tools',
  users: '/super-admin/users',
  organizations: '/super-admin/organisations',
  projects: '/super-admin/projects',
  formsTemplateLibrary: '/super-admin/library/forms',
  observations: '/super-admin/library/common-observations',
};

export const versionsState = {
  archived: 'Archived',
  publish: 'Published',
  draft: 'Draft',
  unarchive: 'unarchive',
  delete: 'delete',
  archiveProcess: 'archive',
  manuallyArchived: 'ManuallyArchived',
};

export const userStatus = {
  activated: 'Activated',
  pending: 'Pending',
  processing: 'Processing',
  deactivated: 'Deactivated',
};

export const layoutBreakPoints = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const typeIconSuperAdminLibrary = {
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
  circle: 'RoundExclamationMark',
  losange: 'DiamondExclamationMark',
  triangle: 'TriangleExclamationMark',
  one: 'RoundOne',
  two: 'RoundTwo',
  three: 'RoundThree',
  four: 'RoundFour',
  five: 'RoundFive',
};
export const iconSuperAdmin = [
  {
    name: 'ArrowUp',
    id: 1,
  },
  {
    name: 'ArrowDown',
    id: 2,
  },
  {
    name: 'RoundExclamationMark',
    id: 3,
  },
  {
    name: 'DiamondExclamationMark',
    id: 10,
  },
  {
    name: 'TriangleExclamationMark',
    id: 4,
  },
  {
    name: 'RoundOne',
    id: 5,
  },
  {
    name: 'RoundTwo',
    id: 6,
  },
  {
    name: 'RoundThree',
    id: 7,
  },
  {
    name: 'RoundFour',
    id: 8,
  },
  {
    name: 'RoundFive',
    id: 9,
  },
];

export const typeSuperAdminLibrary = {
  process: 'Process',
  forms: 'Forms',
  observation: 'Observation',
  formStatusSuperAdmin: 'superAdminStatus',
};

export const typeIconSuperAdminLibraryOne = {
  emojiSad: 'EmojiSad',
  emojiNeutral: 'EmojiNeutral',
  emojiHappy: 'EmojiHappy',
  chevronUp: 'ChevronUp',
  chevronsUp: 'ChevronsUp',
  chevronDown: 'ChevronDown',
  chevronsDown: 'ChevronsDown',
  minusCircle: 'MinusCircle',
  alertTriangle: 'AlertTriangle',
  minus: 'Minus',
  xoctagon: 'XOctagon',
  alertOnly: 'AlertOnly',
};
export const iconSuperAdminOne = [
  {
    name: typeIconSuperAdminLibraryOne.emojiSad,
    id: 1,
  },
  {
    name: typeIconSuperAdminLibraryOne.emojiNeutral,
    id: 2,
  },
  {
    name: typeIconSuperAdminLibraryOne.emojiHappy,
    id: 3,
  },
  {
    name: typeIconSuperAdminLibraryOne.chevronUp,
    id: 4,
  },
  {
    name: typeIconSuperAdminLibraryOne.chevronsUp,
    id: 5,
  },
  {
    name: typeIconSuperAdminLibraryOne.chevronDown,
    id: 6,
  },
  {
    name: typeIconSuperAdminLibraryOne.chevronsDown,
    id: 7,
  },
  {
    name: typeIconSuperAdminLibraryOne.minusCircle,
    id: 8,
  },
  {
    name: typeIconSuperAdminLibraryOne.alertTriangle,
    id: 9,
  },
  {
    name: typeIconSuperAdminLibraryOne.minus,
    id: 10,
  },
  {
    name: typeIconSuperAdminLibraryOne.xoctagon,
    id: 11,
  },
  {
    name: typeIconSuperAdminLibraryOne.alertOnly,
    id: 12,
  },
];

export const prioritiesColors = () => [
  {
    name: 'brown',
    hexa: PRIORITY_BROWN,
  },
  {
    name: 'red',
    hexa: PRIORITY_RED,
  },
  {
    name: 'orange',
    hexa: PRIORITY_ORANGE,
  },
  {
    name: 'yellow',
    hexa: PRIORITY_YELLOW,
  },
  {
    name: 'dark_green',
    hexa: PRIORITY_DARK_GREEN,
  },
  {
    name: 'light_green',
    hexa: PRIORITY_LIGHT_GREEN,
  },
  {
    name: 'dark_blue',
    hexa: PRIORITY_DARK_BLUE,
  },
  {
    name: 'light_blue',
    hexa: PRIORITY_LIGHT_BLUE,
  },
  {
    name: 'purple',
    hexa: PRIORITY_PURPLE,
  },
  {
    name: 'pink',
    hexa: PRIORITY_PINK,
  },
];

export const operators = ['+', '-', '*', '/', '(', ')'];
export const digits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];

export const authorizations = {
  organization: {
    createProject: 'create-project',
    read: 'read',
    write: 'write',
    handleMembers: 'handle-members',
    handleWorkspaces: 'handle-workspaces',
    handleWorkspacesLimited: 'handle-workspaces-limited',
    handleOrganizationLibrary: 'handle-organization-library',
    handleWorkspaceLibrary: 'handle-workspace-library',
    readFormLibrary: 'read-form-library',
    readPlugin: 'read-plugin',
    writeFormLibrary: 'write-form-library',
    writeObservationLibrary: 'write-observation-library',
    writeObservationLibraryLimited: 'write-observation-library-limited',
    writeOffer: 'write-offer',
    seeDomains: 'see-domains',
    handleDomains: 'handle-domains',
    readMembers: 'read-members',
    readSettings: 'read-settings',
    delete: 'delete',
  },
  project: {
    addUserAllRoles: 'add-user-all-roles',
    addUserSameRole: 'add-user-same-role',
    changeUserRole: 'change-user-role',
    createFolder: 'create-folder',
    createGroup: 'create-group',
    createObservation: 'create-observation',
    createPlan: 'create-plan',
    createReport: 'create-report',
    createForm: 'create-form',
    createDocument: 'create-document',
    read: 'read',
    delete: 'delete',
    readSettings: 'read-settings',
    removeUser: 'remove-user',
    writeFormTemplate: 'write-form-template',
    writeLibrary: 'write-library',
    writeSettings: 'write-settings',
    writeMeeting: 'write-meeting',
    addUserInCompany: 'add-user-in-company',
    handleModules: 'handle-modules',
    massImport: 'mass-import',
  },
  observation: {
    writeStatus: 'write-status',
    writeContribute: 'write-contribute',
    write: 'write',
    delete: 'delete',
  },
  document: {
    write: 'write',
    read: 'read',
    delete: 'delete',
  },
  form: {
    writeContribute: 'write-contribute',
    writeStatus: 'write-status',
    write: 'write',
    writeAnswers: 'write-answers',
    delete: 'delete',
  },
  specialUser: {
    readObservationFcLibrary: 'read-observation-fc-library',
    writeObservationFcLibrary: 'write-observation-fc-library',
    readFormFcLibrary: 'read-form-fc-library',
    writeFormFcLibrary: 'write-form-fc-library',
    readAdminPortal: 'read-admin-portal',
  },
  module: {
    writeStatus: 'write-status',
    writeContribute: 'write-contribute',
    write: 'write',
    delete: 'delete',
  },
};

export const limitationOffer = {
  premium: 'premium',
  free: 'free',
  isLimit: 'isLimit',
  observation: 'observation',
  form: 'form',
  home: 'home',
};
export const planType = {
  premium: 'Premium',
  free: 'Free',
  business: 'Business',
  enterprise: 'Enterprise',
};
export const subscriptionPeriodicity = {
  month: 'month',
  year: 'year',
};
export const subscriptionOnMobileType = {
  AppleStore: 'AppleStore',
  GooglePlay: 'GooglePlay',
};
export const typeIconCurrency = {
  eur: 'eur',
  gbp: 'gbp',
  chf: 'chf',
  usd: 'usd',
  sgd: 'sgd',
  jpy: 'jpy',
};

export const limitationEntity = {
  form: 'Form',
  observation: 'Observation',
  member: 'Member',
};

export const subscriptionStatus = {
  active: 'Active',
  canceled: 'Canceled',
  interrupted: 'Interrupted',
};

export const documentType = {
  document: 'Document',
  folder: 'Folder',
};

export const fileType = {
  image: 'Image',
  pdf: 'Pdf',
  video: 'Video',
  audio: 'Audio',
  spreadsheet: 'Spreadsheet',
  text: 'Text',
  presentation: 'Presentation',
  other: 'Other',
  dwg: 'Dwg',
  excel: 'Excel',
  log: 'Log',
  qrcode: 'QrCode',
};

export const errorPlanTypes = {
  cantSeePlan: 'cantSeePlan',
};

export const reportEntityType = {
  form: 'form',
  observation: 'observation',
};

export const scheduleType = {
  daily: 'Daily',
  weekly: 'Weekly',
};

export const messageTypes = {
  form: 'Form',
  observation: 'Observation',
  document: 'Document',
};

export const keyboard = {
  enter: '13',
};

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const returnLocaleDays = () => {
  const getLanguageUserFromLocalStorage = () => {
    const language = localStorage.getItem(finalcadStorageLanguageKey);
    if (language !== null) {
      if (possibleLanguages.includes(language.substr(0, 2))) return language.substr(0, 2);
    } else {
      const formattedNavigatorLanguage = navigator.language.split('-')[0];
      if (possibleLanguages.includes(formattedNavigatorLanguage)) {
        return formattedNavigatorLanguage;
      }
    }
    return 'en';
  };

  moment.locale(getLanguageUserFromLocalStorage());
  const isFirstDayMonday =
    moment()
      .weekday(0)
      .day() !==
    moment()
      .isoWeekday(0)
      .day();
  const daysIndexes = isFirstDayMonday ? [0, 1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5, 6, 0];

  const weekDaysUnsorted = [
    {
      id: daysIndexes[0],
      value: 'Monday',
      label: capitalizeFirstLetter(
        moment()
          .weekday(daysIndexes[0])
          .format('dddd'),
      ),
    },
    {
      id: daysIndexes[1],
      value: 'Tuesday',
      label: capitalizeFirstLetter(
        moment()
          .weekday(daysIndexes[1])
          .format('dddd'),
      ),
    },
    {
      id: daysIndexes[2],
      value: 'Wednesday',
      label: capitalizeFirstLetter(
        moment()
          .weekday(daysIndexes[2])
          .format('dddd'),
      ),
    },
    {
      id: daysIndexes[3],
      value: 'Thursday',
      label: capitalizeFirstLetter(
        moment()
          .weekday(daysIndexes[3])
          .format('dddd'),
      ),
    },
    {
      id: daysIndexes[4],
      value: 'Friday',
      label: capitalizeFirstLetter(
        moment()
          .weekday(daysIndexes[4])
          .format('dddd'),
      ),
    },
    {
      id: daysIndexes[5],
      value: 'Saturday',
      label: capitalizeFirstLetter(
        moment()
          .weekday(daysIndexes[5])
          .format('dddd'),
      ),
    },
    {
      id: daysIndexes[6],
      value: 'Sunday',
      label: capitalizeFirstLetter(
        moment()
          .weekday(daysIndexes[6])
          .format('dddd'),
      ),
    },
  ];

  return weekDaysUnsorted.sort((a, b) => a.id - b.id);
};

const constructHours = () => {
  const items = [];
  new Array(24).fill().forEach((acc, index) => {
    items.push({
      id: index,
      value: index,
      label: moment({ hour: index }).format('ha'),
    });
  });
  return items;
};

export const isGed = item => item?.source === GED || item?.sources_contained?.includes(GED);

function is12HourFormat() {
  const locale_language = localStorageUser.getLanguageUserFromLocalStorage();
  moment.locale(locale_language);
  const formatString = moment.localeData().longDateFormat('LT');
  return formatString.indexOf('a') !== -1 || formatString.indexOf('A') !== -1;
}

export const getHoursInCurrentLocale = () => {
  const format = is12HourFormat() ? 'hA' : 'H[h]';
  const items = [];
  new Array(24).fill().forEach((acc, index) => {
    items.push({
      id: index,
      value: index,
      label: moment({ hour: index }).format(format),
    });
  });
  return items;
};

export const dayHours = constructHours();

export const featureFlagsScope = {
  module: 'module',
  linkItem: 'linkItem',
};

export const getFormsApiVersion = (endpoint, mentions) => {
  const storageFlags = localStorageUser.getFirebaseFeatureStorage();
  if (mentions?.length > 0) {
    return 'v2.0';
  }
  let version;
  switch (endpoint) {
    case featureFlagsScope.linkItem: {
      version = storageFlags?.forms_link_items_field ? 'v2.1' : 'v2.0';
      break;
    }
    default:
      version = 'v2.4';
      break;
  }
  return version;
};

export const getFormsLibraryApiVersion = () => {
  const storageFlags = localStorageUser.getFirebaseFeatureStorage();
  return storageFlags?.templates_module_management ? 'v1.1' : 'v1.0';
};

export const getObservationsApiVersion = mentions => {
  if (mentions?.length > 0) {
    return 'v1.3';
  }
  const storageFlags = localStorageUser.getFirebaseFeatureStorage();
  return storageFlags?.forms_link_items_field ? 'v1.4' : 'v1.3';
};

export const getOrganizationMembersApiVersion = () => 'v1.2';

export const getGroupsApiVersion = () => 'v1.3';

export const getProjectCompaniesFilter = () => 'v2.5';

export const API_DOCUMENTS_VERSION = 'v1.2';

export const cardDetailsParent = {
  message: 'message',
  meeting: 'meeting',
  formBuilder: 'formBuilder',
};

export const stripeStatus = {
  open: 'open',
  complete: 'complete',
  expired: 'expired',
};

export const userType = {
  admin: 'Admin',
};

export const PROJECT_USER_ROLE = {
  admin: 'Admin',
  editor: 'Editor',
  limitedEditor: 'Limited editor',
  guest: 'Guest',
};

export const getDocumentTypeTranslation = (intl, name) => {
  switch (name) {
    case documentType.folder:
      return intl.formatMessage(globalMessages.folder);
    case fileType.image:
      return intl.formatMessage(globalMessages.image);
    case fileType.pdf:
      return intl.formatMessage(globalMessages.pdf);
    case fileType.video:
      return intl.formatMessage(globalMessages.video);
    case fileType.audio:
      return intl.formatMessage(globalMessages.audio);
    case fileType.spreadsheet:
      return intl.formatMessage(globalMessages.spreadsheet);
    case fileType.text:
      return intl.formatMessage(globalMessages.text);
    case fileType.presentation:
      return intl.formatMessage(globalMessages.presentation);
    case fileType.other:
      return intl.formatMessage(globalMessages.other);
    case fileType.dwg:
      return intl.formatMessage(globalMessages.dwg);
    case fileType.excel:
      return intl.formatMessage(globalMessages.excel);
    case fileType.log:
      return intl.formatMessage(globalMessages.log);
    case fileType.qrcode:
      return intl.formatMessage(globalMessages.qrcode);
    default:
      return name;
  }
};

export const textEditor = {
  defaultTagParagraph: '<p class="editor-paragraph"><br></p>',
};

export const dateFormat = 'DD MMM YYYY';
