import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

function Dwg(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{props.title || 'pdf'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.58554 1.25247C2.96061 0.8774 3.46932 0.666687 3.99976 0.666687H8.66642C8.84323 0.666687 9.0128 0.736925 9.13783 0.861949L13.8045 5.52862C13.9295 5.65364 13.9998 5.82321 13.9998 6.00002V13.3334C13.9998 13.8638 13.789 14.3725 13.414 14.7476C13.0389 15.1226 12.5302 15.3334 11.9998 15.3334H3.99976C3.46932 15.3334 2.96061 15.1226 2.58554 14.7476C2.21047 14.3725 1.99976 13.8638 1.99976 13.3334V2.66669C1.99976 2.13626 2.21047 1.62755 2.58554 1.25247ZM3.99976 2.00002C3.82294 2.00002 3.65337 2.07026 3.52835 2.19528C3.40333 2.32031 3.33309 2.48987 3.33309 2.66669V13.3334C3.33309 13.5102 3.40333 13.6797 3.52835 13.8048C3.65337 13.9298 3.82294 14 3.99976 14H11.9998C12.1766 14 12.3461 13.9298 12.4712 13.8048C12.5962 13.6797 12.6664 13.5102 12.6664 13.3334V6.66669H8.66642C8.29823 6.66669 7.99976 6.36821 7.99976 6.00002V2.00002H3.99976ZM9.33309 2.94283L11.7236 5.33335H9.33309V2.94283Z"
      />
      <path d="M5.01803 10.6655H5.86853C6.01319 10.6655 6.13686 10.6865 6.23953 10.7285C6.34219 10.7705 6.42619 10.8265 6.49153 10.8965C6.55919 10.9642 6.60819 11.0435 6.63853 11.1345C6.67119 11.2255 6.68753 11.32 6.68753 11.418C6.68753 11.544 6.66536 11.6548 6.62103 11.7505C6.57903 11.8438 6.52069 11.922 6.44603 11.985C6.37369 12.048 6.28969 12.0958 6.19403 12.1285C6.09836 12.1612 5.99803 12.1775 5.89303 12.1775H5.53253V13H5.01803V10.6655ZM5.84053 11.7505C5.93853 11.7505 6.01553 11.7213 6.07153 11.663C6.12986 11.6023 6.15903 11.5242 6.15903 11.4285C6.15903 11.3305 6.12986 11.2512 6.07153 11.1905C6.01319 11.1275 5.93503 11.096 5.83703 11.096H5.53253V11.7505H5.84053ZM7.04146 10.6655H7.92346C8.10313 10.6655 8.2688 10.6888 8.42046 10.7355C8.57446 10.7822 8.7063 10.8533 8.81596 10.949C8.92796 11.0447 9.0143 11.1672 9.07496 11.3165C9.13796 11.4658 9.16946 11.642 9.16946 11.845C9.16946 12.0387 9.13796 12.2078 9.07496 12.3525C9.01196 12.4948 8.92446 12.615 8.81246 12.713C8.7028 12.8087 8.57096 12.881 8.41696 12.93C8.2653 12.9767 8.09963 13 7.91996 13H7.04146V10.6655ZM7.89196 12.531C8.1253 12.531 8.30496 12.4703 8.43096 12.349C8.55696 12.2253 8.61996 12.0562 8.61996 11.8415C8.61996 11.7342 8.6048 11.6373 8.57446 11.551C8.54646 11.4623 8.50213 11.3877 8.44146 11.327C8.38313 11.264 8.30846 11.2162 8.21746 11.1835C8.12646 11.1485 8.01796 11.131 7.89196 11.131H7.56996V12.531H7.89196ZM9.55025 10.6655H11.1183V11.131H10.0788V11.6595H10.8698V12.111H10.0788V13H9.55025V10.6655Z" />
    </svg>
  );
}
Dwg.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Dwg.defaultProps = {
  title: '',
};

export default injectIntl(Dwg);
