import React from 'react';

import { Fab, Zoom } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Add from 'components/SvgComponents/icons/Add';

const styles = theme => ({
  fabAdd: {
    height: 64,
    width: 64,
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(6),
    background: 'linear-gradient(45deg, #EC9135 0%, #DC3C2E 100%)',
    color: theme.palette.text.white,
  },
});

/**
 * AddNewProject
 */
function FabButton(props) {
  /**
   * Render the component
   *
   * @return {XML}
   */
  const { classes, theme, disabled, icon, className } = props;
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Zoom
      in
      timeout={transitionDuration}
      style={{
        transitionDelay: transitionDuration.exit,
      }}
      unmountOnExit
    >
      <Fab
        disabled={disabled}
        className={classNames(classes.fabAdd, className)}
        onClick={props.onClick}
      >
        {icon || <Add color="inherit" fontSize="large" />}
      </Fab>
    </Zoom>
  );
}

FabButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  theme: PropTypes.object,
  onClick: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(FabButton);
