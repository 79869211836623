import React from 'react';

import { withStyles } from '@mui/styles';
import { useOktaAuth } from '@okta/okta-react';
import Gleap from 'gleap';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import Button from 'components/Button';
import Trash from 'components/SvgComponents/icons/Trash';
import globalMessages from 'translations/messages/global-messages';
import localStorageUser from 'utils/localStorageUser';
import sessionStorageUser from 'utils/sessionStorageUser';

import DialogDeleteAccount from './DialogDeleteAccount';

const styles = () => ({
  button: {
    whiteSpace: 'nowrap',
  },
});

function DeleteAccount(props) {
  const { userEmail, intl, openModal, setOpenModal, classes, onDeleteAccount } = props;
  const { oktaAuth } = useOktaAuth();
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleDeleteAccount = () => {
    const deleteAccountSuccessCallback = () => {
      // do logout after all
      sessionStorageUser.setFinalcadTempRegistrationInfo(null);
      sessionStorageUser.setIsSignUp(null);
      localStorageUser.cleanExpiredProjectFilter();
      oktaAuth.signOut();
      Gleap.clearIdentity();
      Gleap.clearCustomData();
    };

    // call to delete account service
    onDeleteAccount({ deleteAccountSuccessCallback });
    toggleModal();
  };

  return (
    <div>
      <Button
        variant="danger"
        className={classes.button}
        onClick={() => toggleModal()}
        aria-label={intl.formatMessage(globalMessages.account_settings_delete_account_button)}
        title={intl.formatMessage(globalMessages.account_settings_delete_account_button)}
        startIcon={<Trash color="inherit" />}
      >
        {intl.formatMessage(globalMessages.account_settings_delete_account_button)}
      </Button>
      {openModal && (
        <DialogDeleteAccount
          onCloseDialog={toggleModal}
          onSubmitDialog={handleDeleteAccount}
          userEmail={userEmail}
        />
      )}
    </div>
  );
}

DeleteAccount.propTypes = {
  userEmail: PropTypes.string,
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  onDeleteAccount: PropTypes.func,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(DeleteAccount);
