import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

function ArrowRight(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-hidden="true"
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || props.intl.formatMessage(globalMessages.right)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 7.99998C13.3333 8.36817 13.0349 8.66665 12.6667 8.66665L3.33333 8.66665C2.96514 8.66665 2.66666 8.36817 2.66666 7.99998C2.66666 7.63179 2.96514 7.33331 3.33333 7.33331L12.6667 7.33331C13.0349 7.33331 13.3333 7.63179 13.3333 7.99998Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1381 7.52862C13.3984 7.78897 13.3984 8.21108 13.1381 8.47143L8.4714 13.1381C8.21105 13.3984 7.78894 13.3984 7.52859 13.1381C7.26824 12.8777 7.26824 12.4556 7.52859 12.1953L11.7239 8.00002L7.52859 3.80476C7.26824 3.54441 7.26824 3.1223 7.52859 2.86195C7.78894 2.6016 8.21105 2.6016 8.4714 2.86195L13.1381 7.52862Z"
      />
    </svg>
  );
}
ArrowRight.propTypes = {
  title: PropTypes.string,
};

ArrowRight.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(ArrowRight);
