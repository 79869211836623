import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectLoginUserState = state => state.loginUser || initialState;

const selectLoadingFlag = () =>
  createSelector(selectLoginUserState, dataState => dataState.get('loading'));

const selectForgotPasswordVisible = () =>
  createSelector(selectLoginUserState, dataState => dataState.get('forgotPasswordVisible'));

const selectOktaWidgetUuid = () =>
  createSelector(selectLoginUserState, dataState => dataState.get('oktaWidgetUuid'));

const selectShowRelogin = () =>
  createSelector(selectLoginUserState, dataState => dataState.get('shouldShowRelogin'));

export { selectLoadingFlag, selectOktaWidgetUuid, selectShowRelogin, selectForgotPasswordVisible };
