import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectDragDropTableSelfStandingState = (namespace = 'tableDragDrop1') => state =>
  state[namespace] || initialState;

const selectLoadingFlag = (namespace = 'tableDragDrop1') =>
  createSelector(selectDragDropTableSelfStandingState(namespace), dataState =>
    dataState.get('loading'),
  );

const selectItems = (namespace = 'tableDragDrop1') =>
  createSelector(selectDragDropTableSelfStandingState(namespace), dragDropSelfStandingTable =>
    dragDropSelfStandingTable.get('list'),
  );

const selectCheckedItems = (namespace = 'tableDragDrop1') =>
  createSelector(selectDragDropTableSelfStandingState(namespace), dragDropSelfStandingTable =>
    dragDropSelfStandingTable.get('checkedItems').toJS(),
  );

const selectIsEmptyTable = (namespace = 'tableDragDrop1') =>
  createSelector(selectDragDropTableSelfStandingState(namespace), dragDropSelfStandingTable => {
    const list = dragDropSelfStandingTable.get('list');
    return list?.size === 0 && !dragDropSelfStandingTable.get('searchText');
  });

const selectSearchText = (namespace = 'tableDragDrop1') =>
  createSelector(selectDragDropTableSelfStandingState(namespace), dataState =>
    dataState.get('searchText'),
  );

export {
  selectDragDropTableSelfStandingState,
  selectLoadingFlag,
  selectItems,
  selectCheckedItems,
  selectIsEmptyTable,
  selectSearchText,
};
