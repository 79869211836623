import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectTableDropdown = state => state.tableDropdown || initialState;

const selectId = () => createSelector(selectTableDropdown, dataState => dataState.get('id'));

const selectVisibility = () =>
  createSelector(selectTableDropdown, dataState => dataState.get('visibility'));

const selectRef = () =>
  createSelector(selectTableDropdown, dataState => dataState.get('reference').toJS());

const selectSelected = () =>
  createSelector(selectTableDropdown, dataState => dataState.get('selected'));

export { selectId, selectVisibility, selectRef, selectSelected };
