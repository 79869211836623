import React from 'react';

import PropTypes from 'prop-types';

export const Rail = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 11.4477 3.44772 11 4 11L20 11C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13L4 13C3.44772 13 3 12.5523 3 12Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8C4 7.44772 4.44772 7 5 7L19 7C19.5523 7 20 7.44772 20 8C20 8.55229 19.5523 9 19 9L5 9C4.44771 9 4 8.55228 4 8Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 16C2 15.4477 2.44772 15 3 15H21C21.5523 15 22 15.4477 22 16C22 16.5523 21.5523 17 21 17H3C2.44772 17 2 16.5523 2 16Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.20953 4.0222C8.74955 4.13792 9.09352 4.6695 8.9778 5.20953L5.9778 19.2095C5.86208 19.7496 5.3305 20.0935 4.79047 19.9778C4.25045 19.8621 3.90648 19.3305 4.0222 18.7905L7.0222 4.79047C7.13792 4.25045 7.6695 3.90648 8.20953 4.0222Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7905 4.0222C16.3305 3.90648 16.8621 4.25045 16.9778 4.79047L19.9778 18.7905C20.0935 19.3305 19.7496 19.8621 19.2095 19.9778C18.6695 20.0935 18.1379 19.7496 18.0222 19.2095L15.0222 5.20953C14.9065 4.6695 15.2504 4.13792 15.7905 4.0222Z"
      fill="current"
    />
  </svg>
);

Rail.propTypes = {
  size: PropTypes.number,
};
