import React from 'react';

function EsSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512.001 512.001"
      width="60px"
      height="36px"
      {...props}
    >
      <path
        style={{ fill: '#C8414B' }}
        d="M8.828,423.725h494.345c4.875,0,8.828-3.953,8.828-8.828V97.104c0-4.875-3.953-8.828-8.828-8.828
	H8.828C3.953,88.277,0,92.229,0,97.104v317.793C0,419.773,3.953,423.725,8.828,423.725z"
      />
      <rect y="158.901" style={{ fill: '#FFD250' }} width="512" height="194.21" />
      <path
        style={{ fill: '#C8414B' }}
        d="M216.276,256.001l7.485-33.681c0.69-3.102-1.671-6.044-4.849-6.044h-5.272
	c-3.177,0-5.537,2.942-4.849,6.044L216.276,256.001z"
      />
      <rect x="207.45" y="238.341" style={{ fill: '#F5F5F5' }} width="17.655" height="75.03" />
      <rect x="203.03" y="229.521" style={{ fill: '#FAB446' }} width="26.483" height="8.828" />
      <g>
        <rect x="185.38" y="256.001" style={{ fill: '#C8414B' }} width="44.14" height="8.828" />
        <polygon
          style={{ fill: '#C8414B' }}
          points="229.517,291.311 203.034,282.484 203.034,273.656 229.517,282.484 	"
        />
        <path
          style={{ fill: '#C8414B' }}
          d="M83.862,256.001l7.485-33.681c0.69-3.102-1.671-6.044-4.849-6.044h-5.272
		c-3.177,0-5.537,2.942-4.849,6.044L83.862,256.001z"
        />
      </g>
      <path
        style={{ fill: '#F5F5F5' }}
        d="M114.759,229.518c-4.875,0-8.828,3.953-8.828,8.828v57.379c0,10.725,10.01,30.897,44.138,30.897
	s44.138-20.171,44.138-30.897v-57.379c0-4.875-3.953-8.828-8.828-8.828H114.759z"
      />
      <g>
        <path
          style={{ fill: '#C8414B' }}
          d="M150.069,273.656h-44.138v-35.31c0-4.875,3.953-8.828,8.828-8.828h35.31V273.656z"
        />
        <path
          style={{ fill: '#C8414B' }}
          d="M150.069,273.656h44.138v22.069c0,12.189-9.88,22.069-22.069,22.069l0,0
		c-12.189,0-22.069-9.88-22.069-22.069V273.656z"
        />
      </g>
      <path
        style={{ fill: '#FAB446' }}
        d="M105.931,273.656h44.138v22.069c0,12.189-9.88,22.069-22.069,22.069l0,0
	c-12.189,0-22.069-9.88-22.069-22.069V273.656z"
      />
      <g>
        <path
          style={{ fill: '#C8414B' }}
          d="M141.241,313.281v-39.625h-8.828v43.693C135.697,316.683,138.664,315.229,141.241,313.281z"
        />
        <path
          style={{ fill: '#C8414B' }}
          d="M123.586,317.349v-43.693h-8.828v39.625C117.336,315.229,120.303,316.683,123.586,317.349z"
        />
      </g>
      <rect x="114.76" y="256.001" style={{ fill: '#FFB441' }} width="26.483" height="8.828" />
      <g>
        <rect x="114.76" y="238.341" style={{ fill: '#FAB446' }} width="26.483" height="8.828" />
        <rect x="119.17" y="243.591" style={{ fill: '#FAB446' }} width="17.655" height="15.992" />
      </g>
      <rect x="75.03" y="238.341" style={{ fill: '#F5F5F5' }} width="17.655" height="75.03" />
      <g>
        <rect x="70.62" y="308.971" style={{ fill: '#FAB446' }} width="26.483" height="8.828" />
        <rect x="70.62" y="229.521" style={{ fill: '#FAB446' }} width="26.483" height="8.828" />
      </g>
      <rect x="66.21" y="317.791" style={{ fill: '#5064AA' }} width="35.31" height="8.828" />
      <rect x="207.45" y="308.971" style={{ fill: '#FAB446' }} width="26.483" height="8.828" />
      <rect x="198.62" y="317.791" style={{ fill: '#5064AA' }} width="35.31" height="8.828" />
      <rect x="123.59" y="220.691" style={{ fill: '#FAB446' }} width="52.966" height="8.828" />
      <rect x="145.66" y="194.211" style={{ fill: '#FFB441' }} width="8.828" height="26.483" />
      <g>
        <path
          style={{ fill: '#F5F5F5' }}
          d="M141.241,207.449c-7.302,0-13.241-5.94-13.241-13.241c0-7.302,5.94-13.241,13.241-13.241
		c7.302,0,13.241,5.94,13.241,13.241C154.483,201.509,148.543,207.449,141.241,207.449z M141.241,189.794
		c-2.435,0-4.414,1.978-4.414,4.414c0,2.435,1.978,4.414,4.414,4.414s4.414-1.978,4.414-4.414
		C145.655,191.773,143.677,189.794,141.241,189.794z"
        />
        <path
          style={{ fill: '#F5F5F5' }}
          d="M158.897,207.449c-7.302,0-13.241-5.94-13.241-13.241c0-7.302,5.94-13.241,13.241-13.241
		c7.302,0,13.241,5.94,13.241,13.241S166.198,207.449,158.897,207.449z M158.897,189.794c-2.435,0-4.414,1.978-4.414,4.414
		c0,2.435,1.978,4.414,4.414,4.414c2.435,0,4.414-1.978,4.414-4.414C163.31,191.773,161.332,189.794,158.897,189.794z"
        />
        <path
          style={{ fill: '#F5F5F5' }}
          d="M176.552,216.277c-7.302,0-13.241-5.94-13.241-13.241c0-7.302,5.94-13.241,13.241-13.241
		c7.302,0,13.241,5.94,13.241,13.241S183.853,216.277,176.552,216.277z M176.552,198.622c-2.435,0-4.414,1.978-4.414,4.414
		c0,2.435,1.978,4.414,4.414,4.414c2.435,0,4.414-1.978,4.414-4.414S178.987,198.622,176.552,198.622z"
        />
        <path
          style={{ fill: '#F5F5F5' }}
          d="M123.586,216.277c-7.302,0-13.241-5.94-13.241-13.241c0-7.302,5.94-13.241,13.241-13.241
		c7.302,0,13.241,5.94,13.241,13.241C136.828,210.337,130.888,216.277,123.586,216.277z M123.586,198.622
		c-2.435,0-4.414,1.978-4.414,4.414c0,2.435,1.978,4.414,4.414,4.414s4.414-1.979,4.414-4.415
		C128,200.6,126.022,198.622,123.586,198.622z"
        />
      </g>
      <path
        style={{ fill: '#FAB446' }}
        d="M176.552,291.311v4.414c0,2.434-1.98,4.414-4.414,4.414s-4.414-1.98-4.414-4.414v-4.414H176.552
	 M185.379,282.484h-26.483v13.241c0,7.302,5.94,13.241,13.241,13.241c7.302,0,13.241-5.94,13.241-13.241v-13.241H185.379z"
      />
      <path
        style={{ fill: '#FFA0D2' }}
        d="M172.138,264.829L172.138,264.829c-4.875,0-8.828-3.953-8.828-8.828v-8.828
	c0-4.875,3.953-8.828,8.828-8.828l0,0c4.875,0,8.828,3.953,8.828,8.828v8.828C180.966,260.876,177.013,264.829,172.138,264.829z"
      />
      <circle style={{ fill: '#5064AA' }} cx="150.07" cy="273.651" r="13.241" />
      <rect x="145.66" y="176.551" style={{ fill: '#FAB446' }} width="8.828" height="26.483" />
      <path
        style={{ fill: '#C8414B' }}
        d="M123.586,220.691l-8.828-8.828l5.171-5.171c7.993-7.993,18.835-12.484,30.14-12.484l0,0
	c11.305,0,22.146,4.491,30.14,12.484l5.171,5.171l-8.828,8.828H123.586z"
      />
      <g>
        <circle style={{ fill: '#FFD250' }} cx="150.07" cy="211.861" r="4.414" />
        <circle style={{ fill: '#FFD250' }} cx="132.41" cy="211.861" r="4.414" />
        <circle style={{ fill: '#FFD250' }} cx="167.72" cy="211.861" r="4.414" />
      </g>
      <g>
        <rect x="70.62" y="256.001" style={{ fill: '#C8414B' }} width="44.14" height="8.828" />
        <polygon
          style={{ fill: '#C8414B' }}
          points="70.621,291.311 97.103,282.484 97.103,273.656 70.621,282.484 	"
        />
      </g>
    </svg>
  );
}

export default EsSvg;
