import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import uuid from 'uuid';

import config from 'config/config';
import { getPlan } from 'containers/OneProjectPage/PlansPage/actions';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import { Routing } from 'routing/routing';
import combineStyles from 'theme/combineStyles';
import { styles as mapTheme } from 'theme/map-interaction-theme';
import Analytics from 'utils/Analytics';
import { customerIoTrackerWithParams } from 'utils/CustomerIo';
import { getPlanFileFormat } from 'utils/file';
import { getFileTypeFromBlob } from 'utils/fileType';
import { usePrevious } from 'utils/hooks';
import localStorageUser from 'utils/localStorageUser';

import PdfPlanPin from '../PlanViewer/PdfPlanPin';
import AddItemButton from './add-items-controls/AddItemButton';
import { displayFirstPageOnly } from './utils';
import { ZoomControls } from './zoom-controls';

const styles = theme => ({
  scrollView: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',

    '& .pageContainer canvas:last-child': {
      position: 'relative !important',
    },
  },
  scrollViewNoScroll: {
    height: '100%',
    width: '100%',
    bottom: 0,
    display: 'flex',

    '& canvas': {
      left: 0,
      width: '100% !important',
    },
    '& .pageSection': {
      display: 'none',
    },
    '& .pageSection:first-child': {
      margin: '0 !important',
      width: '100% !important',
      display: 'inherit',
    },
    '& .pageContainer': {
      margin: '0 !important',
      width: '100% !important',
    },
  },
  viewer: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    left: 0,
    right: 0,
  },
  viewerNoScroll: {
    cursor: 'inherit !important',
    margin: 0,
    display: 'flex',
    width: '100% !important',
  },
  path: {
    cursor: 'pointer',
    '&:hover': {
      stroke: theme.palette.info.main,
      strokeWidth: 4,
      strokeLinecap: 'square',
    },
  },
  actionSection: {
    position: 'absolute',
    right: theme.spacing(6),
    display: 'grid',
    gridTemplateRows: `repeat(3, 1fr)`,
    justifyContent: 'center',
    alignItems: 'stretch',
    zIndex: 100,
    gap: theme.spacing(2),
    height: '100%',
  },
  zoomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'center',
  },
});

const tronId = uuid.v4();

const PdfTronViewer = ({
  changeLoader,
  classes,
  docUrl,
  entityType,
  history,
  match,
  onPageLoadSuccess,
  overflow = true,
  pins = [],
  onPlacePin,
  shouldAddClickableZones,
  statuses = [],
  tableItems,
  zonesData,
  moduleId,
  addZoomButton = true,
  mouseWheelZoom = false,
  seeAnnotations,
  isFirstPageOnly,
  updatePin,
  dispatchGetPlan,
}) => {
  const { params } = match;
  const { organizationId, idData, itemId } = params;
  const [showPins, setShowPins] = useState(true);
  const featureFlags = useFeatureFlags();
  const [documentViewer] = useState(new window.Core.DocumentViewer());
  const [dimmensions, setDimmensions] = useState({ width: 0, height: 0 });
  const prevUrl = usePrevious(docUrl);
  const [zoomValue, setZoomValue] = useState(1);
  const zoomScale = zoomValue > 1 ? 0.25 : 0.1;
  const shouldAddItems = featureFlags?.plan_add_items;
  const resetDimmensions = () => {
    const elPageContainer = document.getElementById('pageContainer1');
    if (elPageContainer) {
      setDimmensions({
        width: elPageContainer.offsetWidth,
        height: elPageContainer.offsetHeight,
      });
    }
  };

  const zoomOut = () => {
    const newZoom = documentViewer.getZoomLevel() - zoomScale;
    setZoomValue(newZoom);
    documentViewer.zoomTo(newZoom);
  };

  const zoomIn = () => {
    const newZoom = documentViewer.getZoomLevel() + zoomScale;
    setZoomValue(newZoom);
    documentViewer.zoomTo(newZoom);
  };

  const onRedirectToPlan = plan => {
    Analytics.track('plans_plan_opened', {
      source: 'folder_overview',
      file_format: getPlanFileFormat(plan),
    });
    customerIoTrackerWithParams('plans_plan_opened', organizationId, idData);
    localStorageUser.addRecentlyViewedPlans({
      ...plan,
      folder_id: itemId || 'root',
      project_id: idData,
    });

    history.push(
      Routing.plans.plan(organizationId, idData, plan?.folder_id || itemId, plan.id, 'plan'),
    );
  };

  const handleShapeClick = localisation => () => {
    const clickedItem = tableItems.find(item => item.id === localisation.id);
    if (clickedItem == null) {
      dispatchGetPlan(localisation.id, onRedirectToPlan, null);
    } else {
      onRedirectToPlan(clickedItem);
    }
  };

  const processLocalisationCoordinate = (coord, index, localisation) => {
    let commands = '';
    const xPixels = Math.floor(dimmensions.width * coord.x);
    const yPixels = Math.floor(dimmensions.height * coord.y);
    if (index === 0) {
      commands += `M ${xPixels} ${yPixels} `;
    } else if (index === localisation.coordinates.length - 1) {
      commands += `L ${xPixels} ${yPixels} `;
      const xFirst = Math.floor(dimmensions.width * localisation.coordinates[0].x);
      const yFirst = Math.floor(dimmensions.height * localisation.coordinates[0].y);
      commands += `L ${xFirst} ${yFirst} `;
    } else {
      commands += `L ${xPixels} ${yPixels} `;
    }
    return commands;
  };

  const returnText = localisation => {
    if (!localisation.text_coordinates) {
      return null;
    }
    const xPixels = Math.floor(dimmensions.width * localisation.text_coordinates.x_min);
    const yPixels = Math.floor(dimmensions.height * localisation.text_coordinates.y_max);
    const fSize = Math.round(
      Math.ceil(Math.round(dimmensions.width) * localisation.text_coordinates.font_size),
    );
    const plan = tableItems.find(item => item.id === localisation.id);

    return (
      <foreignObject
        key={`clickabletext-${plan?.id}`}
        x={xPixels}
        y={yPixels}
        width={Math.floor(
          dimmensions.width * localisation.text_coordinates.x_max -
            dimmensions.width * localisation.text_coordinates.x_min,
        )}
        height={Math.floor(
          dimmensions.height * localisation.text_coordinates.y_min -
            dimmensions.height * localisation.text_coordinates.y_max,
        )}
        onClick={handleShapeClick(localisation)}
        style={{ overflow: 'visible', cursor: 'pointer' }}
      >
        <div style={{ font: `${fSize}px Effra`, overflow: 'visible' }}>{plan?.name}</div>
      </foreignObject>
    );
  };

  const drawIslandCoordinates = localisation => {
    let commands = '';
    if (localisation.islands_coordinates) {
      // we must go counterclockwise in canvas to remove an area
      localisation.islands_coordinates.forEach(island => {
        island.reverse().forEach((coord, index) => {
          const xPixels = Math.floor(dimmensions.width * coord.x);
          const yPixels = Math.floor(dimmensions.height * coord.y);

          if (index === 0) {
            commands += `M ${xPixels} ${yPixels} `;
          } else if (index === island.length - 1) {
            commands += `L ${xPixels} ${yPixels} `;
            const xFirst = Math.floor(dimmensions.width * island[0].x);
            const yFirst = Math.floor(dimmensions.height * island[0].y);
            commands += `L ${xFirst} ${yFirst} `;
          } else {
            commands += `L ${xPixels} ${yPixels} `;
          }
        });
      });
    }
    return commands;
  };

  const getLayerContent = (localisation, includeAreaAndNames) => {
    const rgbaColor = localisation.color;
    const rgbaFromArgb = `#${rgbaColor.slice(3)}${rgbaColor.slice(1, 3)}`;
    const color = !includeAreaAndNames ? `${rgbaFromArgb}` : '#00000000';
    const uniqueKey = `path-${localisation.id}`;
    let pathCommands = '';
    localisation.coordinates.forEach((coord, index) => {
      pathCommands += processLocalisationCoordinate(coord, index, localisation);
    });
    pathCommands += drawIslandCoordinates(localisation);
    return (
      <path
        className={classes.path}
        key={uniqueKey}
        fill={color}
        d={pathCommands}
        onClick={handleShapeClick(localisation)}
      />
    );
  };

  const getClickableZones = () => {
    if (!shouldAddClickableZones) {
      return <div />;
    }
    return (
      <svg width={dimmensions.width} height={dimmensions.height} fillRule="evenodd">
        {shouldAddClickableZones &&
          zonesData.folder.localisations.map(localisation =>
            getLayerContent(localisation, zonesData.folder.includes_area_and_names),
          )}
        {shouldAddClickableZones &&
          !zonesData.folder.includes_area_and_names &&
          zonesData.folder.localisations.map(localisation => returnText(localisation))}
      </svg>
    );
  };

  const drawSvgClickableZones = () => {
    const node = document.createElement('div');
    node.className = 'clickable-zones';
    node.style.width = '100%';
    node.style.height = '100%';
    node.style.position = 'absolute';
    node.style.zIndex = 1000;

    const previousNode = document.querySelector('.clickable-zones');
    if (previousNode) {
      document.getElementById('pageContainer1').removeChild(previousNode);
    }

    ReactDOM.render(getClickableZones(), node);
    document.getElementById('pageContainer1').appendChild(node);
  };

  useEffect(() => {
    const scrollViewEl = document.getElementById(`scroll-view-${tronId}`);
    const viewerEl = document.getElementById(`viewer-${tronId}`);
    if (seeAnnotations) documentViewer.enableAnnotations();
    else documentViewer.disableAnnotations();
    documentViewer.setScrollViewElement(scrollViewEl);
    documentViewer.setViewerElement(viewerEl);

    fetch(docUrl)
      .then(res => res.blob())
      .then(blob => {
        const documentUrl = window.URL.createObjectURL(blob);
        documentViewer.loadDocument(documentUrl, {
          l: config.pdfTronLicenseKey,
        });
      });
    if (changeLoader) {
      changeLoader(true);
    }

    const getMouseLocation = () => {
      const elementRect = viewerEl.getBoundingClientRect();
      const x = elementRect.left + scrollViewEl.scrollLeft;
      const y = elementRect.top + scrollViewEl.scrollTop;
      return { x, y };
    };

    const handleMouseWheelZoom = e => {
      let throttleTime;
      e.stopPropagation();
      e.preventDefault();

      if (!throttleTime) {
        throttleTime = setTimeout(() => {
          const mouseLocation = getMouseLocation(e);

          const scaleFactor = e.deltaMode === 0 ? 0.05 : 0.3; // adjust these values to your preference

          if (e.deltaY < 0) {
            documentViewer.zoomToMouse(
              documentViewer.getZoomLevel() * (1 + scaleFactor),
              mouseLocation.x,
              mouseLocation.y,
            );
          } else {
            documentViewer.zoomToMouse(
              documentViewer.getZoomLevel() * (1 - scaleFactor),
              mouseLocation.x,
              mouseLocation.y,
            );
          }
          clearTimeout(throttleTime);
          throttleTime = null;
        }, 100);
      }
    };

    if (mouseWheelZoom) {
      scrollViewEl.addEventListener('wheel', handleMouseWheelZoom, { passive: false });
    }

    documentViewer.addEventListener('documentLoaded', () => {
      if (isFirstPageOnly) displayFirstPageOnly(documentViewer);
      const rotation = documentViewer.getCompleteRotation(1);
      documentViewer.setMargin(0);

      onPageLoadSuccess(rotation);
      if (!overflow) {
        documentViewer.setFitMode(documentViewer.FitMode.FitWidth);
      }
      documentViewer.setToolMode(documentViewer.getTool(window.Core.Tools.ToolNames.PAN));

      if (changeLoader) {
        changeLoader(false);
      }
      resetDimmensions();
    });

    documentViewer.addEventListener(window.Core.DocumentViewer.Events.ZOOM_UPDATED, () => {
      resetDimmensions();
    });

    return () => {
      scrollViewEl.removeEventListener('wheel', handleMouseWheelZoom);
    };
  }, []);

  useEffect(
    () => {
      const annotationManager = documentViewer.getAnnotationManager();
      const AllAnnotations = annotationManager.getAnnotationsList();

      if (seeAnnotations) annotationManager.showAnnotations(AllAnnotations);
      else annotationManager.hideAnnotations(AllAnnotations);
    },
    [seeAnnotations],
  );

  useEffect(
    () => {
      if (shouldAddClickableZones && dimmensions.height) {
        drawSvgClickableZones();
      }
      if (prevUrl !== docUrl) {
        if (changeLoader) {
          changeLoader(true);
        }

        fetch(docUrl)
          .then(res => res.blob())
          .then(blob => {
            getFileTypeFromBlob(blob)
              .then(detectedFileType => {
                let mimeType = 'pdf';
                if (detectedFileType === 'image/jpeg') {
                  mimeType = 'jpg';
                } else if (detectedFileType === 'image/png') {
                  mimeType = 'png';
                } else if (detectedFileType === 'application/pdf') {
                  mimeType = 'pdf';
                }

                documentViewer.loadDocument(blob, {
                  l: config.pdfTronLicenseKey,
                  extension: mimeType,
                });
              })
              .catch(() => {
                documentViewer.loadDocument(blob, {
                  l: config.pdfTronLicenseKey,
                });
              });
          });
      }
    },
    [dimmensions, docUrl, shouldAddClickableZones],
  );

  const getStatusColor = status_id => statuses?.find(status => status.id === status_id)?.color;

  return (
    <>
      <div
        key={`scroll-view-${tronId}`}
        id={`scroll-view-${tronId}`}
        className={overflow ? classes.scrollView : classes.scrollViewNoScroll}
      >
        <div className={overflow ? classes.viewer : classes.viewerNoScroll} id={`viewer-${tronId}`}>
          <div id="display-pins">
            {showPins &&
              pins?.map(pin => (
                <PdfPlanPin
                  color={getStatusColor(pin.status_id)}
                  entityType={entityType}
                  key={pin.id}
                  pinData={pin}
                  zoomValue={zoomValue}
                />
              ))}
          </div>
          <div id="clickToPlacePin" style={{ zIndex: 10 }}>
            {Boolean(shouldAddItems) &&
              !shouldAddClickableZones &&
              entityType && (
                <AddItemButton
                  entityType={entityType}
                  moduleId={moduleId}
                  updatePin={updatePin}
                  onClickFromItem={position => {
                    onPlacePin(position);
                  }}
                  showCustomPin={onPlacePin}
                  isPinsVisible={placing => setShowPins(!placing)}
                />
              )}
          </div>
        </div>
        <div id="parent-pins" />
      </div>
      <Box className={classes.actionSection} sx={{ gridTemplateRows: 'auto !important' }}>
        <Box className={classes.zoomContainer}>
          {Boolean(addZoomButton) && (
            <ZoomControls
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              zoomOutIsDisabled={zoomScale >= zoomValue}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

PdfTronViewer.propTypes = {
  changeLoader: PropTypes.func,
  classes: PropTypes.object,
  docUrl: PropTypes.string,
  entityType: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  onPageLoadSuccess: PropTypes.func,
  overflow: PropTypes.bool,
  pins: PropTypes.array,
  onPlacePin: PropTypes.func,
  moduleId: PropTypes.string,
  shouldAddClickableZones: PropTypes.bool,
  statuses: PropTypes.array,
  tableItems: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  zonesData: PropTypes.object,
  addZoomButton: PropTypes.bool,
  mouseWheelZoom: PropTypes.bool,
  seeAnnotations: PropTypes.bool,
  isFirstPageOnly: PropTypes.bool,
  updatePin: PropTypes.func,
  dispatchGetPlan: PropTypes.func,
};

PdfTronViewer.defaultProps = {
  overflow: true,
  pins: [],
  statuses: [],
  addZoomButton: true,
  mouseWheelZoom: false,
};

const mapDispatchToProps = {
  dispatchGetPlan: getPlan,
};

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withRouter,
  withStyles(combineStyles(styles, mapTheme)),
)(PdfTronViewer);
