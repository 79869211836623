import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function Power(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || props.intl.formatMessage(globalMessages.sign_out)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33699 5.93291C6.72758 6.32337 6.72768 6.95654 6.33722 7.34713C5.21866 8.46605 4.457 9.89152 4.14853 11.4433C3.84006 12.9951 3.99864 14.6035 4.60421 16.0651C5.20979 17.5268 6.23517 18.7761 7.55071 19.655C8.86625 20.5339 10.4129 21.0031 11.995 21.0031C13.5771 21.0031 15.1238 20.5339 16.4393 19.655C17.7548 18.7761 18.7802 17.5268 19.3858 16.0651C19.9914 14.6035 20.1499 12.9951 19.8415 11.4433C19.533 9.89152 18.7713 8.46605 17.6528 7.34713C17.2623 6.95654 17.2624 6.32337 17.653 5.93291C18.0436 5.54245 18.6768 5.54256 19.0672 5.93315C20.4654 7.3318 21.4175 9.11364 21.8031 11.0534C22.1887 12.9931 21.9905 15.0036 21.2335 16.8306C20.4765 18.6577 19.1948 20.2193 17.5504 21.318C15.9059 22.4167 13.9727 23.0031 11.995 23.0031C10.0173 23.0031 8.08406 22.4167 6.43964 21.318C4.79522 20.2193 3.51349 18.6577 2.75652 16.8306C1.99954 15.0036 1.80132 12.9931 2.18691 11.0534C2.5725 9.11364 3.52458 7.3318 4.92278 5.93315C5.31324 5.54256 5.9464 5.54245 6.33699 5.93291Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C12.5523 1 13 1.44772 13 2V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V2C11 1.44772 11.4477 1 12 1Z"
      />
    </svg>
  );
}
Power.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Power.defaultProps = {
  title: '',
};

export default injectIntl(Power);
