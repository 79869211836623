import React, { useState } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Breadcrumb from 'containers/BusinessOrganisation/components/Breadcrumb';
import { getBusinessOrganization } from 'containers/GlobalWrapper/actions';
import { selectLoadingFlagGlobal } from 'containers/GlobalWrapper/selectors';
import { Routing } from 'routing/routing';
import injectSaga from 'utils/injectSaga';

import { addOrganisation } from './actions';
import AddBusinessOrganisation from './components/AddBusinessOrganisation';
import ListSearchOrganisation from './components/ListSearchOrganisation';
import { addBusinessOrganization } from './components/utils/config';
import saga from './saga';

export const styles = theme => ({
  contentMenu: {
    position: 'absolute',
    top: theme.spacing(14),
    left: theme.spacing(4),
    zIndex: 10,
  },
});

function BusinessOrganisation(props) {
  const [openSearch, setOpenSearch] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { classes, loading } = props;
  const handleToggleMenu = () => {
    setOpenSearch(!openSearch);
  };

  const handleToggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const callbackAddOrganisation = data => {
    handleChangeOrganization(data);
    handleToggleDialog();
  };

  const handleAddOrganisation = name => {
    props.dispatchAddOrganisation(name, callbackAddOrganisation);
  };

  const handleChangeOrganization = organization => {
    const { history, dispatchGetOrganization, match } = props;
    setOpenSearch(false);
    dispatchGetOrganization(organization?.id, () => {
      if (match.params?.pageName === 'projects') {
        window.location.reload();
      } else {
        history.push(Routing.organizations.projectList(organization.id));
      }
    });
  };

  return (
    <div>
      <Breadcrumb openListOrganisation={() => setOpenSearch(isOpen => !isOpen)} />
      <div className={classes.contentMenu}>
        {openSearch && (
          <ListSearchOrganisation
            onClickOrganisation={handleChangeOrganization}
            onToggleMenu={handleToggleMenu}
            openAddOrganisationDialog={handleToggleDialog}
          />
        )}
      </div>
      {openDialog && (
        <AddBusinessOrganisation
          form={addBusinessOrganization}
          loading={loading}
          open={openDialog}
          onAddOrganisation={handleAddOrganisation}
          onCloseDialog={handleToggleDialog}
        />
      )}
    </div>
  );
}
BusinessOrganisation.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
  dispatchAddOrganisation: PropTypes.func,
  dispatchGetOrganization: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoadingFlagGlobal(),
});

const mapDispatchToProps = {
  dispatchAddOrganisation: addOrganisation,
  dispatchGetOrganization: getBusinessOrganization,
};

const withSaga = injectSaga({ key: 'organisationPage', saga });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withSaga,
  withRouter,
  injectIntl,
  withStyles(styles),
)(BusinessOrganisation);
