import React, { forwardRef, useState } from 'react';

import { Avatar, styled } from '@mui/material';
import PropTypes from 'prop-types';

import SecuredImage, { quality } from 'components/SecuredImage';
import Finalcad from 'components/SvgComponents/icons/Finalcad';
import User from 'components/SvgComponents/icons/User';

const Avatar_Custom = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.common.white,
  fontSize: '50%',
}));

const UIAvatar = forwardRef(
  ({ src, alt, materialUIClasses, className, user, onError, ...other }, ref) => {
    const [error, setError] = useState(false);

    const handleError = error => {
      setError(true);
      if (onError) onError(error);
    };

    if (!src || error) {
      return (
        <Avatar_Custom
          alt={alt}
          ref={ref}
          classes={materialUIClasses}
          className={className}
          user={user}
          {...other}
        >
          {(() => {
            if (error) return <Finalcad />;
            if (!user?.first_name || !user?.last_name) return <User height="50%" width="50%" />;
            return `${user.first_name[0]} ${user.last_name[0]}`;
          })()}
        </Avatar_Custom>
      );
    }

    return (
      <SecuredImage imgQuality={quality.thumbnail} showFallback>
        <Avatar_Custom
          src={src}
          alt={alt}
          ref={ref}
          classes={materialUIClasses}
          className={className}
          onError={handleError}
          {...other}
        />
      </SecuredImage>
    );
  },
);

UIAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  materialUIClasses: PropTypes.object,
  className: PropTypes.string,
  user: PropTypes.object,
  onError: PropTypes.func,
};

export default UIAvatar;
