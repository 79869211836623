import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import ContractPageCTA from 'components/ContractPageCTA';
import { Routing } from 'routing/routing';
import combineStyles from 'theme/combineStyles';
import limitationStyle from 'theme/limitation-theme';
import globalMessages from 'translations/messages/global-messages';
import { limitationOffer, authorizations } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import localStorageUser from 'utils/localStorageUser';

import { getLimitOffer } from './actions';
import ButtonLimit from './components/ButtonLimit';
import reducer from './reducer';
import saga from './saga';
import { selectLimitsOfferObservation, selectLimitsOfferForms } from './selectors';
import { getLimits } from './utils/getLimits';

export const styles = theme => ({
  containerLimits: {
    display: 'flex',
    flexDirection: 'column',
    width: '208px',
    borderRadius: '16px',
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
    alignItems: 'center',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  textSubtitle: {
    marginTop: '16px',
    marginBottom: '16px',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export function LimitationMessageOffer(props) {
  const { classes, intl, limitsObservation, limitsForms, match } = props;
  const organization = localStorageUser.getBusinessOrganizationLocalStorage();
  const dispatch = useDispatch();

  const { params } = match;
  const organizationId = organization?.id;
  const getDataLimit = getLimits(limitsObservation, limitsForms);
  const limitsPercentage = limitsObservation?.percentage >= 80 || limitsForms?.percentage >= 80;
  const showLimits = limitsPercentage && limitsObservation && limitsForms && match.params?.idData;
  const canSubscribe =
    organization?.authorizations?.indexOf(authorizations.organization.writeOffer) > -1;

  const displayContract =
    organization?.offerDetails?.is_free && (!canSubscribe || !!match.params?.idData);

  const redirectURL = Routing.organizations.contract(organizationId);

  useEffect(
    () => {
      if (params.idData && organizationId) {
        dispatch(getLimitOffer({ params }));
      }
    },
    [dispatch, params, organizationId],
  );

  const getContentSubtitle = () => (
    <FormattedMessage
      {...globalMessages[getDataLimit.translationKey]}
      values={{
        type: <strong>{getDataLimit.type}</strong>,
      }}
    />
  );
  const displaySubtitle = () =>
    getDataLimit && getDataLimit?.translationKey
      ? getContentSubtitle()
      : intl.formatMessage(globalMessages.about_to_reach_limit);
  return (
    <>
      {showLimits ? (
        <div
          className={classNames({
            [classes.containerColorLimitsReachPlan]: getDataLimit?.type === limitationOffer.isLimit,
            [classes.containerLimits]: true,
            [classes.containerColorLimits]: getDataLimit?.type !== limitationOffer.isLimit,
          })}
        >
          <Typography
            variant="h6"
            className={classNames({
              [classes.nameTitleColor]: getDataLimit?.type !== limitationOffer.isLimit,
              [classes.nameTitleColorReach]: getDataLimit?.type === limitationOffer.isLimit,
            })}
          >
            {intl.formatMessage(globalMessages.go_unlimited)}
          </Typography>
          <Typography className={classes.textSubtitle} variant="subtitleLight">
            {displaySubtitle()}
            {!canSubscribe && intl.formatMessage(globalMessages.contact_your_admin)}
          </Typography>
          {canSubscribe && (
            <ButtonLimit
              isLimit={getDataLimit?.type === limitationOffer.isLimit}
              redirectURL={redirectURL}
            />
          )}
        </div>
      ) : (
        <>{displayContract && <ContractPageCTA />}</>
      )}
    </>
  );
}

LimitationMessageOffer.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  intl: intlShape.isRequired,
  limitsForms: PropTypes.object,
  limitsObservation: PropTypes.object,
};
LimitationMessageOffer.defaultProps = {
  limitsForms: {},
  limitsObservation: {},
};
const mapStateToProps = createStructuredSelector({
  limitsForms: selectLimitsOfferForms(),
  limitsObservation: selectLimitsOfferObservation(),
});

const withSaga = injectSaga({ key: 'limitationOffer', saga });
const withReducer = injectReducer({ key: 'limitationOffer', reducer });

const withConnect = connect(mapStateToProps);

export default compose(
  withStyles(combineStyles(styles, limitationStyle)),
  withConnect,
  withSaga,
  withReducer,
  withRouter,
  injectIntl,
)(LimitationMessageOffer);
