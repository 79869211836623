import React, { useState, useEffect } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import LoadingIndicator from 'components/LoadingIndicator';
import combineStyles from 'theme/combineStyles';
import { styles as mapTheme } from 'theme/map-interaction-theme';

import boundingClientRef from './boundingClientRef';
import PdfPreview from './PdfPreview';

export const styles = () => ({
  pdfViewerRef: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flex: 'auto',
    position: 'absolute',
    zIndex: -1,
  },
  pdfViewer: {
    display: 'flex',
    flex: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
});

export function PdfViewer({
  classes,
  pdfViewerClassname,
  url,
  isFullViewer,
  tableItems,
  renderPageNumber,
  changeLoader,
  onPdfLoadSuccess,
  zonesData,
  addZoomButton,
  mouseWheelZoom,
  overflow,
  pins,
  moduleId,
  entityType,
  statuses,
  seeAnnotations,
  isFirstPageOnly,
  updatePin,
  fileType,
  plan,
  hasOfferLimitation,
  displayLoading,
  tools,
  document,
  onPlacePin,
}) {
  const [bounding, ref] = boundingClientRef();
  const [elementDimension, setElementDimensions] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(
    () => {
      let tempScale = 1;
      if (elementDimension.originalWidth > bounding.width) {
        tempScale = bounding.width / elementDimension.originalWidth;
      }
      if (elementDimension.originalWidth < bounding.width) {
        tempScale = (bounding.width * 0.8) / elementDimension.originalWidth;
      }
      onPdfLoadSuccess(elementDimension * tempScale);
      setElementDimensions({
        originalHeight: elementDimension.originalHeight,
        originalWidth: elementDimension.originalWidth,
        scale: tempScale,
      });
    },
    [bounding.width],
  );

  const onPageLoadSuccessCallBack = (element, position) => {
    onPdfLoadSuccess(element, position);
  };

  const onPageLoadSuccess = (rotation, position) => {
    onPageLoadSuccessCallBack(rotation, position);
    setLoading(false);
  };

  return (
    <>
      <div ref={ref} className={classes.pdfViewerRef} />
      <div className={pdfViewerClassname || classes.pdfViewer}>
        {loading && displayLoading && <LoadingIndicator size="40" withBackground={false} />}
        <PdfPreview
          fullViewer={isFullViewer}
          addZoomButton={addZoomButton}
          mouseWheelZoom={mouseWheelZoom}
          overflow={overflow}
          tableItems={tableItems}
          zonesData={zonesData}
          file={url}
          renderPageNumber={renderPageNumber}
          elementDimension={elementDimension}
          onPageLoadSuccess={onPageLoadSuccess}
          changeLoader={changeLoader}
          pins={pins}
          moduleId={moduleId}
          entityType={entityType}
          statuses={statuses}
          seeAnnotations={seeAnnotations}
          isFirstPageOnly={isFirstPageOnly}
          updatePin={updatePin}
          fileType={fileType}
          plan={plan}
          hasOfferLimitation={hasOfferLimitation}
          tools={tools}
          document={document}
          onPlacePin={onPlacePin}
        />
      </div>
    </>
  );
}

PdfViewer.propTypes = {
  classes: PropTypes.object,
  addZoomButton: PropTypes.bool,
  overflow: PropTypes.bool,
  isFullViewer: PropTypes.bool,
  zonesData: PropTypes.object,
  changeLoader: PropTypes.func,
  pdfViewerClassname: PropTypes.string,
  tableItems: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  renderPageNumber: PropTypes.number,
  url: PropTypes.string,
  onPdfLoadSuccess: PropTypes.func,
  pins: PropTypes.array,
  moduleId: PropTypes.string,
  entityType: PropTypes.string,
  statuses: PropTypes.array,
  seeAnnotations: PropTypes.bool,
  isFirstPageOnly: PropTypes.bool,
  updatePin: PropTypes.func,
  fileType: PropTypes.string,
  plan: PropTypes.object,
  hasOfferLimitation: PropTypes.bool,
  displayLoading: PropTypes.bool,
  document: PropTypes.object,
  onPlacePin: PropTypes.func,
  mouseWheelZoom: PropTypes.bool,
  tools: PropTypes.shape({
    annotationVisibilityOptionEnabled: PropTypes.bool,
    itemsVisibilityOptionEnabled: PropTypes.bool,
    annotationAreVisible: PropTypes.bool,
    itemsAreVisible: PropTypes.bool,
    toggleAnnotationVisibility: PropTypes.func,
    toggleItemsVisibility: PropTypes.func,
  }),
};

PdfViewer.defaultProps = {
  classes: {},
  isFullViewer: false,
  onPdfLoadSuccess: () => null,
  pins: [],
  statuses: [],
  hasOfferLimitation: false,
  displayLoading: false,
  tools: {
    annotationVisibilityOptionEnabled: false,
    itemsVisibilityOptionEnabled: false,
    annotationAreVisible: false,
    itemsAreVisible: false,
    toggleAnnotationVisibility: () => null,
    toggleItemsVisibility: () => null,
  },
};

export default withStyles(combineStyles(styles, mapTheme))(PdfViewer);
