import React, { lazy, Suspense } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import PageWrapper from 'components/PageWrapper';

// Lazy load components
const WorkspaceInfoPage = lazy(() => import('../WorkspaceInfoPage/Loadable'));
const WorkspaceTemplatesPage = lazy(() => import('../WorkspaceTemplatesPage/Loadable'));
const WorkspaceMembersPage = lazy(() => import('../WorkspaceMembersPage/Loadable'));
const OrganizationDashboardPage = lazy(() => import('../OrganizationDashboardPage/Loadable'));
const FormPage = lazy(() => import('../FormPage/Loadable'));

function WorkspacePage({ match }) {
  const { params } = match;
  const { pageName } = params;

  const getLayoutByRoute = () => {
    switch (pageName) {
      case 'info':
        return <WorkspaceInfoPage />;
      case 'templates':
        return <WorkspaceTemplatesPage />;
      case 'members':
        return <WorkspaceMembersPage />;
      case 'dashboard':
        return <OrganizationDashboardPage />;
      case 'form':
        return <FormPage />;
      default:
        return <OrganizationDashboardPage />;
    }
  };

  return (
    <Suspense fallback="">
      <PageWrapper paddings>{getLayoutByRoute()}</PageWrapper>
    </Suspense>
  );
}

WorkspacePage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default compose(withRouter)(WorkspacePage);
