import React from 'react';

import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import contractCTA from 'assets/images/contractCTA.png';
import Button from 'components/Button';
import { Routing } from 'routing/routing';
import globalMessages from 'translations/messages/global-messages';

export const styles = theme => ({
  card: {
    borderRadius: 16,
    backgroundColor: theme.palette.warning.light,
    boxShadow: 'none',
    display: 'flex',
    flexShrink: 0,
  },
  cardCompact: {
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBlockEnd: '1rem',
    gap: '1rem',
  },
  cardBanner: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '3rem',
  },
  buttonCompact: {
    marginInline: 'auto',
  },
  buttonBanner: {
    marginInline: 'auto 1.5rem',
  },
  text: {
    fontFamily: 'Effra Medium, Roboto',
    whiteSpace: 'normal',
  },
  textCompact: {
    maxWidth: '90%',
    marginInline: 'auto',
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  textBanner: {
    color: theme.palette.text.dark,
    fontSize: '0.825rem',
  },
  secondaryTextCompact: {
    display: 'none',
  },
  secondaryTextBanner: {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
  },
  img: {
    width: '100%',
    maxWidth: '100%',
  },
  imgBanner: {
    maxHeight: 72,
  },
});

export function ContractPageCTA({ classes, intl, history, displayMode }) {
  const { organizationId } = useParams();

  const handleRedirect = () => {
    history.push(Routing.organizations.contract(organizationId));
  };

  return (
    <Card
      classes={{
        root: classnames(classes.card, {
          [classes.cardCompact]: displayMode === 'compact',
          [classes.cardBanner]: displayMode === 'banner',
        }),
      }}
    >
      <img
        src={contractCTA}
        alt=""
        className={classnames({
          [classes.img]: displayMode === 'compact',
          [classes.imgBanner]: displayMode === 'banner',
        })}
      />
      <div>
        <Typography
          className={classnames(classes.text, {
            [classes.textCompact]: displayMode === 'compact',
            [classes.textBanner]: displayMode === 'banner',
          })}
        >
          {intl.formatMessage(globalMessages.enjoy_all_advantages)}
        </Typography>
        <Typography
          className={classnames({
            [classes.secondaryTextCompact]: displayMode === 'compact',
            [classes.secondaryTextBanner]: displayMode === 'banner',
          })}
        >
          {intl.formatMessage(globalMessages.need_more_features_upgrade_team_needs)}
        </Typography>
      </div>
      <Button
        onClick={handleRedirect}
        className={classnames({
          [classes.buttonCompact]: displayMode === 'compact',
          [classes.buttonBanner]: displayMode === 'banner',
        })}
        variant="contained"
        size="small"
      >
        {intl.formatMessage(globalMessages.see_our_offers)}
      </Button>
    </Card>
  );
}

ContractPageCTA.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  history: PropTypes.object.isRequired,
  displayMode: PropTypes.string,
};

ContractPageCTA.defaultProps = {
  displayMode: 'compact',
};

export default compose(
  injectIntl,
  withRouter,
  withStyles(styles),
)(ContractPageCTA);
