import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import DateTimeFormat from 'components/DateTimeFormat';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import globalMessages from 'translations/messages/global-messages';
import { versionsState } from 'utils/constants';

export const styles = theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '& > span': {
      marginRight: theme.spacing(),
      display: 'flex',
      alignItems: 'center',
    },
  },
  date: {
    whiteSpace: 'nowrap',
  },
  state: {
    padding: `0 0.375rem`,
  },
  draft: {
    background: `${theme.palette.secondary.main}1A`,
    color: theme.palette.secondary.main,
    whiteSpace: 'nowrap',
  },
  published: {
    background: `${theme.palette.success.main}1A`,
    color: theme.palette.success.main,
    whiteSpace: 'nowrap',
  },
});

export const FormTemplate = ({ intl, classes, name, createdAt, state }) => {
  let message = state;
  if (state === versionsState.draft) {
    message = intl.formatMessage(globalMessages.draft);
  } else if (state === versionsState.publish) {
    message = intl.formatMessage(globalMessages.published);
  }

  return (
    <div>
      <TypographyEllipsis
        component="p"
        variant="subtitleBold"
        sx={{ marginRight: theme => theme.spacing(2) }}
      >
        {name}
      </TypographyEllipsis>
      <div className={classes.footer}>
        <Typography variant="hint" className={classes.date}>
          <DateTimeFormat year="numeric" month="short" day="numeric" date={`${createdAt}`} />
        </Typography>
        <Typography
          variant="hint"
          className={classnames(classes.state, {
            [classes.draft]: state === versionsState.draft,
            [classes.published]: state === versionsState.publish,
          })}
        >
          {message}
        </Typography>
      </div>
    </div>
  );
};

FormTemplate.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  state: PropTypes.string,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(FormTemplate);
