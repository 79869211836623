import {
  ADD_DOCUMENTS,
  ADD_FOLDER,
  SET_LOADING_FLAG,
  UPDATE_DOCUMENT,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_FAILED,
  UPLOAD_DOCUMENT_SUCCESS,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function addDocuments(documents, folderId, listRequestOption, callback) {
  return {
    type: ADD_DOCUMENTS,
    documents,
    folderId,
    listRequestOption,
    callback,
  };
}

export function addFolder(folder, listRequestOption, callback) {
  return {
    type: ADD_FOLDER,
    folder,
    listRequestOption,
    callback,
  };
}

export function updateDocument(document, listRequestOption, callback) {
  return {
    type: UPDATE_DOCUMENT,
    document,
    listRequestOption,
    callback,
  };
}

export function uploadDocument(item, callbackSuccess, callbackError) {
  return {
    type: UPLOAD_DOCUMENT,
    item,
    callbackSuccess,
    callbackError,
  };
}

export function uploadDocumentSuccess(item) {
  return {
    type: UPLOAD_DOCUMENT_SUCCESS,
    item,
  };
}

export function uploadDocumentFailed(item) {
  return {
    type: UPLOAD_DOCUMENT_FAILED,
    item,
  };
}
