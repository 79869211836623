import React from 'react';

import {
  FormControl,
  Input,
  InputAdornment,
  FormHelperText,
  IconButton,
  alpha,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Eye from 'components/SvgComponents/icons/Eye';
import EyeOff from 'components/SvgComponents/icons/EyeOff';
import { BLUE } from 'theme/colors';
import combineStyles from 'theme/combineStyles';
import formEngineStyle from 'theme/formEngine-theme';

const styles = theme => ({
  searchIcon: {
    fontSize: 30,
  },
  cssLabel: {
    color: 'green',
  },
  adornmentEnd: {
    marginRight: -theme.spacing(4),
  },
  cssInput: {
    fontSize: 16,
    width: '100%',
    padding: `0 ${theme.spacing(3)}`,
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.primary,
    borderRadius: 8,
    display: 'flex',
    border: `1px transparent solid`,

    '& input': {
      padding: `10px 0`,
    },

    '&$cssFocused': {
      borderRadius: 8,
      border: `1px ${theme.palette.info.main} solid`,
      boxShadow: `0px 0px 0px 3px ${alpha(BLUE, 0.2)}`,
    },

    '&:hover': {
      border: `1px solid ${theme.palette.info.main}`,
      '& $showOnlyOnFocus': {
        display: 'flex',
      },
    },
  },
  cssFocused: {},
});
export class InputPassword extends React.Component {
  state = { showPassword: false };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  inputText() {
    const {
      input,
      label,
      className,
      classes,
      disabled,
      meta,
      error,
      id,
      colorWhiteField,
      ...custom
    } = this.props;

    const hasError = !!meta.touched && (!!meta.error || !!meta.submitError);
    const helperText = meta.error || meta.submitError;

    return (
      <FormControl style={{ width: '100%' }}>
        <Input
          disableUnderline
          type={this.state.showPassword ? 'text' : 'password'}
          label={label}
          onChange={event => input.onChange(event)}
          onBlur={event => input.onBlur(event)}
          value={input.value}
          name="password"
          classes={{
            root: classes.cssInput,
            focused: classes.cssFocused,
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                className={classes.adornmentEnd}
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                {this.state.showPassword ? (
                  <Eye height="16" width="16" />
                ) : (
                  <EyeOff height="16" width="16" />
                )}
              </IconButton>
            </InputAdornment>
          }
          {...custom}
        />
        {hasError && <FormHelperText error>{helperText}</FormHelperText>}
      </FormControl>
    );
  }

  render() {
    return <>{this.inputText()}</>;
  }
}

InputPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  label: PropTypes.string,
  meta: PropTypes.object,
  margin: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  InputProps: PropTypes.object,
  disabled: PropTypes.bool,
  colorWhiteField: PropTypes.bool,
};
export default withStyles(combineStyles(formEngineStyle, styles))(InputPassword);
