import { createTheme, alpha as fade } from '@mui/material/styles';

import { colorTheme } from 'utils/constants';

import {
  BLACK_BLUE,
  BLUE,
  BLUE_DARK,
  BLUE_LIGHT,
  DARK_BLUE,
  DOCUMENT,
  FILE,
  FORM,
  GREEN,
  GREEN_LIGHT,
  GREY,
  GREY_BLUE,
  GREY_DARK,
  GREY_DARKER,
  GREY_LIGHT,
  OBSERVATION,
  ORANGE,
  ORANGE_LIGHT,
  PURPLE,
  PURPLE_DARK,
  PURPLE_LIGHT,
  RED,
  RED_DARK,
  RED_LIGHT,
  VIOLET_LIGHT,
  YELLOW,
  YELLOW_DARK,
  YELLOW_LIGHT,
} from './theme/colors';

const paletteDarkTheme = {
  text: {
    primary: GREY_LIGHT,
    secondary: GREY,
    tertiary: GREY_DARK,
    quaternary: GREY_DARKER,
    disabled: GREY_DARK,
    contrast: DARK_BLUE,
    info: BLUE,
    white: '#fff',
  },
  background: {
    tertiary: BLACK_BLUE,
    primary: DARK_BLUE,
    secondary: GREY_BLUE,
    dark: GREY_LIGHT,
  },
  icon: {
    contrast: '#000',
  },
};

const paperDarkTheme = {
  backgroundColor: BLACK_BLUE,
  backgroundImage: 'none',
};

const theme = (mode = colorTheme.light) =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: DARK_BLUE, // ne pas enlever pour MUI
      },
      secondary: {
        light: ORANGE_LIGHT,
        main: ORANGE,
      },
      error: {
        light: RED_LIGHT,
        main: RED,
        dark: RED_DARK,
      },
      warning: {
        light: YELLOW_LIGHT,
        main: YELLOW,
        dark: YELLOW_DARK,
      },
      info: {
        light: BLUE_LIGHT,
        main: BLUE,
        mainLight: `${BLUE}19`,
      },
      lab: {
        light: PURPLE_LIGHT,
        main: PURPLE,
        dark: PURPLE_DARK,
      },
      success: {
        light: GREEN_LIGHT,
        main: GREEN,
      },
      text: {
        white: '#fff',
        primary: GREY_DARK,
        secondary: GREY,
        tertiary: GREY_LIGHT,
        dark: DARK_BLUE,
        info: BLUE,
        main: ORANGE,
      },
      link: {
        active: DARK_BLUE,
        visited: VIOLET_LIGHT,
        hover: BLUE_DARK,
      },
      background: {
        white: '#fff',
        primary: GREY_LIGHT,
        secondary: GREY,
        tertiary: GREY_DARK,
        dark: DARK_BLUE,
      },
      custom: {
        grey_hover: {
          background: '#ECF2F8', // hover for grey light
        },
        login: {
          background: '#F8FBFF', // blue super light
        },
        slide2: {
          background: '#DFF3FF', // blue lighter
        },
        slide4: {
          background: '#FF9E0D', // orange
        },
        slide5: {
          background: '#80C6FD', // blue dim
        },
        tag: {
          background: `${BLUE}1A`, // blue super light,
          text: BLUE, // blue
        },
        button: {
          // only for button
          blue: {
            medium: '#DBEEFC',
            black: '#101825',
            dark: '#1B94EE',
            darker: '#0C83DC',
          },
          orange: {
            medium: '#FF7414',
            darker: '#d35e12',
          },
        },
        input: {
          selection: BLUE,
        },
        selection: ORANGE,
      },
      module: {
        observation: OBSERVATION,
        form: FORM,
        document: DOCUMENT,
        file: FILE,
      },
      divider: fade(GREY_DARK, 0.2),
      icon: {
        main: GREY_DARK,
        contrast: '#fff',
        action: ORANGE,
      },
      ...(mode === 'dark' ? paletteDarkTheme : {}),
    },
    spacing: 4,
    typography: {
      header: {
        fontSize: '1.75rem',
        fontFamily: 'Effra Medium, Roboto !important',
        fontWeight: 500,
        lineHeight: '34px',
        color: DARK_BLUE,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      h2: {
        fontSize: '1.50rem',
        fontFamily: 'Effra Medium, Roboto !important',
        fontWeight: 500,
        lineHeight: '1.5',
        color: DARK_BLUE,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      h3: {
        fontSize: '1.25rem',
        fontFamily: 'Effra Medium, Roboto !important',
        fontWeight: 500,
        lineHeight: '1.5',
        color: DARK_BLUE,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      h4: {
        fontSize: '1rem',
        fontFamily: 'Effra Medium, Roboto !important',
        fontWeight: 500,
        lineHeight: '1.5',
        color: DARK_BLUE,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      h5: {
        fontSize: '1rem',
        fontFamily: 'Effra Medium, Roboto !important',
        fontWeight: 500,
        lineHeight: '1.5',
        color: GREY_DARK,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      h6: {
        fontSize: '1rem',
        fontFamily: 'Effra Medium, Roboto !important',
        fontWeight: 500,
        lineHeight: '1.5',
        color: DARK_BLUE,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      body: {
        fontFamily: 'Effra, Roboto',
        fontSize: '1rem',
        fontWeight: 'normal',
        lineHeight: '1.5',
        color: DARK_BLUE,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      bodyLight: {
        fontFamily: 'Effra, Roboto',
        fontSize: '1rem',
        fontWeight: 'normal',
        lineHeight: '1.5',
        color: GREY_DARK,
        whiteSpace: 'pre-line',
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      subtitle: {
        fontFamily: 'Effra, Roboto',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        lineHeight: '1.2',
        color: DARK_BLUE,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      subtitleWhite: {
        fontFamily: 'Effra, Roboto',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        lineHeight: '1.2',
        white: '#fff',
      },
      subtitleLight: {
        fontFamily: 'Effra, Roboto',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        lineHeight: '1.2',
        color: GREY_DARK,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      subtitleBold: {
        fontSize: '0.875rem',
        fontFamily: 'Effra Medium, Roboto !important',
        fontWeight: 500,
        lineHeight: '1.2',
        color: DARK_BLUE,
      },
      subtitleLightBold: {
        fontSize: '0.875rem',
        fontFamily: 'Effra Medium, Roboto !important',
        lineHeight: '1.2',
        color: GREY_DARK,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      subtitleInfo: {
        fontFamily: 'Effra, Roboto',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        lineHeight: '1.2',
        color: BLUE,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      hint: {
        fontSize: '0.75rem',
        fontWeight: 'normal',
        lineHeight: '1.2',
        color: GREY_DARK,
        ...(mode === 'dark' ? { color: GREY_LIGHT } : {}),
      },
      link: {
        fontFamily: 'Effra, Roboto',
        fontSize: '1rem',
        lineHeight: '1.5',
      },
      smallLink: {
        fontFamily: 'Effra, Roboto',
        fontSize: '0.875rem',
        lineHeight: '1.2',
      },
      fontFamily: ['Effra', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(
        ',',
      ),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          color: 'secondary',
        },
        styleOverrides: {
          root: {
            '.MuiSwitch-track': {
              backgroundColor: GREY,
              opacity: 1,
            },
          },
          switchBase: {
            '&.Mui-checked': {
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: ORANGE,
                opacity: 1,
              },
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(23, 33, 52, 0.6)',
          },
          invisible: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 8,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: DARK_BLUE,
            textDecorationColor: DARK_BLUE,
            textDecoration: 'none',
            fontWeight: 'normal',
            padding: '0 0.5em',
            '&:hover:not(:disabled)': {
              textDecoration: 'underline',
            },
            '&:focus:not(:disabled)': {
              color: DARK_BLUE,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '8px 16px',
            color: DARK_BLUE,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
            ...(mode === 'dark' ? { backgroundColor: BLACK_BLUE } : {}),
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
            ...(mode === 'dark' ? paperDarkTheme : {}),
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: DARK_BLUE,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#fff',
            ...(mode === 'dark' ? { backgroundColor: DARK_BLUE } : {}),
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: DARK_BLUE,
            '&::placeholder': {
              color: GREY_DARK,
              opacity: 1,
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: GREY_DARK,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
            height: 32,
            color: GREY_DARK,
            borderRadius: 24,
            backgroundColor: 'transparent',
            border: `1px solid ${GREY}`,
            margin: `0 4px`,
          },
          colorSecondary: {
            border: `1px solid ${ORANGE}`,
          },
          deleteIcon: {
            width: 14,
            height: 14,
            borderRadius: '50%',
            marginLeft: 1,
            backgroundColor: DARK_BLUE,
            '&:hover': {
              color: GREY,
            },
            color: GREY_LIGHT,
            padding: 2,
          },
          deleteIconColorPrimary: {
            backgroundColor: GREY_LIGHT,
            color: DARK_BLUE,
            '&:hover': {
              color: GREY_DARK,
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          panelHeader: { display: 'none' },
          columnsPanel: { paddingBottom: '24px' },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: ORANGE,
            },
            '&.Mui-disabled': {
              color: ORANGE,
              opacity: 0.3,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: DARK_BLUE,
            color: 'white',
            fontSize: '0.875rem',
            padding: 8,
            whiteSpace: 'pre-wrap',
          },
        },
      },
    },
    scrollbar: {
      small: {
        overflowY: 'auto',
        scrollbarWidth: 'thin', // only works on firefox
        '&::-webkit-scrollbar': {
          width: 8,
          height: 8,
        },
        '&::-webkit-scrollbar-track': {
          marginTop: 1,
          marginBottom: 1,
          backgroundColor: 'background.primary',
          borderRadius: 6,
        },

        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'background.secondary',
          borderRadius: 6,
        },
      },
    },
  });
export default theme;
