import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function Plus(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || props.intl.formatMessage(globalMessages.zoom_in)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99971 3.6001C10.4415 3.6001 10.7997 3.95827 10.7997 4.4001V15.6001C10.7997 16.0419 10.4415 16.4001 9.99971 16.4001C9.55788 16.4001 9.19971 16.0419 9.19971 15.6001V4.4001C9.19971 3.95827 9.55788 3.6001 9.99971 3.6001Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6001 9.99971C3.6001 9.55788 3.95827 9.19971 4.4001 9.19971H15.6001C16.0419 9.19971 16.4001 9.55788 16.4001 9.99971C16.4001 10.4415 16.0419 10.7997 15.6001 10.7997H4.4001C3.95827 10.7997 3.6001 10.4415 3.6001 9.99971Z"
      />
    </svg>
  );
}
Plus.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
  size: PropTypes.number,
};

Plus.defaultProps = {
  title: '',
  size: 20,
};

export default injectIntl(Plus);
