import Analytics from 'utils/Analytics';
import { reportEntityType } from 'utils/constants';

import { customerIoTrackerWithParams } from '../../utils/CustomerIo';

export const AnalyticsError = (type, organizationId, projectId) => {
  if (type === reportEntityType.form) {
    Analytics.track('reports_forms_html_failed');
    customerIoTrackerWithParams('reports_forms_html_failed', organizationId, projectId);
  } else if (type === reportEntityType.observation) {
    Analytics.track('reports_observations_html_failed');
    customerIoTrackerWithParams('reports_observations_html_failed', organizationId, projectId);
  } else {
    Analytics.track('reports_global_export_failed');
    customerIoTrackerWithParams('reports_global_export_failed', organizationId, projectId);
  }
};
export const AnalyticsSuccess = (type, organizationId, projectId) => {
  if (type === reportEntityType.form) {
    Analytics.track('reports_forms_html_succeeded');
    customerIoTrackerWithParams('reports_forms_html_succeeded', organizationId, projectId);
  } else if (type === reportEntityType.observation) {
    Analytics.track('reports_observations_html_succeeded');
    customerIoTrackerWithParams('reports_observations_html_succeeded', organizationId, projectId);
  } else {
    Analytics.track('reports_global_export_succeeded');
    customerIoTrackerWithParams('reports_global_export_succeeded', organizationId, projectId);
  }
};
