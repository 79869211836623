import React, { Component } from 'react';

import { Typography, Link, CardMedia } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import SecuredImage, { quality } from 'components/SecuredImage';
import Attachment from 'components/SvgComponents/icons/Attachment';
import Finalcad from 'components/SvgComponents/icons/Finalcad';
import PlanPdf from 'components/SvgComponents/icons/PlanPdf';

class CardMediaImage extends Component {
  state = {
    error: false,
  };

  render() {
    const {
      src,
      componentProps,
      materialUIClasses = {},
      type,
      isPdf,
      fileName,
      fileSrc,
      title,
      size,
      component,
      placeholder,
    } = this.props;
    const { error } = this.state;

    const icon = type ? (
      <Attachment color="primary" fontSize="large" />
    ) : (
      <PlanPdf width={size || 16} height={size || 16} />
    );

    if (error) {
      return (
        <div
          className={classNames({
            [materialUIClasses.root]: materialUIClasses.root && !type,
          })}
        >
          {isPdf ? (
            <>
              {icon}
              <Typography variant="caption" color="textSecondary">
                <Link href={fileSrc || src} component="button">
                  {fileName}
                </Link>
              </Typography>
            </>
          ) : (
            <Finalcad {...componentProps} color="inherit" />
          )}
        </div>
      );
    }

    return (
      <SecuredImage placeholder={placeholder} imgQuality={quality.thumbnail} showFallback>
        <CardMedia
          title={title}
          src={src}
          component={component || 'img'}
          onError={this.handleImageError}
          {...componentProps}
        />
      </SecuredImage>
    );
  }

  handleImageError = () => {
    this.setState({
      error: true,
    });
  };
}

CardMediaImage.propTypes = {
  classes: PropTypes.object,
  intl: intlShape.isRequired,
  src: PropTypes.string,
  componentProps: PropTypes.object,
  materialUIClasses: PropTypes.object,
  type: PropTypes.string,
  isPdf: PropTypes.bool,
  fileName: PropTypes.string,
  size: PropTypes.number,
  component: PropTypes.string,
  title: PropTypes.string,
  fileSrc: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
};

export default injectIntl(CardMediaImage);
