import { delay } from 'redux-saga';
import { takeEvery, call, put, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import { quality as qualityEnum } from 'components/SecuredImage/index';
import localStorageUser from 'utils/localStorageUser';
import { request } from 'utils/request';

import { getImage, setImage } from './actions';
import { GET_IMAGE } from './constants';
import { selectImage } from './selectors';

const MAX_RETRY = 15;
let loading = {};
let retry = {};

export function* fetchImage({ url, quality = 'original', callback, showFallback, forceRefresh }) {
  const oktaToken = localStorageUser.getOktaTokenStorage();
  const imageData = yield select(selectImage(url, quality));
  const requestQuality =
    quality === qualityEnum.thumbnailOrOriginal ? qualityEnum.thumbnail : quality;

  if (imageData && !forceRefresh) {
    if (callback) yield call(callback, imageData);
  } else if (loading[url + requestQuality]) {
    yield delay(200);
    yield put(getImage(url, quality, callback, showFallback));
  } else {
    loading = { ...loading, [url + quality]: true };
    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
        Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(
      request,
      `${url}/url?quality=${requestQuality}&_=${new Date().getTime()}`,
      options,
      {
        errorRedirect: false,
        errorCustom: true,
      },
    );

    if (data && !data.message) {
      if (
        !showFallback &&
        quality === qualityEnum.thumbnail &&
        data.is_fallback &&
        (!retry[url + quality] || retry[url + quality] < MAX_RETRY)
      ) {
        yield delay(1500);
        loading = { ...loading, [url + quality]: false };
        retry = { ...retry, [url + quality]: (retry[url + quality] || 0) + 1 };
        yield put(getImage(url, quality, callback));
        return;
      }
      if (quality === qualityEnum.thumbnailOrOriginal && data.is_fallback) {
        yield put(getImage(url, qualityEnum.original, callback));
        return;
      }
      retry = { ...retry, [url + quality]: 0 };
      yield put(setImage(url, data.media_url, quality));
      if (callback) yield call(callback, data.media_url);
    }
    loading = { ...loading, [url + quality]: false };
  }
}

export default function* initSecuredImageSaga() {
  yield takeEvery(GET_IMAGE, fetchImage);
}
