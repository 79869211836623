import React from 'react';

import PropTypes from 'prop-types';

function One(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || '1'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9.04 11.68H7.6V6L5.84 6.56V5.36L8.88 4.24H9.04V11.68Z"
      />
    </svg>
  );
}
One.propTypes = {
  title: PropTypes.string,
};

One.defaultProps = {
  title: '',
};

export default One;
