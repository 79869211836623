import { fromJS } from 'immutable';
import uuid from 'uuid';

import {
  RESET_LOGIN_STATE,
  SET_LOADING_FLAG,
  SET_FORGOT_PASSWORD_VISIBLE,
  SHOW_RELOGIN_BUTTON,
} from './constants';

const initialStateJS = {
  loading: false,
  forgotPasswordVisible: false,
  shouldShowRelogin: false,
  oktaWidgetUuid: uuid.v4(),
};

export const initialState = fromJS(initialStateJS);
function loginReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FORGOT_PASSWORD_VISIBLE:
      return state.set('forgotPasswordVisible', action.forgotPasswordVisible);
    case SHOW_RELOGIN_BUTTON:
      return state.set('shouldShowRelogin', action.shouldShowRelogin);
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case RESET_LOGIN_STATE:
      return fromJS({ ...initialStateJS, oktaWidgetUuid: uuid.v4() });
    default:
      return state;
  }
}
export default loginReducer;
