import { limitationOffer } from 'utils/constants';

export const getLimitOfferForms = limits => {
  const { limit, count, percentage } = limits;
  const freePlan = limit === 100 && count < 100;
  const setReachLimitFreePlan = limit === 100 && count >= 100;
  if (freePlan) {
    return {
      reachPlan: false,
      translationKey: 'reached_forms_out_of_upgrade',
      limit,
      count,
      percentage,
      type: limitationOffer.free,
    };
  }
  if (setReachLimitFreePlan) {
    return {
      reachPlan: true,
      translationKey: 'reached_forms_limit',
      limit,
      count,
      percentage,
      type: limitationOffer.free,
    };
  }
  return null;
};
