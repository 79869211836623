import React from 'react';

const PartBlack = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" {...props}>
    <title>Part</title>
    <path d="M0.666504 4.66669C0.666504 3.56212 1.56193 2.66669 2.6665 2.66669H13.3332C14.4377 2.66669 15.3332 3.56212 15.3332 4.66669C15.3332 5.77126 14.4377 6.66669 13.3332 6.66669H2.6665C1.56193 6.66669 0.666504 5.77126 0.666504 4.66669Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6665 9.33333H3.33317C2.59679 9.33333 1.99984 9.93029 1.99984 10.6667C1.99984 11.403 2.59679 12 3.33317 12H12.6665C13.4029 12 13.9998 11.403 13.9998 10.6667C13.9998 9.93029 13.4029 9.33333 12.6665 9.33333ZM3.33317 8C1.86041 8 0.666504 9.19391 0.666504 10.6667C0.666504 12.1394 1.86041 13.3333 3.33317 13.3333H12.6665C14.1393 13.3333 15.3332 12.1394 15.3332 10.6667C15.3332 9.19391 14.1393 8 12.6665 8H3.33317Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10.6666C3 10.4826 3.14924 10.3333 3.33333 10.3333L12.6667 10.3333C12.8508 10.3333 13 10.4826 13 10.6666C13 10.8507 12.8508 11 12.6667 11L3.33333 11C3.14924 11 3 10.8507 3 10.6666Z"
    />
  </svg>
);

export default PartBlack;
