import React, { useEffect, useRef, useState } from 'react';

import { styled, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import TextCellSkeleton from 'components/SkeletonScreen/components/TextCellSkeleton';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import { Tile } from 'components/Tile/Tile';

// #region [styled]
const Div_Container = styled('div', { shouldForwardProp: prop => prop !== 'width' })(
  ({ theme, width }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    overflow: 'hidden',
    width,
  }),
);

const Div_TextsWrapper = styled('div')({
  overflow: 'hidden',
});

const Div_BottomLineWrapper = styled('div')(
  ({ theme, hasTwoElements }) =>
    hasTwoElements
      ? {
          marginTop: theme.spacing(0.5),
          lineHeight: '0.875rem',
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
        }
      : { marginTop: theme.spacing(0.5), lineHeight: '0.875rem' },
);
// #endregion

const TextCell = ({
  icon,
  mainText,
  mainTextVariant = 'subtitle',
  description,
  loading,
  tile,
  tileColor,
  tooltipTitle,
  columnWidth,
}) => {
  // #region [states]
  const [isOverflowing, setIsOverflowing] = useState(false);
  // #endregion

  // #region [refs]
  const mainTextRef = useRef();
  // #endregion

  // #region [effects]
  useEffect(
    () => {
      if (!mainText || !mainTextRef.current) return;
      setIsOverflowing(mainTextRef.current.scrollWidth > columnWidth);
    },
    [mainText, columnWidth, mainTextRef.current],
  );
  // #endregion

  // #region [render]
  return loading ? (
    <TextCellSkeleton hasDescription={!!description} />
  ) : (
    <Div_Container width={columnWidth}>
      {icon && mainText && icon}
      <Div_TextsWrapper>
        {(() => {
          const mainTextTypo = (
            <TypographyEllipsis noWrap component="p" variant={mainTextVariant} ref={mainTextRef}>
              {mainText || '-'}
            </TypographyEllipsis>
          );
          return tooltipTitle && isOverflowing ? (
            <Tooltip
              title={tooltipTitle}
              placement="bottom-start"
              disableInteractive
              key={`tooltip-textCell-${mainText}`}
            >
              {mainTextTypo}
            </Tooltip>
          ) : (
            mainTextTypo
          );
        })()}
        {(description || tile) && (
          <Div_BottomLineWrapper hasTwoElements={description && tile}>
            {description && (
              <TypographyEllipsis noWrap component="span" variant="subtitleLight">
                {description}
              </TypographyEllipsis>
            )}
            {tile && <Tile color={tileColor} label={tile} />}
          </Div_BottomLineWrapper>
        )}
      </Div_TextsWrapper>
    </Div_Container>
  );
};
// #endregion

TextCell.propTypes = {
  mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mainTextVariant: PropTypes.oneOf(['subtitle', 'subtitleLight', 'subtitleBold']),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  loading: PropTypes.bool,
  tile: PropTypes.string,
  tileColor: PropTypes.oneOf(['secondary', 'info', 'success', 'warning']),
  tooltipTitle: PropTypes.string,
  columnWidth: PropTypes.number,
};

export default TextCell;
