import React from 'react';

import PropTypes from 'prop-types';

export const Brush = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.16667 2.70834C5.54783 2.70834 4.95434 2.95417 4.51675 3.39175C4.07917 3.82934 3.83334 4.42283 3.83334 5.04167V9.04167H20.1667V2.70834H18.8333V6.70834C18.8333 7.26062 18.3856 7.70834 17.8333 7.70834C17.2811 7.70834 16.8333 7.26062 16.8333 6.70834V2.70834H6.16667ZM17.8333 0.708336H21.1667C21.719 0.708336 22.1667 1.15605 22.1667 1.70834V10.0417V12.5417C22.1667 13.2489 21.8857 13.9272 21.3856 14.4273C20.8855 14.9274 20.2072 15.2083 19.5 15.2083H14.8197L15.49 19.9002C15.4966 19.9471 15.5 19.9944 15.5 20.0417C15.5 20.9699 15.1313 21.8602 14.4749 22.5165C13.8185 23.1729 12.9283 23.5417 12 23.5417C11.0717 23.5417 10.1815 23.1729 9.52513 22.5165C8.86875 21.8602 8.5 20.9699 8.5 20.0417C8.5 19.9944 8.50336 19.9471 8.51005 19.9002L9.18033 15.2083H4.5C3.79276 15.2083 3.11448 14.9274 2.61438 14.4273C2.11429 13.9272 1.83334 13.2489 1.83334 12.5417V10.0417V5.04167C1.83334 3.8924 2.28988 2.7902 3.10254 1.97754C3.9152 1.16488 5.0174 0.708336 6.16667 0.708336H17.8333ZM20.1667 12.5417V11.0417H3.83334V12.5417C3.83334 12.7185 3.90357 12.8881 4.0286 13.0131C4.15362 13.1381 4.32319 13.2083 4.5 13.2083H9.375V14.2083L9.378 13.2083C9.63959 13.2091 9.89798 13.2659 10.1358 13.3748C10.3737 13.4837 10.5854 13.6423 10.7569 13.8398C10.9284 14.0373 11.0556 14.2693 11.1301 14.5201C11.2045 14.7709 11.2244 15.0347 11.1884 15.2938L11.1879 15.2977L11.1879 15.2977L10.5013 20.1038C10.5168 20.479 10.6727 20.8357 10.9393 21.1023C11.2206 21.3836 11.6022 21.5417 12 21.5417C12.3978 21.5417 12.7794 21.3836 13.0607 21.1023C13.3273 20.8357 13.4832 20.479 13.4987 20.1038L12.8121 15.2977L12.8116 15.2938C12.7756 15.0347 12.7955 14.7709 12.8699 14.5201C12.9444 14.2693 13.0716 14.0373 13.2431 13.8398C13.4146 13.6423 13.6263 13.4837 13.8642 13.3748C14.102 13.2659 14.3604 13.2091 14.622 13.2083L14.625 13.2083L19.5 13.2083C19.6768 13.2083 19.8464 13.1381 19.9714 13.0131C20.0964 12.888 20.1667 12.7185 20.1667 12.5417Z"
      fill="current"
    />
  </svg>
);

Brush.propTypes = {
  size: PropTypes.number,
};
