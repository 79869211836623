import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function Message(props) {
  return (
    <svg width="16" height="16" viewBox="0 -2 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{props.title || props.intl.formatMessage(globalMessages.contact_us)}</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.333 1.333A.667.667 0 0 0 1.666 2v9.057L3.195 9.53a.667.667 0 0 1 .471-.196h8a.666.666 0 0 0 .667-.666V2a.666.666 0 0 0-.667-.667H2.333ZM.919.586A2 2 0 0 1 2.333 0h9.333a2 2 0 0 1 2 2v6.667a2 2 0 0 1-2 2H3.942l-2.47 2.471a.667.667 0 0 1-1.139-.471V2A2 2 0 0 1 .919.586Z"
        fill={props.fill}
      />
    </svg>
  );
}
Message.propTypes = {
  title: PropTypes.string,
  fill: PropTypes.string,
  intl: intlShape.isRequired,
};

Message.defaultProps = {
  title: '',
  fill: '#172134',
};

export default injectIntl(Message);
