import { EXPORT_ITEM, SET_EXPORT_LOADING_FLAG, GET_EXPORT_FILE } from './constants';

export function exportItem(callback, isHTML, onlyPDF, exportFromForms) {
  return {
    type: EXPORT_ITEM,
    callback,
    isHTML,
    onlyPDF,
    exportFromForms,
  };
}

export function setLoadingExportFlag(loading) {
  return {
    type: SET_EXPORT_LOADING_FLAG,
    loading,
  };
}

export function getFile(params, key, isHTML, entityType) {
  return {
    type: GET_EXPORT_FILE,
    key,
    isHTML,
    params,
    entityType,
  };
}
