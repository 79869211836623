import React, { Fragment } from 'react';

import { ListItem, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { styles } from 'theme/nav-link-theme';
import { CustomNavLink } from 'utils/redirect-utils';

const permissionValid = (rightsList, permissions) =>
  permissions.filter(
    permission =>
      rightsList.filter(
        right => (right.action ? right.action === permission : right === permission),
      ).length > 0,
  ).length > 0;

const NavLinkListItem = ({ classes, list, name }) => (
  <>
    {list.map(({ component, key, permissions, rightsList, url, label, icon, other }) => {
      const uniqueKey = `${name}-${key}`;

      if (rightsList?.length && permissions) {
        if (!permissionValid(rightsList, permissions)) return null;
      }
      if (component) {
        if (rightsList?.length && permissions) {
          if (permissionValid(rightsList, permissions)) return component;
          return null;
        }
        return <Fragment key={uniqueKey}>{component}</Fragment>;
      }
      return (
        <ListItem
          key={uniqueKey}
          button
          classes={{
            root: classes.listItem,
          }}
          to={url}
          activeClassName={classes.active}
          component={CustomNavLink}
        >
          {icon || null}
          <Typography noWrap variant="body">
            {label}
          </Typography>
          {other}
        </ListItem>
      );
    })}
  </>
);

NavLinkListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.node,
      component: PropTypes.any,
      other: PropTypes.node,
    }),
  ),
  name: PropTypes.string,
};

NavLinkListItem.defaultProps = {
  list: [],
};

export default compose(withStyles(styles))(NavLinkListItem);
