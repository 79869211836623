import { setShouldReload } from 'containers/DashboardPage/reloadSlice';
import { SET_ROUTE_PARAMS } from 'containers/GlobalWrapper/constants';

export const navigationMiddleware = store => next => action => {
  if (action.type === SET_ROUTE_PARAMS) {
    const pageName = action?.params?.pageName || action?.params?.location;
    if (!pageName?.includes('dashboard')) {
      store.dispatch(setShouldReload(true));
    }
  }
  return next(action);
};
