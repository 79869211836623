import React, { useState } from 'react';

import { Popover, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import Button from 'components/Button';
import EmptyTable from 'components/EmptyTable';
import InfiniteTableSelfStanding, { Column } from 'components/InfiniteTableSelfStandingV3';
import TextCellSkeleton from 'components/SkeletonScreen/components/TextCellSkeleton';
import ChevronDrown from 'components/SvgComponents/icons/ChevronDown';
import combineStyles from 'theme/combineStyles';
import globalMessages from 'translations/messages/global-messages';
import { cache } from 'utils/hooks';
import { getTranslation } from 'utils/localization';

export const styles = theme => ({
  filterPaper: {
    width: 256,
    minHeight: 300,
    padding: theme.spacing(4),
    marginTop: theme.spacing(),
    borderRadius: 12,
  },
  filterWrap: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    minHeight: '40vh',
  },
  menuPaper: {
    marginTop: theme.spacing(),
    borderRadius: 12,
    width: '100%',
  },
  menuList: {
    padding: 0,
  },
  labelHeader: {
    width: '100%',
    fontSize: '1rem',
    color: theme.palette.text.dark,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    marginTop: theme.spacing(3),
  },
  button: {
    margin: `${theme.spacing(3)} 0`,
    justifyContent: 'space-between',
  },
});

function InfiniteTableSelect(props) {
  const {
    classes,
    intl,
    listRequestOption,
    customStyle,
    placeholder,
    disabled,
    label,
    noDataRenderer,
    sortDirection,
    addNewItemButton,
  } = props;
  const buttonEl = React.useRef(null);
  const [displayMenu, setDisplayMenu] = useState(false);

  const handleRowClick = ({ rowData }) => {
    const itemString = JSON.stringify(rowData, null, 2);
    const item = JSON.parse(itemString);
    setDisplayMenu(false);
    props.onChange(item);
  };

  const renderName = params => {
    const { rowData, columnData } = params;
    const stringRowData = JSON.stringify(rowData, null, 2);
    const parsedRowData = JSON.parse(stringRowData);

    if (!parsedRowData) {
      return null;
    }
    const { loading } = columnData || {};

    if (loading) {
      return <TextCellSkeleton />;
    }

    return (
      <Typography noWrap component="div">
        {getTranslation(parsedRowData.names?.translations, parsedRowData.name)}
      </Typography>
    );
  };

  const renderTable = () => {
    const width = buttonEl.current.clientWidth;
    const sortByName = sortDirection ? 'names' : null;
    return (
      <Popover
        id={`priority-popper-${placeholder}`}
        open={displayMenu}
        anchorEl={buttonEl.current}
        onClose={cache([setDisplayMenu], [!displayMenu])}
        classes={{
          paper: classes.menuPaper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width },
        }}
      >
        <div className={classes.filterPaper} style={customStyle}>
          <div className={classes.filterWrap}>
            <InfiniteTableSelfStanding
              headerHeight={0}
              rowHeight={40}
              listRequestOption={listRequestOption}
              sortBy={sortByName}
              isFiltering
              sortDirection={sortDirection}
              onHandleItems={listRequestOption.callBack}
              onRowClick={handleRowClick}
              noDataRenderer={() => (
                <>
                  {noDataRenderer ? (
                    noDataRenderer()
                  ) : (
                    <EmptyTable
                      className={classes.noResultWrap}
                      title={
                        <Typography
                          component="p"
                          variant="body"
                          className={classes.noResultFoundTypo}
                        >
                          {intl.formatMessage(globalMessages.no_result_found)}
                        </Typography>
                      }
                      subtitle={
                        <Typography className={classes.tryTypo}>
                          {intl.formatMessage(globalMessages.try_different_search_terms)}
                        </Typography>
                      }
                    />
                  )}
                </>
              )}
            >
              <Column
                width={80}
                minWidth={80}
                flexGrow={1}
                dataKey="names"
                cellRenderer={renderName}
                disableSort
              />
            </InfiniteTableSelfStanding>
          </div>
          {addNewItemButton}
        </div>
      </Popover>
    );
  };

  return (
    <>
      {label && <div className={classes.labelHeader}>{label}</div>}
      <Button
        disabled={disabled}
        ref={buttonEl}
        className={classes.button}
        fullWidth
        variant="action"
        aria-owns={`infiniteTableSelect-popper-${placeholder}`}
        aria-haspopup="true"
        size="large"
        onClick={cache([setDisplayMenu], [!displayMenu])}
        endIcon={<ChevronDrown />}
      >
        {placeholder}
      </Button>
      {displayMenu && renderTable()}
    </>
  );
}

InfiniteTableSelect.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  listRequestOption: PropTypes.shape({
    method: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    urlParams: PropTypes.object,
    excludeParamsAtSearch: PropTypes.arrayOf(PropTypes.string),
    namespace: PropTypes.string.isRequired,
    callBack: PropTypes.func,
  }).isRequired,
  customStyle: PropTypes.object,
  sortDirection: PropTypes.string,
  onChange: PropTypes.func,
  noDataRenderer: PropTypes.func,
  addNewItemButton: PropTypes.node,
};

InfiniteTableSelect.defaultProps = {
  classes: {},
  customStyle: {},
};

export default compose(
  injectIntl,
  withStyles(combineStyles(styles)),
)(InfiniteTableSelect);
