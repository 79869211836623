import React, { useCallback, useEffect, useState } from 'react';

import {
  ClickAwayListener,
  Divider,
  InputAdornment,
  Paper,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import Button from 'components/Button';
import CleanableTextField from 'components/CleanableTextField';
import { ListInfinite } from 'components/data-display/list-infinite';
import Add from 'components/SvgComponents/icons/Add';
import Search from 'components/SvgComponents/icons/Search';
import BreadcrumbRow from 'containers/BusinessOrganisation/components/BreadcrumbRow';
import { getAllOrganizations } from 'containers/GlobalWrapper/actions';
import { selectAllOrganizations } from 'containers/GlobalWrapper/selectors';
import globalMessages from 'translations/messages/global-messages';

const ORGANIZATION_LIMIT = 400;
const THRESHOLD_REFRESH = 300;
const DEBOUNCE_TIME = 500;

const Paper_Container = styled(Paper)(() => ({
  borderRadius: 12,
  boxShadow: '0px 6px 15px 0px rgba(23, 33, 52, 0.15)',
  overflow: 'hidden',
}));

const CleanableTextField_SearchOrganisation = styled(CleanableTextField)(({ theme }) => ({
  padding: theme.spacing(2),

  '& .MuiInputBase-input': {
    paddingLeft: 0,
  },
}));

const Div_NoResultWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
}));

const Div_ChangeOrganisations = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  width: '292px',
  display: 'flex',
  flexDirection: 'column',
  flex: 'auto',
  marginRight: theme.spacing(2),
}));

const Div_AddOrganisationButton = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,

  '& .MuiButton-root': {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    justifyContent: 'flex-start',
    height: '48px',
  },
}));

const BreadcrumbRow_OrganisationRowContainer = styled(BreadcrumbRow)(({ theme }) => ({
  '& .MuiAvatar-root': {
    height: '32px',
    width: '32px',
  },
  '& .MuiListItemText-root': {
    marginRight: `${theme.spacing(2)} !important`,
  },
}));

function ListSearchOrganisation(props) {
  const { intl, onClickOrganisation, openAddOrganisationDialog, onToggleMenu } = props;
  const initialOrganization = useSelector(selectAllOrganizations());
  const [organisations, setOrganization] = useState(initialOrganization);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [currentOffset, setCurrentOffset] = useState(0);

  const searchOrganisation = useCallback(
    debounce(({ searchText, offset, append }) => {
      dispatch(
        getAllOrganizations(
          data => {
            setOrganization(
              orgs => (append ? orgs.concat(data?.organizations || []) : data?.organizations || []),
            );
          },
          offset,
          searchText,
          ORGANIZATION_LIMIT,
          false,
        ),
      );
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    searchOrganisation({ searchText: currentSearchText, offset: currentOffset, append: false });
  }, []);

  const handleSearchChange = useCallback((value = '') => {
    setCurrentSearchText(value);
    setCurrentOffset(0);
    searchOrganisation({ searchText: value, offset: 0, append: false });
  }, []);

  const handleOrganizationClick = useCallback(
    organisation => {
      onClickOrganisation(organisation);
    },
    [onClickOrganisation],
  );

  const handleAddOrganizationClick = useCallback(
    () => {
      onToggleMenu();
      openAddOrganisationDialog();
    },
    [onToggleMenu, openAddOrganisationDialog],
  );

  const renderNoResult = () => (
    <Div_NoResultWrap>
      <Typography variant="h5">{intl.formatMessage(globalMessages.no_result_found)}</Typography>
    </Div_NoResultWrap>
  );

  return (
    <ClickAwayListener onClickAway={props.onToggleMenu}>
      <Paper_Container elevation={3}>
        <CleanableTextField_SearchOrganisation
          name="search"
          placeholder={intl.formatMessage(globalMessages.search_dot)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color={theme.palette.text.primary} />
              </InputAdornment>
            ),
          }}
          meta={{ touched: false }}
          input={{
            value: currentSearchText,
            onChange: e => handleSearchChange(e?.target?.value),
          }}
          fullWidth
          clearable
          autoFocus
        />
        <Divider variant="inset" component="div" sx={{ ml: 0 }} />
        <Div_ChangeOrganisations>
          {organisations && organisations.length === 0 ? (
            renderNoResult()
          ) : (
            <ListInfinite
              dataLength={organisations.length}
              dense
              sx={{ pr: '8px', py: 0, width: '292px', overflowY: 'scroll !important' }}
              height={217}
              onScroll={event => {
                const scrollOffset = event.target.scrollHeight - event.target.scrollTop;
                if (scrollOffset - event.target.clientHeight < THRESHOLD_REFRESH) {
                  const newOffset = organisations.length + ORGANIZATION_LIMIT;
                  setCurrentOffset(newOffset);
                  searchOrganisation({
                    searchText: currentSearchText,
                    offset: newOffset,
                    append: true,
                    ORGANIZATION_LIMIT,
                  });
                }
              }}
            >
              {organisations.map((organisation, index) => (
                <BreadcrumbRow_OrganisationRowContainer
                  entity={organisation}
                  key={organisation.id}
                  intl={intl}
                  showDivider={index < organisations.length - 1}
                  onClick={handleOrganizationClick}
                  disableTooltips
                  showMember
                />
              ))}
            </ListInfinite>
          )}
        </Div_ChangeOrganisations>
        <Div_AddOrganisationButton>
          <Button
            variant="text flat"
            startIcon={<Add />}
            size="large"
            onClick={handleAddOrganizationClick}
            fullWidth
          >
            {intl.formatMessage(globalMessages.create_organization)}
          </Button>
        </Div_AddOrganisationButton>
      </Paper_Container>
    </ClickAwayListener>
  );
}

ListSearchOrganisation.propTypes = {
  intl: intlShape.isRequired,
  onToggleMenu: PropTypes.func,
  onClickOrganisation: PropTypes.func,
  openAddOrganisationDialog: PropTypes.func,
};

ListSearchOrganisation.defaultProps = {};

export default compose(injectIntl)(ListSearchOrganisation);
