import React from 'react';

import { Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  sectionTreeToolbox: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTreeNoScrollBar: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 'auto',
    backgroundColor: theme.palette.background.primary,
  },
  sectionTreeContainer: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    overflowY: 'scroll',
    width: `calc(100% + 17px)`, // this hides the scrollbar 17px represents the scrollbar width in all browser
  },
  toolbarRoot: {
    padding: `0 ${theme.spacing(2)}`,
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 44,
    fontSize: '0.75rem',
  },
});

function SectionTreeWrap(props) {
  const { classes, toolBarOption, children, toolBarLabel } = props;
  return (
    <div className={classes.sectionTreeToolbox}>
      {toolBarLabel && (
        <Toolbar variant="regular" classes={{ root: classes.toolbarRoot }}>
          {toolBarLabel && <Typography>{toolBarLabel}</Typography>}
          {toolBarOption}
        </Toolbar>
      )}
      <div className={classes.sectionTreeNoScrollBar}>
        <div className={classes.sectionTreeContainer}>{children}</div>
      </div>
    </div>
  );
}

SectionTreeWrap.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  toolBarOption: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  toolBarLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default withStyles(styles)(SectionTreeWrap);
