import React, { useEffect, useState } from 'react';

import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import _, { isArray } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import Close from 'components/SvgComponents/icons/Close';
import { styles as tableStyle } from 'theme/table-style';
import globalMessages from 'translations/messages/global-messages';
import { cleanFiltersBeforeSave } from 'utils/filterUtils';
import localStorageUser from 'utils/localStorageUser';

import DefaultFilterItem from './DefaultFilterItem';

function FiltersWrap({
  classes,
  quickFilterClassName,
  disabled,
  intl,
  filterList,
  onChangeFilters,
  filterActions,
  modulesParams,
  hideResetFilter,
  localstorageFiltersKey,
  localStorageSetter,
  localStorageGetter,
  initialActiveFilters,
}) {
  const [activeFilters, setActiveFilters] = useState();
  const tempActiveFilters = { ...activeFilters };

  useEffect(
    () => {
      if (activeFilters) {
        if (localstorageFiltersKey) {
          const filters = cleanFiltersBeforeSave(activeFilters);

          localStorageUser[localStorageSetter || 'saveFilterByProjectAndModule']({
            filters: activeFilters,
            ...localstorageFiltersKey,
          });
          onChangeFilters(filters);
        } else {
          onChangeFilters(activeFilters);
        }
      }
    },
    [activeFilters],
  );

  useEffect(() => {
    if (localstorageFiltersKey && localstorageFiltersKey) {
      const storedFilters = localStorageUser[localStorageGetter || 'getFilterByProjectAndModule'](
        localstorageFiltersKey,
      );
      if (storedFilters && Object.keys(storedFilters).length > 0) {
        setActiveFilters(storedFilters);
      } else {
        setActiveFilters(initialActiveFilters);
      }
    }
  }, []);

  const handleFilterChange = filter => {
    Object.keys(filter).forEach(key => {
      delete tempActiveFilters[key];
    });
    setActiveFilters({
      ...tempActiveFilters,
      ...JSON.parse(JSON.stringify(filter)),
    });
  };

  const handleFilterChangeByType = filterPropertyName => filterValue => {
    let filterIndex = -1;
    if (tempActiveFilters[filterPropertyName]) {
      filterIndex = tempActiveFilters[filterPropertyName].findIndex(selectedFilter => {
        // match with id if exist
        if (filterValue.id && selectedFilter.id) {
          return filterValue.id === selectedFilter.id;
        }
        // match the object
        return _.isEqual(filterValue, selectedFilter);
      });
    }
    let tempFilters = [];
    if (filterIndex !== -1) {
      tempFilters = [
        ...tempActiveFilters[filterPropertyName].slice(0, filterIndex),
        ...tempActiveFilters[filterPropertyName].slice(
          filterIndex + 1,
          tempActiveFilters[filterPropertyName].length,
        ),
      ];
    } else if (isArray(filterValue)) {
      tempFilters = filterValue;
    } else {
      tempFilters = tempActiveFilters[filterPropertyName]
        ? [...tempActiveFilters[filterPropertyName], filterValue]
        : [filterValue];
    }

    if (!tempFilters.length) {
      delete tempActiveFilters[filterPropertyName];
    } else {
      tempActiveFilters[filterPropertyName] = tempFilters;
    }
    setActiveFilters(tempActiveFilters);
  };

  const handleClearFilter = filterPropertyName => {
    delete tempActiveFilters[filterPropertyName];
    setActiveFilters(tempActiveFilters);
  };

  const handleFilterClearByType = filterPropertyName => () => handleClearFilter(filterPropertyName);

  const handleClearFilters = () => {
    setActiveFilters({});
    localStorageUser.setTmpNavigationItems({});
  };

  if (!filterList?.length) {
    return null;
  }
  const isReportsFilter = filterList.findIndex(object => object.key === 'split_by') > -1;

  const isResetDisabled =
    hideResetFilter ||
    (isReportsFilter &&
      Object.keys(tempActiveFilters)?.length === 1 &&
      tempActiveFilters.split_by === true) ||
    (!isReportsFilter && Object.keys(tempActiveFilters)?.length === 0);

  return (
    <div className={classes.filterContainer}>
      <div
        className={classNames({
          [classes.quickFiltersWrap]: filterList?.length,
          [quickFilterClassName]: !!quickFilterClassName,
        })}
      >
        {!isResetDisabled && (
          <DefaultFilterItem
            classesChip={{
              root: classes.clearFilterBtn,
            }}
            filter={{
              id: 'clear',
              names: {
                translations: [
                  {
                    localization: 'en',
                    translation: intl.formatMessage(globalMessages.clear_filters),
                  },
                  {
                    localization: 'fr',
                    translation: intl.formatMessage(globalMessages.clear_filters),
                  },
                ],
              },
            }}
            onChange={handleClearFilters}
            icon={
              <Close
                width="16px"
                height="18px"
                className={classes.closeIcon}
                title={intl.formatMessage(globalMessages.clear_filters)}
              />
            }
          />
        )}
        {filterList.map(({ component, key, label, subLabel, trackAnalytics, props, ...filter }) => {
          const FILTER_COMPONENT = component || DefaultFilterItem;
          const { filterValue, filterPropertyName, filterPropertyArrayNames, active } = filter;

          if (filterPropertyArrayNames) {
            return (
              <FILTER_COMPONENT
                key={key || filterValue?.id}
                label={label}
                subLabel={subLabel}
                disabled={disabled}
                filterPropertyArrayNames={filterPropertyArrayNames}
                active={active}
                activeFilters={tempActiveFilters}
                filter={filterValue}
                onChange={handleFilterChange}
                onClear={handleClearFilter}
                modulesParams={modulesParams}
                trackAnalytics={trackAnalytics}
                {...props}
              />
            );
          }
          if (filterPropertyName) {
            return (
              <FILTER_COMPONENT
                key={key || filterValue?.id}
                label={label}
                subLabel={subLabel}
                disabled={disabled}
                activePropertyFilters={tempActiveFilters[filterPropertyName]}
                filter={filterValue}
                onChange={handleFilterChangeByType(filterPropertyName)}
                onClear={handleFilterClearByType(filterPropertyName)}
                modulesParams={modulesParams}
                trackAnalytics={trackAnalytics}
                {...props}
              />
            );
          }

          return null;
        })}
      </div>
      {filterActions}
    </div>
  );
}

FiltersWrap.propTypes = {
  classes: PropTypes.object,
  intl: intlShape.isRequired,
  disabled: PropTypes.bool,
  activeFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  quickFilterClassName: PropTypes.string,
  filterList: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      filterPropertyName: PropTypes.string,
      filterValue: PropTypes.object,
    }),
  ),
  onChangeFilters: PropTypes.func,
  filterActions: PropTypes.element,
  modulesParams: PropTypes.object,
  active: PropTypes.string,
  hideResetFilter: PropTypes.bool,
  localstorageFiltersKey: PropTypes.object,
  localStorageSetter: PropTypes.string,
  localStorageGetter: PropTypes.string,
  initialActiveFilters: PropTypes.object,
};

FiltersWrap.defaultProps = {
  classes: {},
  filterList: [],
  hideResetFilter: false,
};

export default injectIntl(withStyles(tableStyle)(FiltersWrap));
