import React from 'react';

import PropTypes from 'prop-types';

function OpenBook(props) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      {props.title && <title>{props.title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666992 2.50016C0.666992 2.13197 0.965469 1.8335 1.33366 1.8335H5.33366C6.21771 1.8335 7.06556 2.18469 7.69068 2.80981C8.3158 3.43493 8.66699 4.28277 8.66699 5.16683V14.5002C8.66699 14.8684 8.36852 15.1668 8.00032 15.1668C7.63213 15.1668 7.33366 14.8684 7.33366 14.5002C7.33366 14.1465 7.19318 13.8074 6.94313 13.5574C6.69309 13.3073 6.35395 13.1668 6.00033 13.1668H1.33366C0.965469 13.1668 0.666992 12.8684 0.666992 12.5002V2.50016ZM7.33366 12.1908V5.16683C7.33366 4.6364 7.12294 4.12769 6.74787 3.75262C6.3728 3.37754 5.86409 3.16683 5.33366 3.16683H2.00033V11.8335H6.00033C6.47248 11.8335 6.93172 11.9587 7.33366 12.1908Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.30932 2.80981C8.93444 2.18469 9.78229 1.8335 10.6663 1.8335H14.6663C15.0345 1.8335 15.333 2.13197 15.333 2.50016V12.5002C15.333 12.8684 15.0345 13.1668 14.6663 13.1668H9.99967C9.64605 13.1668 9.30691 13.3073 9.05687 13.5574C8.80682 13.8074 8.66634 14.1465 8.66634 14.5002C8.66634 14.8684 8.36786 15.1668 7.99967 15.1668C7.63148 15.1668 7.33301 14.8684 7.33301 14.5002V5.16683C7.33301 4.28277 7.6842 3.43493 8.30932 2.80981ZM8.66634 12.1908C9.06828 11.9587 9.52752 11.8335 9.99967 11.8335H13.9997V3.16683H10.6663C10.1359 3.16683 9.6272 3.37754 9.25213 3.75262C8.87705 4.12769 8.66634 4.6364 8.66634 5.16683V12.1908Z"
      />
    </svg>
  );
}
OpenBook.propTypes = {
  title: PropTypes.string,
};

OpenBook.defaultProps = {
  title: '',
};

export default OpenBook;
