import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

const SectionBlack = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.section)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3332 4.00002H2.6665C2.29831 4.00002 1.99984 4.2985 1.99984 4.66669C1.99984 5.03488 2.29831 5.33335 2.6665 5.33335H13.3332C13.7014 5.33335 13.9998 5.03488 13.9998 4.66669C13.9998 4.2985 13.7014 4.00002 13.3332 4.00002ZM2.6665 2.66669C1.56193 2.66669 0.666504 3.56212 0.666504 4.66669C0.666504 5.77126 1.56193 6.66669 2.6665 6.66669H13.3332C14.4377 6.66669 15.3332 5.77126 15.3332 4.66669C15.3332 3.56212 14.4377 2.66669 13.3332 2.66669H2.6665Z"
    />
    <path d="M0.666504 10.6667C0.666504 9.19391 1.86041 8 3.33317 8H12.6665C14.1393 8 15.3332 9.19391 15.3332 10.6667C15.3332 12.1394 14.1393 13.3333 12.6665 13.3333H3.33317C1.86041 13.3333 0.666504 12.1394 0.666504 10.6667Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10.6666C3 10.4826 3.14924 10.3333 3.33333 10.3333L12.6667 10.3333C12.8508 10.3333 13 10.4826 13 10.6666C13 10.8507 12.8508 11 12.6667 11L3.33333 11C3.14924 11 3 10.8507 3 10.6666Z"
    />
  </svg>
);

SectionBlack.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(SectionBlack);
