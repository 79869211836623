import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Button from 'components/Button';
import ArrowRight from 'components/SvgComponents/icons/ArrowRight';
import globalMessages from 'translations/messages/global-messages';

export function ButtonLimit(props) {
  const { intl, isLimit, history, redirectURL } = props;

  const handleRedirect = () => {
    history.push(redirectURL);
  };

  return (
    <Button
      variant={isLimit ? 'danger' : 'default'}
      onClick={handleRedirect}
      startIcon={<ArrowRight />}
    >
      {intl.formatMessage(globalMessages.upgrade)}
    </Button>
  );
}

ButtonLimit.propTypes = {
  intl: intlShape.isRequired,
  isLimit: PropTypes.bool,
  history: PropTypes.object.isRequired,
  redirectURL: PropTypes.string.isRequired,
};
ButtonLimit.defaultProps = {
  isLimit: false,
};
export default compose(
  withRouter,
  injectIntl,
)(ButtonLimit);
