import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

const Phase = ({ intl, height, size = 16, title, width, ...rest }) => (
  <svg
    viewBox="0 0 16 16"
    height={height || size}
    width={width || size}
    aria-hidden
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>{title || intl.formatMessage(globalMessages.phases)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.333 2.75a.583.583 0 0 0-.583.583v9.334c0 .322.261.583.583.583h9.334a.583.583 0 0 0 .583-.583V3.333a.583.583 0 0 0-.583-.583H3.333Zm-2.083.583c0-1.15.933-2.083 2.083-2.083h9.334c1.15 0 2.083.933 2.083 2.083v9.334c0 1.15-.933 2.083-2.083 2.083H3.333a2.083 2.083 0 0 1-2.083-2.083V3.333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.583 8a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75ZM5.25 10.667a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75ZM9.333 6.083h-5a.75.75 0 0 1 0-1.5h5a.75.75 0 1 1 0 1.5Z"
    />
  </svg>
);

Phase.propTypes = {
  intl: intlShape,
  height: PropTypes.number,
  size: PropTypes.number,
  stroke: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default injectIntl(Phase);
