import { fromJS } from 'immutable';

import globalMessages from 'translations/messages/global-messages';
import { documentType } from 'utils/constants';

export const filterSuccessfulUpload = (items, itemToUpload) => {
  const values = JSON.parse(JSON.stringify(items, null, 2));
  return fromJS(
    values.map(item => {
      if (item.id === itemToUpload.id) {
        return { ...itemToUpload, loading: false };
      }
      return item;
    }),
  );
};

export const filterFailedUpload = (items, itemToUpload) => {
  const values = JSON.parse(JSON.stringify(items, null, 2));
  return fromJS(
    values.map(item => {
      if (item.id === itemToUpload.id) {
        return {
          ...item,
          ...itemToUpload,
        };
      }
      return item;
    }),
  );
};

export const buildMessageSingularOrPlural = (
  intl,
  elements,
  keySingularDoc,
  keyPluralDoc,
  keySingularFolder,
  keyPluralFolder,
) => {
  if (elements.every(element => element.type === documentType.folder)) {
    return elements.length > 1
      ? intl.formatMessage(globalMessages[keyPluralFolder])
      : intl.formatMessage(globalMessages[keySingularFolder]);
  }
  return elements.length > 1
    ? intl.formatMessage(globalMessages[keyPluralDoc])
    : intl.formatMessage(globalMessages[keySingularDoc]);
};

export const getDialogTitleDelete = (elements, intl) =>
  buildMessageSingularOrPlural(
    intl,
    elements,
    'delete_document',
    'delete_documents',
    'delete_folder',
    'delete_folders',
  );

export const getDialogMessageDelete = (elements, intl) =>
  buildMessageSingularOrPlural(
    intl,
    elements,
    'definitely_delete_document',
    'definitely_delete_documents',
    'definitely_delete_folder',
    'definitely_delete_folders',
  );
