import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function EmojiHappy(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{props.title || props.intl.formatMessage(globalMessages.happy)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 11C7.67157 11 7 10.3284 7 9.5V9.4C7 8.57157 7.67157 7.9 8.5 7.9C9.32843 7.9 10 8.57157 10 9.4V9.5C10 10.3284 9.32843 11 8.5 11Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 11C14.6716 11 14 10.3284 14 9.5V9.4C14 8.57157 14.6716 7.9 15.5 7.9C16.3284 7.9 17 8.57157 17 9.4V9.5C17 10.3284 16.3284 11 15.5 11Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25185 14.7209C10.4432 16.4739 13.5569 16.4739 15.7482 14.7209L16.3753 14.2192C16.8066 13.8741 17.4359 13.9441 17.7809 14.3753C18.1259 14.8066 18.056 15.4359 17.6247 15.7809L16.9976 16.2826C14.0758 18.62 9.92421 18.62 7.00246 16.2826L6.37533 15.7809C5.94407 15.4359 5.87415 14.8066 6.21916 14.3753C6.56416 13.9441 7.19346 13.8741 7.62472 14.2192L8.25185 14.7209Z"
      />
    </svg>
  );
}
EmojiHappy.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

EmojiHappy.defaultProps = {
  title: '',
};

export default injectIntl(EmojiHappy);
