import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import Button from 'components/Button';

import styles from './banner.styles';

function Banner({ classes, icon, action, children, variant, noPadding, ...otherProps }) {
  const displayIcon = () => {
    if (icon)
      return (
        <CardMedia>
          {{
            ...icon,
            props: {
              height: '2em',
              width: '2em',
              'aria-hidden': true,
              ...icon?.props,
            },
          }}
        </CardMedia>
      );
    return null;
  };

  const displayAction = () => {
    if (action)
      return (
        <Button
          onClick={action.onClick ? action.onClick : () => {}}
          classes={{
            root: classnames(classes.rootButton),
          }}
          variant="flat"
          size="small"
        >
          {action?.label}
        </Button>
      );
    return null;
  };

  return (
    <Card
      classes={{
        root: classnames(classes.root, {
          [classes.warning]: variant === 'warning',
          [classes.info]: variant === 'info',
          [classes.infoLight]: variant === 'infoLight',
          [classes.rootPadding]: !noPadding,
        }),
      }}
      {...otherProps}
    >
      {displayIcon()}
      <CardContent
        classes={{
          root: classnames(classes.rootContent),
        }}
      >
        <Typography
          component="p"
          classes={{
            root: classnames(classes.rootTypography, {
              [classes.warningTypography]: variant === 'warning',
              [classes.infoTypography]: variant === 'info' || variant === 'infoLight',
            }),
          }}
        >
          {children}
        </Typography>
      </CardContent>
      {displayAction()}
    </Card>
  );
}

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['warning']),
  icon: PropTypes.node,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
};

Banner.defaultProps = {
  variant: 'warning',
  noPadding: false,
};

export default compose(withStyles(styles))(Banner);
