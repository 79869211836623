import { delay } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import Config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { checkParams } from 'utils/errors';
import request from 'utils/request';

import {
  setCommonObservation,
  setObservationStatus,
  setObservationPriority,
  setObservationPhase,
  setObservation,
} from './actions';
import {
  GET_COMMON_OBSERVATION,
  GET_OBSERVATION,
  GET_OBSERVATION_PHASE,
  GET_OBSERVATION_PRIORITY,
  GET_OBSERVATION_STATUS,
} from './constants';
import {
  selectCommonObservation,
  selectObservationPriority,
  selectObservationPhase,
  selectObservationStatus,
  selectObservation,
} from './selectors';

export function* getCommonObservation({ id, callback, params }) {
  const commonObservationData = yield select(selectCommonObservation(id));

  if (commonObservationData) {
    if (callback) yield call(callback, commonObservationData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/common-observations/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (!data?.message) {
      yield put(setCommonObservation(id, data));
      if (callback) yield call(callback, data);
    }
  }
}

export function* getObservationPriority({ id, callback, params }) {
  const priorityData = yield select(selectObservationPriority(id));

  if (priorityData) {
    if (callback) yield call(callback, priorityData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/observations-priorities/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (!data?.message) {
      yield put(setObservationPriority(id, data));
      if (callback) yield call(callback, data);
    }
  }
}

export function* getObservationPhase({ id, callback, params }) {
  const phaseData = yield select(selectObservationPhase(id));

  if (phaseData) {
    if (callback) yield call(callback, phaseData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/phases/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (!data?.message) {
      yield put(setObservationPhase(id, data));
      if (callback) yield call(callback, data);
    }
  }
}

export function* getObservationStatus({ id, callback, params }) {
  const statusData = yield select(selectObservationStatus(id));

  if (statusData) {
    if (callback) yield call(callback, statusData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/statuses/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (!data?.message) {
      yield put(setObservationStatus(id, data));
      if (callback) yield call(callback, data);
    }
  }
}

export function* getObservation({ id, callback, params }) {
  const observationData = yield select(selectObservation(id));
  const maxRetries = 2;
  const retryDelay = 1000;

  if (observationData) {
    if (callback) yield call(callback, observationData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/observations/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    for (let i = 0; i < maxRetries; i++) {
      try {
        const data = yield call(request, requestURL, options, {
          errorRedirect: false,
          errorCustom: true,
        });

        if (data && data?.status !== 403) {
          yield put(setObservation(id, data?.status === 403 ? { forbidden: true } : data));
          if (callback) yield call(callback, data?.status === 403 ? { forbidden: true } : data);
          break;
        }

        if (i < maxRetries - 1) {
          yield delay(retryDelay);
        }
      } catch (error) {
        if (error.status !== 403 || i === maxRetries - 1) {
          break;
        }
      }
    }
  }
}

export default function* initObservationsSaga() {
  yield takeEvery(GET_COMMON_OBSERVATION, getCommonObservation);
  yield takeEvery(GET_OBSERVATION_PRIORITY, getObservationPriority);
  yield takeEvery(GET_OBSERVATION_PHASE, getObservationPhase);
  yield takeEvery(GET_OBSERVATION_STATUS, getObservationStatus);
  yield takeEvery(GET_OBSERVATION, getObservation);
}
