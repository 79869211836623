import React from 'react';

import PropTypes from 'prop-types';

function AlertTriangle(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      {props.title && <title>{props.title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.528 2.2833C10.9773 2.03035 11.4842 1.89746 11.9998 1.89746C12.5154 1.89746 13.0223 2.03035 13.4716 2.2833C13.9209 2.53626 14.2974 2.90074 14.5648 3.34158L14.5677 3.34633L23.0376 17.4863L23.0458 17.5001C23.3077 17.9538 23.4463 18.4681 23.4478 18.9919C23.4493 19.5158 23.3135 20.0308 23.0541 20.4859C22.7947 20.941 22.4207 21.3203 21.9692 21.5859C21.5177 21.8516 21.0046 21.9944 20.4808 22.0001L20.4698 22.0003L3.51879 22.0002C2.99498 21.9944 2.48182 21.8516 2.03035 21.5859C1.57887 21.3203 1.20483 20.941 0.945426 20.4859C0.686022 20.0308 0.550303 19.5158 0.55177 18.9919C0.553236 18.4681 0.691839 17.9538 0.953786 17.5001L0.961909 17.4863L9.43191 3.34633L10.2898 3.86019L9.43478 3.34158C9.70218 2.90074 10.0787 2.53626 10.528 2.2833ZM11.146 4.37684L2.68246 18.506C2.59729 18.6558 2.55224 18.8251 2.55176 18.9975C2.55127 19.1721 2.59651 19.3438 2.68298 19.4955C2.76945 19.6472 2.89413 19.7736 3.04462 19.8622C3.1938 19.95 3.36317 19.9975 3.53617 20.0002H20.4634C20.6364 19.9975 20.8058 19.95 20.9549 19.8622C21.1054 19.7736 21.2301 19.6472 21.3166 19.4955C21.403 19.3438 21.4483 19.1721 21.4478 18.9975C21.4473 18.8251 21.4023 18.6559 21.3171 18.5061L12.8548 4.37883C12.8544 4.37817 12.854 4.37751 12.8536 4.37684C12.7645 4.23079 12.6395 4.11001 12.4904 4.02607C12.3406 3.94176 12.1716 3.89746 11.9998 3.89746C11.8279 3.89746 11.6589 3.94176 11.5092 4.02607C11.3601 4.11001 11.235 4.23079 11.146 4.37684Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z"
      />
    </svg>
  );
}
AlertTriangle.propTypes = {
  title: PropTypes.string,
};

AlertTriangle.defaultProps = {
  title: null,
};

export default AlertTriangle;
