import React from 'react';

import PropTypes from 'prop-types';

function Three(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || '3'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7.91875 11.7594C6 11.7594 5.35938 10.4 5.44062 9.6H6.88125C6.8 9.84062 7.04062 10.5594 8 10.5594C9.04062 10.5594 9.11875 9.68125 9.11875 9.51875C9.11875 8.4 8.08125 8.4 7.91875 8.4H7.11875V7.28125H7.91875C8.31875 7.28125 9.04062 7.2 9.04062 6.24062C9.04062 5.35938 8.24062 5.28125 8 5.28125C7.2 5.28125 7.04062 5.91875 7.04062 6.08125H5.51875C5.51875 4.64062 6.95938 4.08125 7.91875 4.08125C10.1594 4.08125 10.4 5.6 10.4 6.15938C10.4 7.2 9.44063 7.68125 9.28125 7.75938C9.68125 7.91875 10.4813 8.31875 10.4813 9.51875C10.5594 11.2812 9.04062 11.7594 7.91875 11.7594Z"
      />
    </svg>
  );
}
Three.propTypes = {
  title: PropTypes.string,
};

Three.defaultProps = {
  title: '',
};

export default Three;
