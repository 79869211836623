import { fromJS } from 'immutable';

import {
  SET_CURRENT_CLICK_POSITION,
  SET_MENU_DISPLAY,
  SET_MENU_REFERENCE,
  CLEAR_MENU,
} from './constants';

export const initialState = fromJS({
  position: {
    x: 0,
    y: 0,
  },
  display: false,
  component: {},
  reference: null,
});

function mouseReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CLICK_POSITION:
      return state.set('position', action.position);
    case SET_MENU_DISPLAY:
      return state.set('display', action.display);
    case CLEAR_MENU:
      return initialState;
    case SET_MENU_REFERENCE:
      return state.set('reference', action.reference);
    default:
      return state;
  }
}
export default mouseReducer;
