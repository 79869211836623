import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

const Email = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.email)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.68547 0.777878C8.05535 0.397457 6.34448 0.579664 4.83104 1.29487C3.31761 2.01008 2.09062 3.21622 1.34959 4.71719C0.608563 6.21815 0.397075 7.92565 0.749517 9.56204C1.10196 11.1984 1.9976 12.6675 3.29081 13.7303C4.58402 14.7932 6.19874 15.3873 7.87241 15.416C9.54608 15.4448 11.1803 14.9066 12.5093 13.8888C12.8381 13.637 12.9006 13.1662 12.6487 12.8374C12.3969 12.5085 11.9261 12.4461 11.5973 12.6979C10.5371 13.5098 9.23338 13.9392 7.8982 13.9163C6.56302 13.8933 5.27488 13.4194 4.24322 12.5715C3.21155 11.7236 2.49705 10.5517 2.21589 9.24622C1.93473 7.94078 2.10345 6.57862 2.6946 5.38122C3.28576 4.18383 4.26459 3.22162 5.47194 2.65106C6.67929 2.0805 8.04414 1.93515 9.34457 2.23863C10.645 2.54211 11.8045 3.27658 12.6346 4.32262C13.4647 5.36864 13.9165 6.6647 13.9166 8.00005L13.9166 8.0001L13.9166 8.66672C13.9166 8.99824 13.7849 9.31619 13.5505 9.55061C13.3161 9.78503 12.9981 9.91672 12.6666 9.91672C12.3351 9.91672 12.0171 9.78503 11.7827 9.55061C11.5483 9.31619 11.4166 8.99824 11.4166 8.66672V5.33339C11.4166 4.91917 11.0808 4.58339 10.6666 4.58339C10.3044 4.58339 10.0021 4.84019 9.93195 5.18167C9.38242 4.80423 8.71696 4.58334 7.99992 4.58334C6.11295 4.58334 4.58325 6.11304 4.58325 8.00001C4.58325 9.88698 6.11295 11.4167 7.99992 11.4167C8.97993 11.4167 9.86357 11.0041 10.4866 10.3431C10.5588 10.437 10.6374 10.5266 10.722 10.6113C11.2378 11.127 11.9372 11.4167 12.6666 11.4167C13.3959 11.4167 14.0954 11.127 14.6111 10.6113C15.1269 10.0955 15.4166 9.39607 15.4166 8.66672L15.4166 8.00005L15.4166 8.00001C15.4165 6.32609 14.8501 4.70143 13.8096 3.3902C12.7691 2.07898 11.3156 1.1583 9.68547 0.777878ZM6.08325 8.00001C6.08325 6.94146 6.94137 6.08334 7.99992 6.08334C9.05846 6.08334 9.91659 6.94146 9.91659 8.00001C9.91659 9.05856 9.05846 9.91668 7.99992 9.91668C6.94137 9.91668 6.08325 9.05856 6.08325 8.00001Z"
    />
  </svg>
);

Email.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(Email);
