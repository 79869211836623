import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import config from 'config/config';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import { setProject } from 'containers/GlobalWrapper/actions';
import { selectLoggedUserData, selectRouteParams } from 'containers/GlobalWrapper/selectors';
import globalMessages from 'translations/messages/global-messages';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { uploadMedia } from 'utils/chunkUploader';
import { authorizations, getFormsApiVersion } from 'utils/constants';
import { checkParams } from 'utils/errors/check-params';
import localStorageUser from 'utils/localStorageUser';
import request from 'utils/request';

import {
  setActivities,
  setActivitiesLoadingFlag,
  setLoadingFlag,
  setModuleSuggestions,
} from './actions';
import {
  ATTACH_PROJECT_MEDIA,
  DETACH_PROJECT_MEDIA,
  FETCH_PROJECT,
  GET_ACTIVITIES,
  GET_FORM,
  GET_FORM_TEMPLATE,
  GET_MODULE_SUGGESTIONS,
  GET_OBSERVATION,
  GET_PLAN,
  LIMIT_ACTIVITIES,
  UPDATE_PROJECT_STANDARD_INFO,
} from './constants';

export function* fetchProject({ callBack403, params }) {
  yield put(setLoadingFlag(true));

  checkParams({ params, keys: ['idData'] });
  const userInfo = yield select(selectLoggedUserData());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, { errorCustom: true });
  if (data && !data.message) {
    const isMember = data?.project_roles.some(role =>
      role?.user_ids.some(id => id === userInfo?.user_id),
    );
    const project = { ...data, current_user_in_project: isMember };
    yield put(setProject(project));
    localStorageUser.addRecentlyViewedProjects(project);
    yield put(setLoadingFlag(false));
  } else if (data && data?.status === 403) {
    yield put(setLoadingFlag(false));
    yield call(callBack403);
  }
}

export function* updateProjectStandardInfo({ projectInfo, callback }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());
  const userInfo = yield select(selectLoggedUserData());
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }`;

  const options = {
    method: 'PUT',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(projectInfo),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    const isMember = data?.project_roles.some(role =>
      role?.user_ids.some(id => id === userInfo?.user_id),
    );
    const project = { ...data, current_user_in_project: isMember };
    yield put(setProject(project));
    localStorageUser.addRecentlyViewedProjects(project);
    if (callback) {
      yield call(callback, data);
    }
  }
  yield put(setLoadingFlag(false));
}

export function* detachProjectMedia({ projectMediaId, callback }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/detach-media`;

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      detach_media_resource_ids: [projectMediaId],
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setProject(data));
    localStorageUser.addRecentlyViewedProjects(data);
    if (callback) {
      yield call(callback, data);
    }
  }
  yield put(setLoadingFlag(false));
}

export function* attachProjectMedia({ projectMedia, callback }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());

  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/`;

  const dataUpload = yield call(uploadMedia, projectMedia, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });

  if (!dataUpload?.id) {
    yield put(
      setGlobalError({
        customErrorMessageIntl: globalMessages.error_in_upload_import,
      }),
    );
    yield put(setLoadingFlag(false));
    return;
  }

  const requestAttachProjectURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v2.1/projects/${route.idData}/attach-media`;

  const payload = {
    media_resource: {
      root_media_resource_id: dataUpload.id,
      media_resource_id: dataUpload.id,
      media_resource_label: dataUpload.file_name,
    },
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const data = yield call(request, requestAttachProjectURL, options);

  if (data && !data.message) {
    yield put(setProject(data));
    localStorageUser.addRecentlyViewedProjects(data);
    if (callback) {
      yield call(callback, data);
    }
  }
  yield put(setLoadingFlag(false));
}

export function* fetchObservation({ id, callback, callbackError, params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/observations/${id}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
    errorCustom: true,
  });

  if (data && data?.message) {
    if (data.response?.code === 'NOT_AUTHORIZED' || data.response?.code === 'ENTITY_NOT_FOUND') {
      yield call(callbackError, data);
    } else {
      yield put(setGlobalError(data));
    }
  } else if (data && !data.message) {
    yield call(callback, data);
  }
}

export function* fetchFormTemplate({ id, callback, callbackError, params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsApiVersion()}/projects/${params.idData}/forms/${id}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
  });

  if (data && data?.message) {
    if (
      data.response?.code === 'NOT_AUTHORIZED' &&
      data.response?.errors?.action === authorizations.organization.read
    ) {
      yield call(callbackError, data);
    } else {
      yield put(setGlobalError(data));
    }
  } else {
    yield call(callback, data);
  }
}

export function* fetchForm({ id, callback, callbackError, params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsApiVersion()}/projects/${params.idData}/form-instances/${id}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
    errorCustom: true,
  });
  if (data && data?.message) {
    if (data.response?.code === 'NOT_AUTHORIZED' || data.response?.code === 'ENTITY_NOT_FOUND') {
      yield call(callbackError, data);
    } else {
      yield put(setGlobalError(data));
    }
  } else {
    yield call(callback, data);
  }
}

export function* fetchPlan({ id, callback, callbackError, params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/plans/${id}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
  });

  if (data && data?.message) {
    if (
      data.response?.code === 'NOT_AUTHORIZED' &&
      data.response?.errors?.action === authorizations.organization.read
    ) {
      yield call(callbackError, data);
    } else {
      yield put(setGlobalError(data));
    }
  } else {
    yield call(callback, data);
  }
}

export function* fetchActivities({ idData }) {
  yield put(setActivitiesLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v2.0/projects/${idData}/activities?limit=${LIMIT_ACTIVITIES}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message && data.activities) {
    yield put(setActivities(idData, data.activities));
  }
  yield put(setActivitiesLoadingFlag(false));
}

export function* fetchModuleSuggestions({ featureFlags, workspaceId }) {
  const route = yield select(selectRouteParams());

  let requestURL = '';
  if (featureFlags?.templates_module_management) {
    requestURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/v1.0/organizations/${route.organizationId}/modules/suggestions`;

    if (workspaceId) {
      requestURL = `${requestURL}?workspaceId=${workspaceId}`;
    }
  } else {
    requestURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/modules/suggestions`;
  }

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options);

  if (data?.message) {
    yield put(setGlobalError(data));
  } else if (data?.suggestions) {
    yield put(setModuleSuggestions(data.suggestions));
  }
}

export default function* initHomePageSaga() {
  yield takeLatest(FETCH_PROJECT, fetchProject);
  yield takeLatest(UPDATE_PROJECT_STANDARD_INFO, updateProjectStandardInfo);
  yield takeLatest(DETACH_PROJECT_MEDIA, detachProjectMedia);
  yield takeLatest(ATTACH_PROJECT_MEDIA, attachProjectMedia);
  yield takeLatest(GET_ACTIVITIES, fetchActivities);
  yield takeEvery(GET_OBSERVATION, fetchObservation);
  yield takeEvery(GET_PLAN, fetchPlan);
  yield takeEvery(GET_FORM, fetchForm);
  yield takeEvery(GET_FORM_TEMPLATE, fetchFormTemplate);
  yield takeEvery(GET_MODULE_SUGGESTIONS, fetchModuleSuggestions);
}
