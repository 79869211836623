import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Placeholder from './Placeholder';

const styles = () => ({
  alignBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    height: '2em',
  },
});

const DetailCellSkeleton = props => {
  const { classes } = props;
  return (
    <div className={classes.alignBetween}>
      <Placeholder height="10px" width="40%" />
      <Placeholder height="8px" width="30%" />
    </div>
  );
};

DetailCellSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailCellSkeleton);
