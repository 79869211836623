import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function Download({ intl, title = '', color, showTitle = true, ...props }) {
  return (
    <svg width="16" height="16" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      {showTitle && <title>{title || intl.formatMessage(globalMessages.download)}</title>}
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 6.9375C1.81066 6.9375 2.0625 7.18934 2.0625 7.5V9.5C2.0625 9.61603 2.10859 9.72731 2.19064 9.80936C2.27269 9.89141 2.38397 9.9375 2.5 9.9375H9.5C9.61603 9.9375 9.72731 9.89141 9.80936 9.80936C9.89141 9.72731 9.9375 9.61603 9.9375 9.5V7.5C9.9375 7.18934 10.1893 6.9375 10.5 6.9375C10.8107 6.9375 11.0625 7.18934 11.0625 7.5V9.5C11.0625 9.9144 10.8979 10.3118 10.6049 10.6049C10.3118 10.8979 9.9144 11.0625 9.5 11.0625H2.5C2.0856 11.0625 1.68817 10.8979 1.39515 10.6049C1.10212 10.3118 0.9375 9.9144 0.9375 9.5V7.5C0.9375 7.18934 1.18934 6.9375 1.5 6.9375Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.10225 4.60225C3.32192 4.38258 3.67808 4.38258 3.89775 4.60225L6 6.7045L8.10225 4.60225C8.32192 4.38258 8.67808 4.38258 8.89775 4.60225C9.11742 4.82192 9.11742 5.17808 8.89775 5.39775L6.39775 7.89775C6.29226 8.00324 6.14918 8.0625 6 8.0625C5.85082 8.0625 5.70774 8.00324 5.60225 7.89775L3.10225 5.39775C2.88258 5.17808 2.88258 4.82192 3.10225 4.60225Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0.9375C6.31066 0.9375 6.5625 1.18934 6.5625 1.5V7.5C6.5625 7.81066 6.31066 8.0625 6 8.0625C5.68934 8.0625 5.4375 7.81066 5.4375 7.5V1.5C5.4375 1.18934 5.68934 0.9375 6 0.9375Z"
      />
    </svg>
  );
}
Download.propTypes = {
  intl: intlShape.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  showTitle: PropTypes.bool,
};

export default injectIntl(Download);
