import React from 'react';

import PropTypes from 'prop-types';

function ArrowBack(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <title>{props.title}</title>
      <path
        fillRule="evenodd"
        d="M13.1 8.932H5.073L6.5 10.41c.351.363.351.954 0 1.317-.35.364-.921.364-1.272 0L2.264 8.66c-.352-.365-.352-.954 0-1.318l2.964-3.068c.35-.364.921-.364 1.272 0 .351.363.351.954 0 1.317L5.073 7.068H13.1c.497 0 .9.418.9.932s-.403.932-.9.932z"
      />
    </svg>
  );
}

ArrowBack.propTypes = {
  title: PropTypes.string,
};
export default ArrowBack;
