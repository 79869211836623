import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

const ThumbView = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.thumb_view)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33325 1.99992C1.33325 1.63173 1.63173 1.33325 1.99992 1.33325H6.66659C7.03478 1.33325 7.33325 1.63173 7.33325 1.99992V6.66659C7.33325 7.03478 7.03478 7.33325 6.66659 7.33325H1.99992C1.63173 7.33325 1.33325 7.03478 1.33325 6.66659V1.99992ZM2.66659 2.66659V5.99992H5.99992V2.66659H2.66659Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66675 1.99992C8.66675 1.63173 8.96522 1.33325 9.33341 1.33325H14.0001C14.3683 1.33325 14.6667 1.63173 14.6667 1.99992V6.66659C14.6667 7.03478 14.3683 7.33325 14.0001 7.33325H9.33341C8.96522 7.33325 8.66675 7.03478 8.66675 6.66659V1.99992ZM10.0001 2.66659V5.99992H13.3334V2.66659H10.0001Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66675 9.33341C8.66675 8.96522 8.96522 8.66675 9.33341 8.66675H14.0001C14.3683 8.66675 14.6667 8.96522 14.6667 9.33341V14.0001C14.6667 14.3683 14.3683 14.6667 14.0001 14.6667H9.33341C8.96522 14.6667 8.66675 14.3683 8.66675 14.0001V9.33341ZM10.0001 10.0001V13.3334H13.3334V10.0001H10.0001Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33325 9.33341C1.33325 8.96522 1.63173 8.66675 1.99992 8.66675H6.66659C7.03478 8.66675 7.33325 8.96522 7.33325 9.33341V14.0001C7.33325 14.3683 7.03478 14.6667 6.66659 14.6667H1.99992C1.63173 14.6667 1.33325 14.3683 1.33325 14.0001V9.33341ZM2.66659 10.0001V13.3334H5.99992V10.0001H2.66659Z"
    />
  </svg>
);

ThumbView.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(ThumbView);
