const HEADERS_PNG = '89504e47';
const HEADERS_JPG = 'ffd8';
const HEADERS_PDF = '25504446';

// See https://en.wikipedia.org/wiki/List_of_file_signatures for explanations
export async function getFileTypeFromBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = e => {
      const arr = new Uint8Array(e.target.result);
      let header = '';
      for (let i = 0; i < arr.length; i += 1) {
        header += arr[i].toString(16).padStart(2, '0');
      }

      if (header.startsWith(HEADERS_PNG)) {
        resolve('image/png');
      } else if (header.startsWith(HEADERS_JPG)) {
        resolve('image/jpeg');
      } else if (header.startsWith(HEADERS_PDF)) {
        resolve('application/pdf');
      } else {
        reject('unknown file type');
      }
    };

    reader.onerror = () => {
      reject('Error reading blob');
    };

    reader.readAsArrayBuffer(blob.slice(0, 8));
  });
}
