import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectWorkspaceSwitcherPageState = state => state.workspaceSwitcher || initialState;

const selectLoadingFlag = () =>
  createSelector(selectWorkspaceSwitcherPageState, workspaceSwitcherState =>
    workspaceSwitcherState.get('loading'),
  );

const selectUserWorkspaces = () =>
  createSelector(selectWorkspaceSwitcherPageState, workspaceSwitcherState =>
    workspaceSwitcherState.get('user_workspaces').toJS(),
  );

const selectUserWorkspacesByParent = () =>
  createSelector(selectWorkspaceSwitcherPageState, workspaceSwitcherState => {
    const selected = workspaceSwitcherState.get('selected_workspace').toJS();
    return workspaceSwitcherState
      .get('user_workspaces')
      .toJS()
      .filter(item => item.parentId === selected?.id);
  });

const selectSelectedWorkspace = () =>
  createSelector(selectWorkspaceSwitcherPageState, workspaceSwitcherState =>
    workspaceSwitcherState.get('selected_workspace').toJS(),
  );

const selectIsSearch = () =>
  createSelector(selectWorkspaceSwitcherPageState, workspaceSwitcherState =>
    workspaceSwitcherState.get('isSearch'),
  );

export {
  selectLoadingFlag,
  selectUserWorkspaces,
  selectSelectedWorkspace,
  selectUserWorkspacesByParent,
  selectIsSearch,
};
