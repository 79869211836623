import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectVerifyCode = state => state.verifyCodePage || initialState;

const selectLoadingFlag = () =>
  createSelector(selectVerifyCode, dataState => dataState.get('loading'));

export { selectLoadingFlag };
