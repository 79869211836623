import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

const Layers = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.layers)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.70169 0.737008C7.88938 0.643165 8.11029 0.643165 8.29798 0.737008L14.9646 4.07034C15.1905 4.18327 15.3332 4.41411 15.3332 4.66663C15.3332 4.91914 15.1905 5.14998 14.9646 5.26291L8.29798 8.59624C8.11029 8.69009 7.88938 8.69009 7.70169 8.59624L1.03503 5.26291C0.809172 5.14998 0.666504 4.91914 0.666504 4.66663C0.666504 4.41411 0.809172 4.18327 1.03503 4.07034L7.70169 0.737008ZM2.82388 4.66663L7.99984 7.2546L13.1758 4.66663L7.99984 2.07865L2.82388 4.66663Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.737032 11.0352C0.901691 10.7059 1.30214 10.5724 1.63146 10.737L7.99998 13.9213L14.3685 10.737C14.6978 10.5724 15.0983 10.7059 15.2629 11.0352C15.4276 11.3645 15.2941 11.7649 14.9648 11.9296L8.29813 15.2629C8.11044 15.3568 7.88953 15.3568 7.70184 15.2629L1.03517 11.9296C0.705855 11.7649 0.572372 11.3645 0.737032 11.0352Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.737032 7.7018C0.901691 7.37248 1.30214 7.239 1.63146 7.40366L7.99998 10.5879L14.3685 7.40366C14.6978 7.239 15.0983 7.37248 15.2629 7.7018C15.4276 8.03112 15.2941 8.43157 14.9648 8.59623L8.29813 11.9296C8.11044 12.0234 7.88953 12.0234 7.70184 11.9296L1.03517 8.59623C0.705855 8.43157 0.572372 8.03112 0.737032 7.7018Z"
    />
  </svg>
);

Layers.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default injectIntl(Layers);
