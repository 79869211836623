import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function Add(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || props.intl.formatMessage(globalMessages.add)}</title>
      <path
        fill={props.tint}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1111 7.11111H8.88889V0.888889C8.88889 0.398222 8.49067 0 8 0C7.50933 0 7.11111 0.398222 7.11111 0.888889V7.11111H0.888889C0.398222 7.11111 0 7.50933 0 8C0 8.49067 0.398222 8.88889 0.888889 8.88889H7.11111V15.1111C7.11111 15.6018 7.50933 16 8 16C8.49067 16 8.88889 15.6018 8.88889 15.1111V8.88889H15.1111C15.6018 8.88889 16 8.49067 16 8C16 7.50933 15.6018 7.11111 15.1111 7.11111Z"
      />
    </svg>
  );
}
Add.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
  size: PropTypes.number,
  tint: PropTypes.string,
};

Add.defaultProps = {
  title: '',
  size: 16,
};

export default injectIntl(Add);
