import { limitationOffer } from 'utils/constants';

export const getLimits = (limitObservation, limitForms) => {
  const setReachLimitFreePlanObservation =
    limitObservation.percentage >= 80 &&
    limitObservation.limit === 25 &&
    limitObservation.count < 25;
  const setReachLimitPremiumPlanObservation =
    limitObservation.percentage >= 80 &&
    limitObservation.limit === 130 &&
    limitObservation.count < 130;
  const setReachLimitFreePlanForms =
    limitForms.percentage >= 80 && limitForms.limit === 15 && limitForms.count < 15;
  const setReachLimitPremiumPlanForms =
    limitForms.percentage >= 80 && limitForms.limit === 80 && limitForms.count < 80;
  const isLimitReachPremiumForms = limitForms.limit === 80 && limitForms.count >= 80;
  const isLimitReachFreeForms = limitForms.limit === 15 && limitForms.count >= 15;
  const isLimitReachPremiumObservation =
    limitObservation.limit === 130 && limitObservation.count >= 130;
  const isLimitReachFreeObservation = limitObservation.limit === 25 && limitObservation.count >= 25;
  if (setReachLimitFreePlanObservation || setReachLimitFreePlanForms) {
    return {
      translationKey: 'about_to_reach_limit',
      type: limitationOffer.free,
    };
  }
  if (setReachLimitPremiumPlanObservation || setReachLimitPremiumPlanForms) {
    return {
      translationKey: 'about_to_reach_limit',
      type: limitationOffer.premium,
    };
  }
  if (
    isLimitReachPremiumForms ||
    isLimitReachPremiumObservation ||
    (isLimitReachFreeForms || isLimitReachFreeObservation)
  ) {
    return {
      translationKey: 'reached_limit',
      type: limitationOffer.isLimit,
    };
  }
  return null;
};
