import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

const Plans = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.plans)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.33333C7.30649 1.33333 6.63241 1.62598 6.12869 2.16176C5.62366 2.69893 5.33333 3.4358 5.33333 4.21212C5.33333 5.33807 6.02707 6.46063 6.83116 7.36341C7.22188 7.80208 7.61456 8.16195 7.91004 8.41221C7.9412 8.43861 7.97123 8.46373 8 8.48756C8.02877 8.46373 8.0588 8.43861 8.08996 8.41221C8.38544 8.16195 8.77812 7.80208 9.16884 7.36341C9.97293 6.46063 10.6667 5.33807 10.6667 4.21212C10.6667 3.4358 10.3763 2.69893 9.87131 2.16176C9.36759 1.62598 8.69351 1.33333 8 1.33333ZM8 9.33333C7.61438 9.87716 7.61422 9.87704 7.61405 9.87692L7.6126 9.87589L7.6096 9.87375L7.60004 9.86687L7.56716 9.84286C7.53934 9.82237 7.49995 9.79295 7.45065 9.75507C7.35209 9.67936 7.21354 9.56961 7.0483 9.42965C6.71877 9.15055 6.27812 8.74716 5.83551 8.25023C4.97293 7.2818 4 5.84375 4 4.21212C4 3.10781 4.41206 2.04109 5.15727 1.24846C5.90379 0.454427 6.9254 0 8 0C9.0746 0 10.0962 0.454427 10.8427 1.24846C11.5879 2.04109 12 3.10781 12 4.21212C12 5.84375 11.0271 7.2818 10.1645 8.25023C9.72188 8.74716 9.28123 9.15055 8.9517 9.42965C8.78646 9.56961 8.64791 9.67936 8.54935 9.75507C8.50005 9.79295 8.46066 9.82237 8.43284 9.84286L8.39996 9.86687L8.3904 9.87375L8.3874 9.87589L8.38636 9.87663C8.38619 9.87675 8.38562 9.87716 8 9.33333ZM8 9.33333L8.38636 9.87663C8.15537 10.0404 7.84504 10.0407 7.61405 9.87692L8 9.33333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.93377 9.71031C5.09376 10.0419 4.95463 10.4404 4.62302 10.6004C3.64774 11.071 2.97377 11.3979 2.19338 11.7771L8 14.5924L13.8052 11.7778L11.3758 10.5999C11.0445 10.4392 10.9062 10.0404 11.0668 9.70915C11.2274 9.37784 11.6262 9.23949 11.9575 9.40012L15.6242 11.1779C15.854 11.2893 16 11.5223 16 11.7778C16 12.0332 15.854 12.2662 15.6242 12.3777L8.29085 15.9332C8.10718 16.0223 7.89283 16.0223 7.70915 15.9332L0.375818 12.3777C0.146047 12.2663 9.85167e-05 12.0334 4.98544e-08 11.778C-9.8417e-05 11.5227 0.145671 11.2897 0.375355 11.1781C0.646308 11.0465 0.894085 10.9261 1.12743 10.8127C2.13401 10.3235 2.87209 9.96479 4.04365 9.39956C4.37526 9.23957 4.77378 9.3787 4.93377 9.71031Z"
    />
  </svg>
);

Plans.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default injectIntl(Plans);
