import { useState, useRef, useEffect } from 'react';

const BoundingClientRef = () => {
  const ref = useRef();
  const [bounding, setBoundingRect] = useState({});

  const set = () => setBoundingRect(ref && ref.current ? ref.current.getBoundingClientRect() : {});

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [bounding, ref];
};

export default BoundingClientRef;
