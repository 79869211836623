import React, { useEffect } from 'react';

import { StyledEngineProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';

import PopupBlockerFallback from 'components/PopupBlockerFallback';
import GlobalExport from 'containers/GlobalExport';
import FeatureFlagsProvider from 'contexts/FeatureFlagsProvider';
import ScrollProvider from 'contexts/ScrollProvider';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import localStorageUser from 'utils/localStorageUser';

import { initializeUserStore } from './actions';
import reducer from './reducer';
import saga from './saga';

function GlobalWrapper({ children }) {
  const dispatch = useDispatch();

  useEffect(
    () => {
      const initialUserStore = localStorageUser.getUserStore();
      window.Core.setWorkerPath('/webviewer/core');
      dispatch(initializeUserStore(initialUserStore));
    },
    [dispatch],
  );

  return (
    <StyledEngineProvider injectFirst>
      <GlobalExport />
      <PopupBlockerFallback />
      <FeatureFlagsProvider>
        <ScrollProvider>{children}</ScrollProvider>
      </FeatureFlagsProvider>
    </StyledEngineProvider>
  );
}

GlobalWrapper.propTypes = {
  children: PropTypes.node,
};

const withReducer = injectReducer({ key: 'global', reducer });
const withSaga = injectSaga({ key: 'global', saga });

export default compose(
  withReducer,
  withSaga,
)(GlobalWrapper);
