import React, { useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import Button from 'components/Button';
import FabButton from 'components/buttons/FabButton';
import Add from 'components/SvgComponents/icons/Add';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import globalMessages from 'translations/messages/global-messages';
import { handleToggleDialog } from 'utils/dialog';

import AddForm from './AddForm';

const styles = theme => ({
  createFormBtn: {
    margin: '0 auto',
  },
  addFormGenericButton: {
    textAlign: 'center',
    marginBlockEnd: theme.spacing(4),
    marginBlockStart: theme.spacing(4),
  },
  typographyEllipsisWidth: {
    maxWidth: '200px',
  },
});

/**
 * AddFormButton
 */
function AddFormButton(props) {
  const {
    classes,
    buttonType,
    intl,
    canCreateForm,
    disableAddButton,
    moduleId,
    beforeOpenDialog,
    openDialog,
    position,
    disableRedirect,
    addButtonLabel,
    onFormCreated,
    source,
    onCloseDialog,
    buttonSize,
    customStyleButtonWrapper,
  } = props;
  const [activeDialog, setActiveDialog] = useState(false);

  const handleCreateForm = () => {
    if (beforeOpenDialog) {
      beforeOpenDialog();
    } else {
      setActiveDialog(true);
    }
  };

  useEffect(
    () => {
      if (openDialog) setActiveDialog(true);
    },
    [openDialog],
  );

  return (
    <>
      {buttonType === 'generic' ? (
        <div className={classNames(classes.addFormGenericButton, customStyleButtonWrapper)}>
          <Tooltip
            title={
              !canCreateForm
                ? addButtonLabel ||
                  intl.formatMessage(globalMessages.upgrade_organization_account_for_forms)
                : ''
            }
          >
            <span>
              <Button
                onClick={handleCreateForm}
                size={buttonSize}
                startIcon={<Add />}
                disabled={disableAddButton || !canCreateForm}
              >
                {buttonSize === 'small' ? (
                  addButtonLabel || intl.formatMessage(globalMessages.create_form)
                ) : (
                  <TypographyEllipsis
                    variant="subtitleWhite"
                    className={classes.typographyEllipsisWidth}
                  >
                    {addButtonLabel || intl.formatMessage(globalMessages.create_form)}
                  </TypographyEllipsis>
                )}
              </Button>
            </span>
          </Tooltip>
        </div>
      ) : (
        <FabButton
          className={classes.createFormBtn}
          onClick={handleToggleDialog(setActiveDialog, activeDialog)}
        />
      )}
      <AddForm
        activeDialog={openDialog || activeDialog}
        onCloseDialog={() => {
          if (onCloseDialog) {
            onCloseDialog();
          }
          setActiveDialog(false);
        }}
        moduleId={moduleId}
        position={position}
        disableRedirect={disableRedirect}
        onFormCreated={onFormCreated}
        source={source}
      />
    </>
  );
}

AddFormButton.propTypes = {
  classes: PropTypes.object,
  intl: intlShape.isRequired,
  buttonType: PropTypes.string,
  canCreateForm: PropTypes.bool,
  disableAddButton: PropTypes.bool,
  moduleId: PropTypes.string,
  beforeOpenDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  position: PropTypes.object,
  disableRedirect: PropTypes.bool,
  addButtonLabel: PropTypes.string,
  onFormCreated: PropTypes.func,
  source: PropTypes.string,
  onCloseDialog: PropTypes.func,
  buttonSize: PropTypes.string,
  customStyleButtonWrapper: PropTypes.string,
};

AddFormButton.defaultProps = {
  buttonType: 'generic',
  canCreateForm: false,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(AddFormButton);
