import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectSnackbarMessage = state => state.snackbar || initialState;

const selectGlobalMessage = () =>
  createSelector(selectSnackbarMessage, successState => successState.get('message'));

export { selectGlobalMessage };
