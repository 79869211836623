import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

const SlidersSvg = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66667 8.66663C3.03486 8.66663 3.33333 8.9651 3.33333 9.33329V14C3.33333 14.3681 3.03486 14.6666 2.66667 14.6666C2.29848 14.6666 2 14.3681 2 14V9.33329C2 8.9651 2.29848 8.66663 2.66667 8.66663Z"
      fill="#848F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66667 1.33337C3.03486 1.33337 3.33333 1.63185 3.33333 2.00004V6.66671C3.33333 7.0349 3.03486 7.33337 2.66667 7.33337C2.29848 7.33337 2 7.0349 2 6.66671V2.00004C2 1.63185 2.29848 1.33337 2.66667 1.33337Z"
      fill="#848F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 7.33337C8.36884 7.33337 8.66732 7.63185 8.66732 8.00004V14C8.66732 14.3682 8.36884 14.6667 8.00065 14.6667C7.63246 14.6667 7.33398 14.3682 7.33398 14V8.00004C7.33398 7.63185 7.63246 7.33337 8.00065 7.33337Z"
      fill="#848F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 1.33337C8.36884 1.33337 8.66732 1.63185 8.66732 2.00004V5.33337C8.66732 5.70156 8.36884 6.00004 8.00065 6.00004C7.63246 6.00004 7.33398 5.70156 7.33398 5.33337V2.00004C7.33398 1.63185 7.63246 1.33337 8.00065 1.33337Z"
      fill="#848F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3327 10C13.7009 10 13.9993 10.2985 13.9993 10.6667V14C13.9993 14.3682 13.7009 14.6667 13.3327 14.6667C12.9645 14.6667 12.666 14.3682 12.666 14V10.6667C12.666 10.2985 12.9645 10 13.3327 10Z"
      fill="#848F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3327 1.33337C13.7009 1.33337 13.9993 1.63185 13.9993 2.00004V8.00004C13.9993 8.36823 13.7009 8.66671 13.3327 8.66671C12.9645 8.66671 12.666 8.36823 12.666 8.00004V2.00004C12.666 1.63185 12.9645 1.33337 13.3327 1.33337Z"
      fill="#848F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.33329C0 8.9651 0.298477 8.66663 0.666667 8.66663H4.66667C5.03486 8.66663 5.33333 8.9651 5.33333 9.33329C5.33333 9.70148 5.03486 9.99996 4.66667 9.99996H0.666667C0.298477 9.99996 0 9.70148 0 9.33329Z"
      fill="#848F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33398 5.33329C5.33398 4.9651 5.63246 4.66663 6.00065 4.66663H10.0007C10.3688 4.66663 10.6673 4.9651 10.6673 5.33329C10.6673 5.70148 10.3688 5.99996 10.0007 5.99996H6.00065C5.63246 5.99996 5.33398 5.70148 5.33398 5.33329Z"
      fill="#848F9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.666 10.6667C10.666 10.2985 10.9645 10 11.3327 10H15.3327C15.7009 10 15.9993 10.2985 15.9993 10.6667C15.9993 11.0349 15.7009 11.3333 15.3327 11.3333H11.3327C10.9645 11.3333 10.666 11.0349 10.666 10.6667Z"
      fill="#848F9D"
    />
  </svg>
);

SlidersSvg.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(SlidersSvg);
