import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'config/config';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { prepareFCHeader } from 'utils/request';

const rootProjectPath = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/`;

export const phasesApi = createApi({
  reducerPath: 'phasesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: rootProjectPath,
    prepareHeaders: prepareFCHeader(),
  }),
  tagTypes: ['Phase'],
  endpoints: builder => ({
    fetchPhases: builder.query({
      query: ({ idData, lang }) => ({
        url: `projects/${idData}/phases`,
        method: 'GET',
        headers: { 'Accept-Language': lang },
        params: { without_disabled: true },
      }),
      transformResponse: response =>
        response.phases?.map(phase => ({ ...phase, __reorder__: phase.name })),
      providesTags: ['Phase'],
    }),
    createPhases: builder.mutation({
      query: ({ idData, phases }) => ({
        url: `projects/${idData}/phases/create-bulk`,
        method: 'POST',
        body: { phases },
      }),
      transformResponse: () => {
        Analytics.track('phases_phase_creation_succeeded');
      },
      transformErrorResponse: () => {
        Analytics.track('phases_phase_creation_failed');
      },
      invalidatesTags: ['Phase'],
    }),
    updatePhase: builder.mutation({
      query: ({ idData, modifiedItem }) => ({
        url: `projects/${idData}/phases/${modifiedItem?.id}`,
        method: 'PUT',
        body: modifiedItem,
      }),
      transformResponse: () => {
        Analytics.track('phases_phase_update_succeeded');
      },
      transformErrorResponse: () => {
        Analytics.track('phases_phase_update_failed');
      },
      invalidatesTags: ['Phase'],
    }),
    renamePhase: builder.mutation({
      query: ({ idData, phase, name, lang }) => ({
        url: `projects/${idData}/phases/${phase?.id}`,
        method: 'PUT',
        headers: { 'Accept-Language': lang },
        body: JSON.stringify({
          ...phase,
          name,
        }),
      }),
      transformResponse: () => {
        Analytics.track('phases_phase_update_succeeded');
      },
      transformErrorResponse: () => {
        Analytics.track('phases_phase_update_failed');
      },
      invalidatesTags: ['Phase'],
    }),
    deletePhase: builder.mutation({
      query: ({ idData, id }) => ({
        url: `projects/${idData}/phases/${id}`,
        method: 'DELETE',
      }),
      transformResponse: () => {
        Analytics.track('phases_phase_deletion_succeeded');
      },
      transformErrorResponse: () => {
        Analytics.track('phases_phase_deletion_failed');
      },
      invalidatesTags: ['Phase'],
    }),
  }),
});

export const {
  useFetchPhasesQuery,
  useCreatePhasesMutation,
  useUpdatePhaseMutation,
  useRenamePhaseMutation,
  useDeletePhaseMutation,
} = phasesApi;
