import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

const MultipleChoiceSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.multiple_choice)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3335 2.66665C3.15668 2.66665 2.98712 2.73688 2.86209 2.86191C2.73707 2.98693 2.66683 3.1565 2.66683 3.33331V12.6666C2.66683 12.8435 2.73707 13.013 2.86209 13.1381C2.98712 13.2631 3.15669 13.3333 3.3335 13.3333H12.6668C12.8436 13.3333 13.0132 13.2631 13.1382 13.1381C13.2633 13.013 13.3335 12.8435 13.3335 12.6666V7.99998C13.3335 7.63179 13.632 7.33331 14.0002 7.33331C14.3684 7.33331 14.6668 7.63179 14.6668 7.99998V12.6666C14.6668 13.1971 14.4561 13.7058 14.081 14.0809C13.706 14.4559 13.1973 14.6666 12.6668 14.6666H3.3335C2.80306 14.6666 2.29436 14.4559 1.91928 14.0809C1.54421 13.7058 1.3335 13.1971 1.3335 12.6666V3.33331C1.3335 2.80288 1.54421 2.29417 1.91928 1.9191C2.29436 1.54403 2.80306 1.33331 3.3335 1.33331H10.6668C11.035 1.33331 11.3335 1.63179 11.3335 1.99998C11.3335 2.36817 11.035 2.66665 10.6668 2.66665H3.3335ZM14.1954 2.19524C14.4558 1.93489 14.8779 1.93489 15.1382 2.19524C15.3986 2.45559 15.3986 2.8777 15.1382 3.13805L8.47157 9.80472C8.21122 10.0651 7.78911 10.0651 7.52876 9.80472L5.52876 7.80472C5.26841 7.54437 5.26841 7.12226 5.52876 6.86191C5.78911 6.60156 6.21122 6.60156 6.47157 6.86191L8.00016 8.3905L14.1954 2.19524Z"
    />
  </svg>
);

MultipleChoiceSvg.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(MultipleChoiceSvg);
