import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function Upload(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{props.title || props.intl.formatMessage(globalMessages.upload)}</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 7C1.77614 7 2 7.22386 2 7.5V9.5C2 9.63261 2.05268 9.75978 2.14645 9.85355C2.24021 9.94732 2.36739 10 2.5 10H9.5C9.63261 10 9.75978 9.94732 9.85355 9.85355C9.94732 9.75978 10 9.63261 10 9.5V7.5C10 7.22386 10.2239 7 10.5 7C10.7761 7 11 7.22386 11 7.5V9.5C11 9.89783 10.842 10.2794 10.5607 10.5607C10.2794 10.842 9.89783 11 9.5 11H2.5C2.10217 11 1.72064 10.842 1.43934 10.5607C1.15804 10.2794 1 9.89782 1 9.5V7.5C1 7.22386 1.22386 7 1.5 7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64645 1.14645C5.84171 0.951184 6.15829 0.951184 6.35355 1.14645L8.85355 3.64645C9.04882 3.84171 9.04882 4.15829 8.85355 4.35355C8.65829 4.54882 8.34171 4.54882 8.14645 4.35355L6 2.20711L3.85355 4.35355C3.65829 4.54882 3.34171 4.54882 3.14645 4.35355C2.95118 4.15829 2.95118 3.84171 3.14645 3.64645L5.64645 1.14645Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1C6.27614 1 6.5 1.22386 6.5 1.5V7.5C6.5 7.77614 6.27614 8 6 8C5.72386 8 5.5 7.77614 5.5 7.5V1.5C5.5 1.22386 5.72386 1 6 1Z"
      />
    </svg>
  );
}
Upload.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Upload.defaultProps = {
  title: '',
};

export default injectIntl(Upload);
