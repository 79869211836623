import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shouldReload: false,
};

export const reloadSlice = createSlice({
  name: 'dashboardReload',
  initialState,
  reducers: {
    setShouldReload: (state, action) => {
      state.shouldReload = action.payload;
    },
  },
});

export const { setShouldReload } = reloadSlice.actions;
export default reloadSlice.reducer;
