import React from 'react';

import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function CircleRadio({ bgColor, ...props }) {
  const theme = useTheme();
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>{props.title || props.intl.formatMessage(globalMessages.check)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666992 7.99984C0.666992 3.94975 3.95024 0.666504 8.00033 0.666504C12.0504 0.666504 15.3337 3.94975 15.3337 7.99984C15.3337 12.0499 12.0504 15.3332 8.00033 15.3332C3.95024 15.3332 0.666992 12.0499 0.666992 7.99984Z"
        fill={bgColor || theme.palette.secondary.main}
      />
      <circle cx="8" cy="8" r="6" fill="white" />
      <circle
        cx="8.00033"
        cy="7.99984"
        r="3.33333"
        fill={bgColor || theme.palette.secondary.main}
      />
    </svg>
  );
}
CircleRadio.propTypes = {
  title: PropTypes.string,
  bgColor: PropTypes.string,
  intl: intlShape.isRequired,
};

CircleRadio.defaultProps = {
  title: '',
};

export default injectIntl(CircleRadio);
