import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

const Phone = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <title>{props.title || props.intl.formatMessage(globalMessages.phone)}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3,15.4c-0.1,0-0.1,0-0.2,0c-2.2-0.2-4.3-1-6.1-2.2C5.4,12.1,3.9,10.7,2.8,9C1.6,7.1,0.9,5,0.7,2.9
	c0-0.3,0-0.6,0.1-0.9C0.9,1.7,1,1.5,1.2,1.3C1.4,1,1.6,0.9,1.9,0.8s0.5-0.2,0.8-0.2h2c0.5,0,1,0.2,1.4,0.5c0.4,0.3,0.6,0.8,0.7,1.3
	C6.9,3,7,3.5,7.2,4.1c0.1,0.4,0.2,0.8,0.1,1.2C7.2,5.6,7.1,6,6.8,6.3L6.3,6.7c0.8,1.2,1.8,2.2,2.9,2.9l0.4-0.4
	c0.3-0.3,0.6-0.5,1-0.6c0.4-0.1,0.8-0.1,1.2,0.1C12.5,9,13,9.1,13.6,9.2c0.5,0.1,1,0.3,1.3,0.7c0.3,0.4,0.5,0.9,0.5,1.4v2
	c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.7,0.4C13.8,15.3,13.6,15.4,13.3,15.4z M4.8,2.1
	C4.8,2.1,4.7,2.1,4.8,2.1h-2c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1C2.3,2.3,2.2,2.4,2.2,2.5c0,0.1,0,0.2,0,0.2
	C2.4,4.6,3,6.5,4.1,8.2c1,1.5,2.3,2.8,3.8,3.8c1.6,1.1,3.5,1.7,5.4,1.9c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1
	c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2v-2c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.7-0.1-1.4-0.3-2-0.5
	c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.2l-0.8,0.8c-0.2,0.2-0.6,0.3-0.9,0.1c-1.8-1-3.3-2.5-4.3-4.3C4.6,6.7,4.6,6.3,4.9,6.1
	l0.8-0.8c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3C5.6,4,5.4,3.3,5.3,2.6c0-0.1-0.1-0.3-0.2-0.4C5,2.1,4.9,2.1,4.8,2.1z"
    />
  </svg>
);

Phone.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(Phone);
