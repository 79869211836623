// TODO?: delete ?

import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { getTranslation } from 'utils/localization';

export const styles = theme => ({
  container: {
    display: 'flex',
  },
  button: {
    background: theme.palette.background.primary,
    borderRadius: '0.25rem',
    textTransform: 'capitalize',
    padding: '0.125rem 0.5rem',
  },
  icon: {
    marginLeft: '0.375rem',
  },
  dropdown: {
    position: 'fixed',
    zIndex: theme.zIndex.tooltip,
    marginTop: '2rem',
  },
  control: {
    marginLeft: '2.25rem',
  },
});

export const StatusForm = ({ classes, selected }) => {
  const name = Array.isArray(selected?.name)
    ? getTranslation(selected?.name, selected.id)
    : selected?.name;
  return (
    <div className={classes.container}>
      <span style={{ color: selected?.color }}>{name}</span>
    </div>
  );
};

StatusForm.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.object,
};

export default compose(withStyles(styles))(StatusForm);
