export const scopeType = {
  observation: 'Observation',
  form: 'Form',
  formAnswer: 'FormAnswer',
  group: 'Group',
  meeting: 'Meeting',
};

export const messageType = {
  text: 'Text',
  observation: 'Observation',
  form: 'Form',
  image: 'Image',
  document: 'Document',
  file: 'File',
  action: 'Action',
  cluster: 'Cluster',
};

export const metaDataCase = {
  metaDataNotSet: 0,
  image: 7,
  video: 8,
  audio: 9,
  document: 10,
};

export const observationsUpdateAction = {
  assignedCompanyRemoved: 'assigned_company_removed',
  assignedCompanyTo: 'assigned_company_to',
  assigneeRemoved: 'assignee_removed',
  assigneeTo: 'assignee_to',
  commonObservationChange: 'common_observation_change',
  commonObservationRemoved: 'common_observation_removed',
  descriptionChange: 'description_change',
  dueDateChange: 'due_date_change',
  imageAdded: 'image_added',
  locatedOnPlan: 'located_on_plan',
  locatedOnPlanRemoved: 'located_on_plan_removed',
  priorityChange: 'priority_change',
  assignedPhase: 'assigned_phase',
  statusChange: 'status_change',
  assignedPhaseRemoved: 'assigned_phase_removed',
  moduleChange: 'module_change',
};

export const formsUpdateAction = {
  assignedCompanyRemoved: 'assigned_company_removed',
  assignedCompanyTo: 'assigned_company_to',
  assigneeRemoved: 'assignee_removed',
  assigneeTo: 'assignee_to',
  dueDateChange: 'due_date_change',
  formDescriptionChange: 'form_description_change',
  locatedOnPlan: 'located_on_plan',
  locatedOnPlanRemoved: 'located_on_plan_removed',
  priorityChange: 'priority_change',
  assignedPhase: 'assigned_phase',
  statusChange: 'status_change',
  assignedPhaseRemoved: 'assigned_phase_removed',
};

export const groupUpdateAction = {
  defaultGroupCreated: 'default_group_created',
  created: 'group_created',
  imageAdded: 'image_added',
  memberAdded: 'group_member_added',
  membersAdded: 'group_members_added',
  memberLeft: 'group_member_left',
  nameEdited: 'group_name_edited',
  descriptionEdited: 'group_description_edited',
  planAdded: 'group_plan_added',
  folderAdded: 'group_folder_added',
};

const scope = 'components/MessageThread/';
export const ADD_MESSAGE = `${scope}ADD_MESSAGE`;
export const ADD_PREVIOUS_MESSAGES = `${scope}ADD_PREVIOUS_MESSAGES`;
export const CLEAR_MESSAGES = `${scope}CLEAR_MESSAGES`;
export const GET_OBSERVATION_PREVIOUS_MESSAGE = `${scope}GET_OBSERVATION_PREVIOUS_MESSAGE`;
export const GET_FORM_PREVIOUS_MESSAGE = `${scope}GET_FORM_PREVIOUS_MESSAGE`;
export const GET_GROUP_PREVIOUS_MESSAGE = `${scope}GET_GROUP_PREVIOUS_MESSAGE`;
export const SET_PREVIOUS_MESSAGES_LOADING_FLAG = `${scope}SET_PREVIOUS_MESSAGES_LOADING_FLAG`;

export const MAX_RETRY_ATTEMPTS = 5;

export const MSG_MAX_WIDTH = 300;
export const SIDE_MARGIN_MSG = 40;
